import Table from "@Components/Table";
import { getAdminTableColumns } from "@Pages/AdminsPage/columns";
import { TextContainer } from "@Pages/OrganizationPage/styles";
import ModalDeleteUser from "@Pages/UsersPage/ModalDeleteUser";
import ModalOrganizationUser from "@Pages/UsersPage/ModalOrganizationUser";
import { RootState } from "@Store";
import { selectIsSystemAdmin } from "@Store/AppUser/selectors";
import {setUserModal, setUserModalType, usersListRequest} from "@Store/Users/actions";
import { CloseOutlined, EditFilled } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import i18n from "@i18n";
import MainComponent from "@src/MainComponent";
import { Typography } from "antd";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { selectOrganizationAdmins } from "./selectors";
import { ActionIconContainer, TableContainer } from "./styles";
import {useEffect} from "react";

const { Text } = Typography;

const superAdminColumn = (
  onDelete: (row: any) => any,
  onEdit: (row: any) => any
) => ({
  name: i18n.t("admins.actions"),
  width: "90px",
  sortable: true,
  cell: (row: any) => (
    <ActionIconContainer>
      <EditFilled onClick={() => onEdit(row)} />
      {!row.me && !row.superAdmin && (
        <CloseOutlined onClick={() => onDelete(row)} />
      )}
      {row.me || (row.superAdmin && <CloseOutlined disabled />)}
    </ActionIconContainer>
  ),
});

function AdminsPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { tableData, isSystemAdmin } = useAppSelector((state: RootState) => ({
    tableData: selectOrganizationAdmins(state),
    isSystemAdmin: selectIsSystemAdmin(state),
  }));

  const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();

  const adminTableColumns: TableColumn<any>[] = getAdminTableColumns();

  if (isSystemAdmin && adminTableColumns.length === 11) {
    const onDelete = (row: any) => {
      dispatch(setUserModal(row));
      setTimeout(() => dispatch(setUserModalType("delete")), 500);
    };
    const onEdit = (row: any) => {
      dispatch(setUserModal(row));
      setTimeout(() => dispatch(setUserModalType("update")), 500);
    };
    adminTableColumns.push(superAdminColumn(onDelete, onEdit));
  }

  useEffect(() => {
    dispatch(usersListRequest(orgId));
  }, []);

  return (
    <MainComponent>
      <TableContainer>
        {isSystemAdmin && (
          <TextContainer>
            <Text
              type="warning"
              onClick={() => history.push(`/organization/${orgId}/users`)}
            >
              {t("adminPage.add")}
            </Text>
          </TextContainer>
        )}
        <Table columns={adminTableColumns} data={tableData} />
      </TableContainer>
      <ModalDeleteUser />
      <ModalOrganizationUser />
    </MainComponent>
  );
}

export default AdminsPage;
