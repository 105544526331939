import BlackPinDot from "@Components/BlackPinDot";
import BlackPinTag, { BlackpinTagProps } from "@Components/BlackPinTag";
import { sortFunction } from "@Components/Table";
import { ActionIconContainer } from "@Pages/AdminsPage/styles";
import AvatarAsync from "@Pages/AvatarAsync";
import { IOrganizationUser } from "@Store/Organizations/types";
import {
  isOrganizationUserAdmin,
  isOrganizationUserSimpleUser,
  isOrganizationUserStatusActive, isOrganizationUserStatusActiveInvited,
  isOrganizationUserSuperAdmin,
  roleToReadable,
} from "@Store/Organizations/utilities";
import { EditFilled } from "@ant-design/icons";
import i18n from "@i18n";
import {Switch, Tag, Tooltip} from "antd";
import { TableColumn } from "react-data-table-component";
import { CellsContainer } from "./styles";
import {environment} from "@src/environments/environment";

export const USERS_TABLE_COLUMNS = (
  onEditUser: (row: IOrganizationUser) => void,
  showConfirmModal: (row: IOrganizationUser) => void
): TableColumn<any>[] => {

  const columns: TableColumn<any>[] = [
    {
      selector: (_) => "avatar",
      width: "45px",
      cell: (row: IOrganizationUser) => (
          <BlackPinDot
              size={8}
              color="#C4482B"
              hide={isOrganizationUserStatusActive(row.status)}
          >
            <div
                className={
                  isOrganizationUserStatusActive(row.status) ? "" : "grayscaleAvatar"
                }
            >
              <AvatarAsync
                  individual
                  size={"small"}
                  // TODO: add image update url
                  url={""}
              />
            </div>
          </BlackPinDot>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.firstname"),
      selector: (row: IOrganizationUser) => row.givenName,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          row.givenName.toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.givenName}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.lastname"),
      selector: (row: IOrganizationUser) => row.surName,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          row.surName.toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.surName}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.displayName"),
      selector: (row: IOrganizationUser) => row.displayName,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          row.displayName.toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.displayName}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.nickname"),
      selector: (row: IOrganizationUser) => row.nickName ?? "",
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          (row.nickName ?? "").toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.nickName}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.description"),
      selector: (row: IOrganizationUser) => row.description ?? "",
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          (row.description ?? "").toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.description}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.email"),
      selector: (row: IOrganizationUser) => row.emailAddress,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          row.emailAddress.toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.emailAddress}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.department"),
      selector: (row: IOrganizationUser) => row.department ?? "",
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          (row.department ?? "").toLowerCase()
      ),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>{row.department}</CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.phoneNumbers"),
      selector: (row: IOrganizationUser) => row.mobileNumber,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) => row.mobileNumber),
      cell: (row: IOrganizationUser) => (
          <CellsContainer>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{paddingBottom: 15}}>
                <Tag>{i18n.t(`phoneNumberTypes.mobile`)}</Tag>
                {row.mobileNumber}
              </div>
            </div>
          </CellsContainer>
      ),
    },
    {
      name: i18n.t("organizationUsers.columns.role"),
      width: "8vw",
      selector: (row: IOrganizationUser) => row.role,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          i18n.t(roleToReadable(row.role)).toLowerCase()
      ),
      cell: (row: IOrganizationUser) => {
        let color: BlackpinTagProps["color"] = "default";

        if (isOrganizationUserSimpleUser(row.role)) {
          color = "Green";
        } else if (isOrganizationUserAdmin(row.role)) {
          color = "Orange";
        } else if (isOrganizationUserSuperAdmin(row.role)) {
          color = "Red";
        }

        return (
            <BlackPinTag color={color} text={i18n.t(roleToReadable(row.role))}/>
        );
      },
    },
    {
      name: i18n.t("organizationUsers.columns.actions"),
      width: "90px",
      sortable: true,
      cell: (row: IOrganizationUser) => (
          <ActionIconContainer>
            <Tooltip title={i18n.t("organizationUsers.buttons.edit")}>
              <EditFilled onClick={() => onEditUser(row)}/>
            </Tooltip>
          </ActionIconContainer>
      ),
    },
  ];

  if (!environment.production) {
    columns.splice(columns.length - 1, 0, {
      name: i18n.t("organizationUsers.columns.active"),
      selector: (row: IOrganizationUser) => row.status,
      sortable: true,
      sortFunction: sortFunction((row: IOrganizationUser) =>
          Boolean(isOrganizationUserStatusActiveInvited(row.status))
      ),
      cell: (row: IOrganizationUser) => (
          <Tooltip title={row.status === 'INVITED' ? i18n.t('organizationUsers.columns.notActivated') : ''}>
            <Switch
                aria-label="User activation status"
                disabled={row.status === 'INVITED'}
                onClick={(checked, event) => event.preventDefault()}
                checked={row.status === 'ACTIVE'}
                onChange={(checked) => {
                  showConfirmModal(row);
                }}
            />
          </Tooltip>
      ),
    });
  }

  return columns;
}