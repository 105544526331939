import styled from 'styled-components';
import { color } from '.';

export const NavSectionContainer = styled.div`
  width: 40%;
  border-radius: 5px;
  background-color: #232323;
  text-transform: uppercase;
  .ant-image {
    margin: 0px 12px 0px 12px;
  }
`;

interface INavSectionHeader {
  color: color;
}

export const NavSectionHeader = styled.div<INavSectionHeader>`
  background: ${({ theme, color }) => theme.palette[color]};

  color: black;

  height: 65px;
  display: flex;
  align-items: center;

  font-style: 400;
  font-size: 16px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const NavSectionContentItem = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 12px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:after {
    content: '';
    position: absolute;
    border-left: 1px solid black;
    right: -10px;
    height: 80%;
  }
`;
