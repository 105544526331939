import { LogLevel } from '@azure/msal-browser';

export const environment = {
  production: true,
  b2c: {
    clientId: '353053ad-b779-42c5-bd47-2db22ec81dfa',
    authority:
      'https://BlackpinDevB2C.b2clogin.com/BlackpinDevB2C.onmicrosoft.com/B2C_1_login',
    logLevel: LogLevel.Info,
    knownAuthorities: ['BlackpinDevB2C.b2clogin.com'],
    passwordResetFlow: 'B2C_1_pwreset',
  },
  api: {
    //baseUrl: 'https://common-api-dev.azurewebsites.net/api',
    //baseUrl: 'http://192.168.178.33:65042/api/v1',
    code: '1GFgH36viZks3O4inRpFVcasg4JBZoHXngckiJmwlcAZxM6DIQl3XA==',
    clientId: '353053ad-b779-42c5-bd47-2db22ec81dfa',
  },
  backendApi: {
    clientId: '0abd2cab-66d9-4d83-9353-f0c370ff109d',
  },
  scopes: ['openid', '353053ad-b779-42c5-bd47-2db22ec81dfa'],
};
