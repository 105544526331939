import {NewOrganizationPayload} from "@API/Organizations/types";
import {sortFunction} from "@Components/Table";
import {combineReducers, createReducer, PayloadAction,} from "@reduxjs/toolkit";
import {
  createOrganizationFail,
  createOrganizationRequest,
  createOrganizationSuccess,
  getOrganizationByIdFail,
  getOrganizationByIdRequest,
  getOrganizationByIdSuccess,
  getOrganizationPolicyFail, getOrganizationPolicyRequest, getOrganizationPolicySuccess,
  organizationsListFail,
  organizationsListRequest,
  organizationsListSuccess,
  resetCreateOrganizationModal,
  setModalCreateOrganization,
  setModalCreateOrganizationType,
  updateOrganizationFail,
  updateOrganizationRequest,
  updateOrganizationSuccess,
  updateSelectedOrganizationId,
  uploadImageOrganizationFail,
  uploadImageOrganizationRequest,
  uploadImageOrganizationSuccess,
} from "./actions";
import {CreateOrganizationModal, IOrganization, OrganizationId, OrganizationModalType,} from "./types";

interface IOrganizationsListState {
  organizationsList: IOrganization[];
  createOrganizationModal: CreateOrganizationModal;
  selectedOrganizationId: OrganizationId | undefined;
  noOrganization: boolean;
  updateStatus: {
    isLoading: boolean;
    success: boolean;
    fail: boolean;
  };
  isLoading: boolean;
  success: boolean;
  fail: boolean;
  imageUploadStatus: {
    isLoading: boolean,
    success: boolean,
    fail: boolean,
  },
  currentOrganization: IOrganization | null;
  currentOrganizationLoading: boolean;
  currentOrganizationError: Error | null;
  organizationPolicy: {
    isLoading: boolean;
    data: string | null; // Change the type according to the actual data structure
    error: Error | null;
  };
}

const ORGANIZATION_LIST_INITIAL_STATE: IOrganizationsListState = {
  organizationsList: [],
  createOrganizationModal: {
    organization: undefined,
    type: undefined,
    isLoading: false,
    success: false,
    fail: false,
  },
  selectedOrganizationId: undefined,
  noOrganization: false,
  updateStatus: {
    isLoading: false,
    success: false,
    fail: false,
  },
  isLoading: false,
  success: false,
  fail: false,
  imageUploadStatus: {
    isLoading: false,
    success: false,
    fail: false,
  },
  currentOrganization: null,
  currentOrganizationLoading: false,
  currentOrganizationError: null,
  organizationPolicy: {
    isLoading: false,
    data: null,
    error: null,
  },
};

const organizationsListReducer = createReducer(
  ORGANIZATION_LIST_INITIAL_STATE,
  {
    [organizationsListRequest.type]: (state) => ({
      ...state,
      isLoading: true,
      success: false,
      fail: false,
    }),
    [organizationsListSuccess.type]: (
      state,
      { payload }: PayloadAction<IOrganization[]>
    ) => ({
      ...state,
      organizationsList: payload,
      noOrganization: !payload.length,
      isLoading: false,
      success: true,
      fail: false,
    }),
    [organizationsListFail.type]: (state) => ({
      ...state,
      isLoading: false,
      success: false,
      fail: true,
    }),

    [updateOrganizationRequest.type]: (state) => ({
      ...state,
      createOrganizationModal: {
        ...state.createOrganizationModal,
        isLoading: true,
        success: false,
        fail: false,
      },
      updateStatus: {
        isLoading: true,
        success: false,
        fail: false,
      },
    }),
    [updateOrganizationRequest.type]: (state, action) => {
      return {
        ...state,
        currentOrganization: action.payload,
        isLoading: false,
      }
    },
    [getOrganizationByIdRequest.type]: (state) => ({
      ...state,
      currentOrganizationLoading: true,
      currentOrganizationError: null
    }),

    [getOrganizationByIdSuccess.type]: (state, action: PayloadAction<IOrganization>) => {
      const timestamp = new Date().getTime();

      const updatedOrganization = {
        ...action.payload,
        orgLogoUrl: action.payload.orgLogoUrl ? `${action.payload.orgLogoUrl}?${timestamp}` : action.payload.orgLogoUrl,
        orgInfoUrl: action.payload.orgInfoUrl ? `${action.payload.orgInfoUrl}?${timestamp}` : action.payload.orgInfoUrl,
      };

      return {
        ...state,
        currentOrganization: updatedOrganization,
        currentOrganizationLoading: false,
        currentOrganizationError: null
      };
    },
    [getOrganizationByIdFail.type]: (state, action: PayloadAction<Error>) => ({
        ...state,
      currentOrganization: null,
        currentOrganizationLoading: false,
        currentOrganizationError: action.payload
    }),
    [updateOrganizationSuccess.type]: (
      state,
      { payload }: PayloadAction<IOrganization>
    ) => ({
      ...state,
      organizationsList: state.organizationsList.map((item) => {
        if (item.orgId !== payload.orgId) return item;
        else
          return {
            ...item,
            createOrganizationModal: {
              ...state.createOrganizationModal,
              isLoading: false,
              success: true,
              fail: false,
            },
            organization: {
              ...item,
              ...payload,
            },
          };
      }),
      updateStatus: {
        isLoading: false,
        success: true,
        fail: false,
      },
    }),
    [updateOrganizationFail.type]: (state) => ({
      ...state,
      createOrganizationModal: {
        ...state.createOrganizationModal,
        isLoading: false,
        success: false,
        fail: true,
      },
      updateStatus: {
        isLoading: false,
        success: false,
        fail: true,
      },
    }),

    [updateSelectedOrganizationId.type]: (
      state,
      { payload }: PayloadAction<OrganizationId>
    ) => ({
      ...state,
      selectedOrganizationId: payload,
    }),

    [createOrganizationRequest.type]: (state) => ({
      ...state,
      createOrganizationModal: {
        ...state.createOrganizationModal,
        isLoading: true,
        success: false,
        fail: false,
      },
    }),
    [createOrganizationSuccess.type]: (
      state,
      { payload }: PayloadAction<IOrganization>
    ) => ({
      ...state,
      organizationsList: [...state.organizationsList, payload].sort(
        sortFunction((organization: IOrganization) =>
          organization.orgName.toLowerCase()
        )
      ),
      createOrganizationModal: {
        ...state.createOrganizationModal,
        isLoading: false,
        success: true,
        fail: false,
      },
    }),
    [createOrganizationFail.type]: (state) => ({
      ...state,
      createOrganizationModal: {
        ...state.createOrganizationModal,
        isLoading: false,
        success: false,
        fail: true,
      },
    }),

    [setModalCreateOrganizationType.type]: (
      state,
      { payload }: PayloadAction<OrganizationModalType>
    ) => {
      return {
        ...state,
        createOrganizationModal: {
          ...state.createOrganizationModal,
          type: payload,
        },
      };
    },
    [setModalCreateOrganization.type]: (state) => {
      return {
        ...state,
        createOrganizationModal: {
          ...state.createOrganizationModal,
          organization: {} as NewOrganizationPayload,
        },
      };
    },
    [resetCreateOrganizationModal.type]: (state) => ({
      ...state,
      createOrganizationModal: {
        organization: undefined,
        type: undefined,
        isLoading: false,
        success: false,
        fail: false,
      },
    }),
  }
);
const imageUploadReducer = createReducer(ORGANIZATION_LIST_INITIAL_STATE, {
  [uploadImageOrganizationRequest.type]: (state) => ({
    ...state,
    imageUploadStatus: {
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [uploadImageOrganizationSuccess.type]: (state) => ({
    ...state,
    imageUploadStatus: {
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [uploadImageOrganizationFail.type]: (state) => ({
    ...state,
    imageUploadStatus: {
      isLoading: false,
      success: false,
      fail: true,
    },
  }),
  [getOrganizationPolicyRequest.type]: (state, action) => ({
    ...state,
    organizationPolicy: {
      isLoading: true,
      data: null,
      error: null,
    },
  }),
  [getOrganizationPolicySuccess.type]: (state, action) => {
    return {
      ...state,
      organizationPolicy: {
        isLoading: false,
        data: {...action.payload},
        error: null,
      },
    }
  },
  [getOrganizationPolicyFail.type]: (state, action) => ({
    ...state,
    organizationPolicy: {
      isLoading: false,
      data: null,
      error: action.payload,
    },
  }),
});

export interface IGeneralState {
  readonly system: IOrganizationsListState;
}
const combinedOrganizationsReducer = (
    state: IOrganizationsListState | undefined, // Allow state to be undefined
    action: PayloadAction<any>
): IOrganizationsListState => {
  const currentState = state ?? ORGANIZATION_LIST_INITIAL_STATE;
  const intermediateState = organizationsListReducer(currentState, action);
  return imageUploadReducer(intermediateState, action);
};


export default combineReducers<IGeneralState>({
  system: combinedOrganizationsReducer,
});
