import "./index.scss";

import { GetImageUrl_BpContactPersons } from "@Services/PbContactPerson";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { IAppUser } from "@Store/AppUser/types";
import {
  endDeleteBpContactPersons,
  endLoadBpContactPersons,
  openAddEditBpContactPersons,
  performGetBpContactPersons,
} from "@Store/Reducers/BpContactPersonReducer";
import { BpContactPerson } from "@Types/bpContactPersons";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import MainComponent from "@src/MainComponent";
import { getUserName } from "@src/Utilities";
import { Breadcrumb, Button, Divider, notification, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AvatarAsync from "../AvatarAsync";
import BpContactPersonListActions from "./BpContactPersonListActions";

function BpContactPersonsListPage(props: any) {
  const dispatch = useAppDispatch();
  const userInformation: IAppUser | any = useAppSelector(selectAppUserData);
  const { list, deleteState } = useAppSelector(
    (state) => state.bpContactPerson
  );

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (typeof deleteState.response === "undefined") {
      return;
    }

    if (deleteState.response.success) {
      notification.success({
        message: t(`success.${deleteState.response.message}`),
      });
      dispatch(performGetBpContactPersons({}));
      dispatch(endDeleteBpContactPersons());
    }

    //Reset state after consume the state info
    dispatch(endLoadBpContactPersons());
  }, [deleteState]);

  const columns = (): ColumnsType<BpContactPerson> => {
    var columns = [];

    columns.push({
      title: "",
      key: "avatar",
      render: (text: string, record: BpContactPerson) => (
        <Space size="middle">
          <AvatarAsync
            size={"small"}
            url={
              record.avatar?.update
                ? `${GetImageUrl_BpContactPersons(record.id)}?${
                    record.avatar?.update
                  }`
                : ""
            }
          />
        </Space>
      ),
    });

    columns.push({
      title: t("bpContactPersons.columns.name"),
      key: "name",
      render: (text: string, record: BpContactPerson) => (
        <Space size="middle">
          {getUserName({
            givenName: record.firstname,
            surName: record.lastname,
            emailAddress: record.email,
          })}
        </Space>
      ),
    });

    columns.push({
      title: t("bpContactPersons.columns.email"),
      dataIndex: "email",
      key: "email",
    });

    columns.push({
      title: t("bpContactPersons.columns.department"),
      dataIndex: "department",
      key: "department",
    });

    columns.push({
      title: t("bpContactPersons.columns.departmentJob"),
      dataIndex: "departmentJob",
      key: "departmentJob",
    });

    columns.push({
      title: t("bpContactPersons.columns.action"),
      key: "action",
      render: (text: string, record: BpContactPerson) => (
        <Space size="middle">
          <BpContactPersonListActions
            bpContactPerson={record}
          ></BpContactPersonListActions>
        </Space>
      ),
    });

    return columns;
  };

  useEffect(() => {
    dispatch(performGetBpContactPersons({}));
  }, []);

  const breadcrumpList = () => {
    return [
      { id: 0, url: `/bpContactPersons/`, text: t("bpContactPersons.title") },
    ];
  };

  return (
    <MainComponent>
      <div style={{ width: "100%" }}>
        <Breadcrumb style={{ fontSize: 22, paddingBottom: 20 }}>
          {breadcrumpList().map((item) => {
            return (
              <Breadcrumb.Item key={item.id}>
                <Link to={item.url}>{item.text}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        {userInformation?.superAdmin && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(
                openAddEditBpContactPersons({
                  addresses: [],
                  avatar: undefined,
                  email: "",
                  firstname: "",
                  id: 0,
                  lastname: "",
                  department: "",
                  departmentJob: "",
                  phoneNumbers: [],
                })
              );
            }}
          >
            {t("bpContactPersons.buttons.addContactPerson")}
          </Button>
        )}
        <Divider />
        <Table
          dataSource={list.data}
          loading={list.loading}
          rowKey={(record) => {
            return record.id;
          }}
          columns={columns()}
        />
      </div>
    </MainComponent>
  );
}

export default BpContactPersonsListPage;
