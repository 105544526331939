import { RootState } from "@Store";
import { createSelector } from "@reduxjs/toolkit";
import { IAppUser } from "./types";

export const selectIsAppUserLoading = (state: RootState): boolean =>
  state.appUser.general.isLoading;

export const selectAppUserData = (state: RootState): IAppUser => {
  if (state.appUser.general.data === undefined) {
    return {
      sub: "",
      userId: "",
      givenName: "",
      surName: "",
      displayName: "",
      emailAddress: "",
      presence: "OFFLINE",
      presenceDescriptionMap: {
        AVAILABLE: "",
        AWAY: "",
        BUSY: "",
        OFFLINE: "",
      },
      isSystemAdmin: false,
      matrixId: "",
      matrixHomeServer: "",
      enabledKeyPassphrase: false,
      memberships: [],
    } as IAppUser;
  }
  return state.appUser.general.data;
};

export const selectIsSystemAdmin = createSelector(
  selectAppUserData,
  (appUser: IAppUser) => appUser.isSystemAdmin
);

export const selectFilteredOrganizations = createSelector(
    (state: RootState): IAppUser => selectAppUserData(state),
    (appUser: IAppUser): any[] => {
      // Filter memberships where orgUser.role is not SIMPLE_USER and map to organization details
      return appUser.memberships
          .filter(membership => membership.orgUser && membership.orgUser.role !== 'SIMPLE_USER')
          .map(membership => ({
            orgId: membership.orgId,
            orgName: membership.orgName,
            device2FaRequired: membership.device2FaRequired,
            orgInfoUrl: membership.orgInfoUrl,
            orgLogoUrl: membership.orgLogoUrl,
            matrixHomeServer: membership.matrixHomeServer
          }));
    }
);