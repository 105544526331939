import OrganizationBanner from "@Components/OrganizationBanner";
import { sortFunction } from "@Components/Table";
import AvatarAsync from "@Pages/AvatarAsync";
import { CellsContainer } from "@Pages/UsersPage/styles";
import { IOrganization } from "@Store/Organizations/types";
import i18n from "@i18n";
import { Dispatch, SetStateAction } from "react";
import { TableColumn } from "react-data-table-component";

export const ORGANIZATIONS_TABLE_COLUMNS = (
  onEdit: Dispatch<SetStateAction<string | undefined>> = () => {}
): TableColumn<any>[] => [
  {
    name: i18n.t("organization.columns.name"),
    selector: (_) => "logo",
    sortable: true,
    sortFunction: sortFunction((row: IOrganization) =>
      row.orgName.toLowerCase()
    ),
    cell: (row: IOrganization) => (
      <div className="orgaName" style={{ margin: 5 }}>
        <AvatarAsync
          shape="square"
          size="medium"
          url={row.orgLogoUrl}
          individual={false}
        />
        <div style={{ paddingLeft: 15 }}>{row.orgName}</div>
      </div>
    ),
  },
  {
    name: i18n.t("organization.columns.matrixHomeServer"),
    selector: (row: IOrganization) => row.matrixHomeserver,
    sortable: true,
    sortFunction: sortFunction((row: IOrganization) =>
      row.matrixHomeserver.toLowerCase()
    ),
    cell: (row: IOrganization) => (
      <CellsContainer>{row.matrixHomeserver}</CellsContainer>
    ),
  },
  {
    name: i18n.t("organization.columns.information"),
    selector: (row: IOrganization) => row.orgInfoUrl ?? "",
    cell: (row: IOrganization) =>
      row.orgInfoUrl && <OrganizationBanner bannerImageUrl={row.orgInfoUrl} />,
  },
  // {
  //   name: i18n.t("organization.columns.superAdministrator"),
  //   selector: "superAdministrator",
  //   sortable: true,
  //   sortFunction: sortFunction((row: any) =>
  //     getUserName(row.superAdministrator).toLowerCase()
  //   ),
  //   cell: (row) => (
  //     <div style={{ margin: 5 }}>
  //       <PersonObject
  //         showName={true}
  //         name={getUserName(row.superAdministrator)}
  //         imageUrl=""
  //         phoneNumbers={row.superAdministrator?.phoneNumbers || []}
  //         email={row.superAdministrator?.email || []}
  //         addresses={row.superAdministrator?.addresses || []}
  //         department={row.superAdministrator?.department || ""}
  //         departmentJob={row.superAdministrator?.departmentJob || ""}
  //       />
  //     </div>
  //   ),
  // },
  // {
  //   name: i18n.t("organization.columns.bpContactPerson"),
  //   selector: "bpContactPerson",
  //   sortable: true,
  //   sortFunction: sortFunction((row: any) =>
  //     getUserName(row.bpContactPerson).toLowerCase()
  //   ),
  //   cell: (row) => (
  //     <div style={{ margin: 5 }}>
  //       <PersonObject
  //         showName={true}
  //         name={getUserName(row.bpContactPerson)}
  //         imageUrl=""
  //         phoneNumbers={row.bpContactPerson?.phoneNumbers || []}
  //         email={row.bpContactPerson?.email || []}
  //         addresses={row.bpContactPerson?.addresses || []}
  //         department={row.bpContactPerson?.department || ""}
  //         departmentJob={row.bpContactPerson?.departmentJob || ""}
  //       />
  //     </div>
  //   ),
  // },
  // {
  //   name: i18n.t("organization.columns.contactPerson"),
  //   selector: "contactPerson",
  //   sortable: true,
  //   sortFunction: sortFunction((row: any) =>
  //     getUserName(row.contactPerson).toLowerCase()
  //   ),
  //   cell: (row) => (
  //     <div style={{ margin: 5 }}>
  //       <PersonObject
  //         showName={true}
  //         name={getUserName(row.contactPerson)}
  //         imageUrl=""
  //         phoneNumbers={row.contactPerson?.phoneNumbers || []}
  //         email={row.contactPerson?.email || []}
  //         addresses={row.contactPerson?.addresses || []}
  //         department={row.contactPerson?.department || ""}
  //         departmentJob={row.contactPerson?.departmentJob || ""}
  //       />
  //     </div>
  //   ),
  // },
  // {
  //   name: i18n.t("organization.columns.actions"),
  //   width: "70px",
  //   cell: (row: IOrganization) => (
  //     <OrganizationListActions organization={row} onEdit={onEdit} />
  //   ),
  // },
];
