import styled from 'styled-components';
import * as yup from "yup";

export const PageContainer = styled.div`
    background-color: black;
    color: white;
    padding: 20px;
    width: 80%;
`;

export const EditorContainer = styled.div`
    background-color: white; 
    color: black;
    border: 1px solid #ddd;
    font-size: 14px;
    height: 493px;
    width: 100%;
    
    .quill {
        height: 450px;
    }
`;

export const ActionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
`;