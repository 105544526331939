import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 20px 0;
  justify-content: end;
  input {
    border-radius: 5px;
    width: 335px;
  }
`;

export const SearchBlock = styled.div`
  color: ${props => props.theme.palette.white};
  padding: .375rem .75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
`;
