import { IInviteUser } from '@Store/Invites/types';

export const inviteUsers: IInviteUser[] = [
  {
    id: 2,
    email: 'frauke@blackpin.de',
    firstname: 'Frauke',
    lastname: 'Schiller',
    nickname: '00100033434',
    department: 'Support Department',
    departmentJob: 'Support Manager',
    role: {
      id: 4,
      name: 'super_admin',
    },
    notifyUser: true,
    active: false,
    used: true,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },
  {
    id: 3,
    email: 'andy.hook@blackpin.de',
    firstname: 'Andy',
    lastname: 'Hook',
    nickname: '00100033899',
    department: 'Support Department',
    departmentJob: 'Support Manager',
    role: {
      id: 4,
      name: 'super_admin',
    },
    notifyUser: true,
    active: false,
    used: true,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },

  {
    id: 4,
    email: 'maria.hertot@blackpin.de',
    firstname: 'Maria',
    lastname: 'Herton',
    nickname: '00100033875',
    department: 'Sales Department',
    departmentJob: 'Sales Manager',
    role: {
      id: 2,
      name: 'user',
    },
    notifyUser: true,
    active: false,
    used: true,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },

  {
    id: 5,
    email: 'tony.rize@blackpin.de',
    firstname: 'Tony',
    lastname: 'Rize',
    nickname: '00100033646',
    department: 'Sales Department',
    departmentJob: 'Sales Manager',
    role: {
      id: 2,
      name: 'user',
    },
    notifyUser: true,
    active: false,
    used: true,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },

  {
    id: 6,
    email: 'fiona.stock@blackpin.de',
    firstname: 'Fiona',
    lastname: 'Stock',
    nickname: '0010003666',
    department: 'IT Department',
    departmentJob: 'Software Developer',
    role: {
      id: 2,
      name: 'user',
    },
    notifyUser: true,
    active: false,
    used: true,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },

  {
    id: 7,
    email: 'test@ptest.com',
    firstname: 'Christoph',
    lastname: 'Bolsinger',
    nickname: '0010003688',
    department: 'PlanB',
    departmentJob: 'Head of Digital Product Factory',
    role: {
      id: 2,
      name: 'user',
    },
    notifyUser: true,
    active: false,
    used: false,
    timeStamp: '2021-09-28T14:35:42.2806761',
    validUntilTimeStamp: '2021-09-29T14:35:42.2806755',
  },
];
