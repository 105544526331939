import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  &:nth-child(n) {
    display: flex;
    flex-direction: column;

  }
  label.ant-checkbox-wrapper {
    width: 100%;
  }
  h2 {
    text-align: center;
    margin-bottom: 50px;
  }

  h3 {
    text-align: center;
    margin-top: 36px;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

export const FormFooterContainer = styled.div`
  display: flex;
  width: 100%;
  button {
    margin: 24px 0 0 0;
  }
  justify-content: right;
`;
