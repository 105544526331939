import "./App.scss";
import "./Index.less";

import Header from "@Components/Header";
import OrganizationSelection from "@Components/OrganizationSelection";
import SideBar from "@Components/SideBar";
import ModalBpContactPerson from "@Pages/BpContactPersonListPage/ModalBpContactPerson";
import { RootState } from "@Store";
import {selectAppUserData, selectIsAppUserLoading} from "@Store/AppUser/selectors";
import {
    getOrganizationByIdRequest,
    organizationsListRequest,
    updateSelectedOrganizationId,
} from "@Store/Organizations/actions";
import {
  selectCurrentOrgId,
  selectOrganizationsGeneralData,
} from "@Store/Organizations/selectors";
import { OrganizationId, OrganizationRole } from "@Store/Organizations/types";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Result, Spin } from "antd";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

type MainComponentProps = {
  role?: OrganizationRole;
};

function MainComponent(props: PropsWithChildren<MainComponentProps>) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location: ReturnType<typeof useLocation> = useLocation();
  const params: {orgId: string} = useParams();

  const { orgId: organizationIdFromUrl } =
    useParams<Record<string, OrganizationId>>();

  const {
    currentOrganizationId,
    isAppUserLoading,
    organizationGeneralData: {
      isLoading: isOrganizationsLoading,
      noOrganization,
    },
  } = useAppSelector((state: RootState) => ({
    currentOrganizationId: selectCurrentOrgId(state),
    isAppUserLoading: selectIsAppUserLoading(state),
    organizationGeneralData: selectOrganizationsGeneralData(state),
  }));

  const showAppContent: boolean = useMemo(
    () =>
      !isAppUserLoading && !isOrganizationsLoading && !!currentOrganizationId,
    [isAppUserLoading, isOrganizationsLoading, currentOrganizationId]
  );

    useEffect(() => {
        dispatch(getOrganizationByIdRequest(params.orgId));
    }, [params.orgId]);

  useEffect(() => {
    const newOrganizationId: string | undefined =
      organizationIdFromUrl || currentOrganizationId;

    if (newOrganizationId !== currentOrganizationId) {
      dispatch(updateSelectedOrganizationId(organizationIdFromUrl));
    }
  }, [organizationIdFromUrl, currentOrganizationId, dispatch, history]);

  if (props.role === "SIMPLE_USER") {
    return (
      <div className="app__wrapper">
        <Header
          onLogoClick={() => history.push("/dashboard")}
          showCurrentOrganizationName={false}
        />
        <div className="app__container">
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {showAppContent && (
              <div
                className="app__content"
                style={{ justifyContent: "center" }}
              >
                {props.children}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="app__wrapper">
        <ModalBpContactPerson />
        <Header
          onLogoClick={() => history.push("/dashboard")}
          showCurrentOrganizationName={location.pathname !== "/organizations"}
        />
        <div className="app__container">
          {noOrganization && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* TODO: translation */}
              <Result title="Es sind keine aktiven Organisationen vorhanden!" />
            </div>
          )}
          {isOrganizationsLoading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" tip="Organizationen werden geladen..." />
            </div>
          ) : (
            <>
              <OrganizationSelection />
                <div>

                    <SideBar />
                </div>
            </>
          )}
          {showAppContent && (
            <div className="app__content">{props.children}</div>
          )}
        </div>
      </div>
    );
  }
}

export default MainComponent;
