import "./index.scss";

import LanguageSelectAction from "@Components/ProfileCard/LanguageSelectAction";
import LogoutAction from "@Components/ProfileCard/LogoutAction";
import { RootState } from "@Store";
import {
  selectAppUserData,
  selectIsSystemAdmin,
} from "@Store/AppUser/selectors";
import { selectIsAdminOfCurrentOrganization } from "@Store/Organizations/selectors";
import { UserOutlined } from "@ant-design/icons";
import { useAppSelector } from "@hooks";
import { getUserName } from "@src/Utilities";
import { Avatar, Badge, Card, Space } from "antd";

const { Meta } = Card;

function ProfileCard() {
  const { appUser, isSystemAdmin, isAdminOfCurrentOrganization } =
    useAppSelector((state: RootState) => ({
      appUser: selectAppUserData(state),
      isSystemAdmin: selectIsSystemAdmin(state),
      isAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    }));

  const displayBadge = (): JSX.Element | null => {
    if (Object.keys(appUser).length === 0) return null;

    if (isSystemAdmin) {
      return (
        <div className="role-badge-box">
          <Badge count="Super Admin" />
        </div>
      );
    } else if (isAdminOfCurrentOrganization) {
      return (
        <div className="role-badge-box">
          <Badge count="Admin" />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Card
      style={{ width: 300 }}
      actions={[<LanguageSelectAction />, <LogoutAction />]}
    >
      <Meta
        avatar={<Avatar size="small" icon={<UserOutlined />} />}
        title={
          <Space>
            {getUserName({
              givenName: appUser.givenName,
              surName: appUser.surName,
              displayName: appUser.displayName,
              emailAddress: appUser.emailAddress,
            })}
            {displayBadge()}
          </Space>
        }
        description={appUser.emailAddress}
      />
    </Card>
  );
}

export default ProfileCard;
