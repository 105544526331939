import { ArrowLeftIcon } from "@Components/Icons";
import {
  IOrganizationsGeneralData,
  selectCurrentOrganization,
  selectOrganizationsGeneralData,
} from "@Store/Organizations/selectors";
import { IOrganization } from "@Store/Organizations/types";
import { useAppSelector } from "@hooks";
import React, { useMemo } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import {
  NavSectionContainer,
  NavSectionContentItem,
  NavSectionHeader,
} from "./styles";

interface IContentItem {
  text: string;
  onClickHandler: () => void;
}

export type color =
  | "mainYellow"
  | "mainGreen"
  | "mainRed"
  | "mainPurple"
  | "white"
  | "gray";

interface INavSection {
  header: JSX.Element;
  contentItems: IContentItem[];
  color: color;
}

const NavSection: React.FC<INavSection> = ({ header, contentItems, color }) => {
  const { orgId }: IOrganization = useAppSelector(selectCurrentOrganization);
  const { isLoading }: IOrganizationsGeneralData = useAppSelector(
    selectOrganizationsGeneralData
  );

  const memoizedContentItems: JSX.Element[] = useMemo(
    () =>
      contentItems.map(({ text, onClickHandler }: IContentItem) => (
        <NavSectionContentItem key={text} onClick={onClickHandler}>
          {text}
          <ArrowLeftIcon />
        </NavSectionContentItem>
      )),
    [contentItems]
  );

  if (!orgId && isLoading) return null;

  return (
    <NavSectionContainer>
      <NavSectionHeader color={color}>{header}</NavSectionHeader>
      {memoizedContentItems.map((memoizedContentItem) => memoizedContentItem)}
    </NavSectionContainer>
  );
};

export default NavSection;
