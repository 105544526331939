import {
  combineReducers,
  createReducer,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  createInviteUserFail,
  createInviteUserRequest,
  createInviteUserSuccess,
  deleteInviteUserFail,
  deleteInviteUserRequest,
  deleteInviteUserSuccess,
  invitationDetailsFail,
  invitationDetailsRequest,
  invitationDetailsSuccess,
  inviteUsersListFail,
  inviteUsersListRequest,
  inviteUsersListSuccess,
  notifyInviteUserFail,
  notifyInviteUserRequest,
  notifyInviteUserSuccess,
  resetInviteUserModal,
  setModalInviteUser,
  setModalInviteUserType,
} from "./actions";
import {
  IDeleteUserInviteRequest,
  IInviteUser,
  IInviteUserModal,
  IInviteUserModalType,
  IUserInvite,
  UserInvitationFormValues,
} from "./types";

interface IInviteUserInitialState {
  list: IUserInvite[];
  inviteUserModal: IInviteUserModal;
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const INITIAL_STATE: IInviteUserInitialState = {
  list: [],
  inviteUserModal: {
    invite: undefined,
    usersInvites: [],
    type: undefined,
    isLoading: false,
    success: false,
    fail: false,
  },
  isLoading: false,
  success: false,
  fail: false,
};

const inviteUsersListReducer = createReducer(INITIAL_STATE, {
  [inviteUsersListRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [inviteUsersListSuccess.type]: (
    state,
    { payload }: PayloadAction<IUserInvite[]>
  ) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [inviteUsersListFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),
  [invitationDetailsRequest.type]: (state) => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [invitationDetailsSuccess.type]: (
    state,
    { payload }: PayloadAction<IUserInvite>
  ) => {
    const invitationIndex = state.list.findIndex(
      (invitation) => invitation.invitationId === payload.invitationId
    );

    if (invitationIndex === -1) {
      return {
        ...state,
        isLoading: false,
        success: true,
        fail: false,
      };
    }

    const invitationList = [...state.list];

    invitationList[invitationIndex] = payload;

    return {
      ...state,
      list: invitationList,
      isLoading: false,
      success: true,
      fail: false,
    };
  },
  [invitationDetailsFail.type]: (state) => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),

  [deleteInviteUserRequest.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [deleteInviteUserSuccess.type]: (
    state,
    { payload }: PayloadAction<IDeleteUserInviteRequest>
  ) => {
    const newList = state.list.filter(
      (item) => !payload.body.userIds.includes(+item.invitedOrgUserId)
    );

    return {
      ...state,
      list: newList,
      inviteUserModal: {
        ...state.inviteUserModal,
        isLoading: false,
        success: true,
        fail: false,
      },
    };
  },
  [deleteInviteUserFail.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [notifyInviteUserRequest.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [notifyInviteUserSuccess.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [notifyInviteUserFail.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [createInviteUserRequest.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [createInviteUserSuccess.type]: (
    state,
    { payload }: PayloadAction<IUserInvite>
  ) => ({
    ...state,
    list: [payload, ...state.list],
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [createInviteUserFail.type]: (state) => ({
    ...state,
    inviteUserModal: {
      ...state.inviteUserModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [setModalInviteUserType.type]: (
    state,
    { payload }: PayloadAction<IInviteUserModalType>
  ) => {
    return {
      ...state,
      inviteUserModal: {
        ...state.inviteUserModal,
        type: payload,
      },
    };
  },
  [setModalInviteUser.type]: (
    state,
    { payload }: PayloadAction<IInviteUser>
  ) => {
    return {
      ...state,
      inviteUserModal: {
        ...state.inviteUserModal,
        invite: {} as UserInvitationFormValues,
        // usersInvites: state.inviteUserModal.usersInvites.some(
        //   (item) => item.id === payload.id
        // )
        //   ? state.inviteUserModal.usersInvites.filter(
        //       (item) => item.id !== payload.id
        //     )
        //   : [...state.inviteUserModal.usersInvites, payload],
      },
    };
  },
  [resetInviteUserModal.type]: (state) => ({
    ...state,
    inviteUserModal: {
      invite: undefined,
      usersInvites: [],
      type: undefined,
      isLoading: false,
      success: false,
      fail: false,
    },
  }),
});

export interface IGeneralState {
  readonly general: IInviteUserInitialState;
}

export default combineReducers<IGeneralState>({
  general: inviteUsersListReducer,
});
