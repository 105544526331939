import { createAction } from '@reduxjs/toolkit';
import { IOrganizationTeam, IOrganizationTeamListItem, IOrganizationTeamMember, ITeamModalType } from './types';

export const teamsListRequest = createAction<void>('TEAMS_LIST/REQUEST');
export const teamsListSuccess = createAction<IOrganizationTeamListItem[]>('TEAMS_LIST/SUCCESS');
export const teamsListFail = createAction<Error>('TEAMS_LIST/FAIL');

export const teamRequest = createAction<number>('TEAM/REQUEST');
export const teamSuccess = createAction<IOrganizationTeam>('TEAM/SUCCESS');
export const teamFail = createAction<Error>('TEAM/FAIL');

export const createTeamRequest = createAction<IOrganizationTeamListItem>('CREATE_TEAM/REQUEST');
export const createTeamSuccess = createAction<IOrganizationTeamListItem>('CREATE_TEAM/SUCCESS');
export const createTeamFail = createAction<Error>('CREATE_TEAM/FAIL');

export const updateTeamRequest = createAction<IOrganizationTeamListItem>('UPDATE_TEAM/REQUEST');
export const updateTeamSuccess = createAction<IOrganizationTeamListItem>('UPDATE_TEAM/SUCCESS');
export const updateTeamFail = createAction<Error>('UPDATE_TEAM/FAIL');

export const deleteTeamRequest = createAction<void>('DELETE_TEAM/REQUEST');
export const deleteTeamSuccess = createAction<number>('DELETE_TEAM/SUCCESS');
export const deleteTeamFail = createAction<Error>('DELETE_TEAM/FAIL');

// TODO: post only ids
export const addTeamMembersRequest = createAction<IOrganizationTeamMember[]>('ADD_TEAM_MEMBER/REQUEST');
export const addTeamMembersSuccess = createAction<IOrganizationTeamMember[]>('ADD_TEAM_MEMBER/SUCCESS');
export const addTeamMembersFail = createAction<Error>('ADD_TEAM_MEMBER/FAIL');

export const setTeamModalType = createAction<ITeamModalType | undefined>('SET_MODAL_TEAM_TYPE');
export const resetTeamModal = createAction<void>('MODAL_TEAM_RESET');
export const setEmptyTeam = createAction<void>('SET_EMPTY_TEAM');
