import { deleteUserRequest, resetUserModal } from "@Store/Users/actions";
import {
  selectShowDeleteUserModal,
  selectUserModal,
} from "@Store/Users/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import { getUserName } from "@src/Utilities";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

function ModalDeleteUserUser(props: any) {
  const deleteState = useAppSelector(selectUserModal);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const show = useAppSelector(selectShowDeleteUserModal);
  const { user } = useAppSelector(selectUserModal);

  const onCancel = () => !deleteState.isLoading && dispatch(resetUserModal());
  const onOk = () => user && dispatch(deleteUserRequest(user));

  return (
    <Modal
      maskClosable={false}
      okText={t("buttons.delete")}
      okType={"danger"}
      cancelText={t("buttons.cancel")}
      title={t("deleteUser.messages.deleteTitle")}
      visible={show}
      confirmLoading={deleteState.isLoading}
      onCancel={onCancel}
      closeIcon={deleteState.isLoading}
      onOk={onOk}
    >
      <p>
        {t("messages.deleteGeneric", {
          text: getUserName({
            givenName: user?.givenName,
            surName: user?.surName,
            nickName: user?.nickName,
            displayName: user?.displayName,
            emailAddress: user?.emailAddress,
          }),
        })}
      </p>
    </Modal>
  );
}

export default ModalDeleteUserUser;
