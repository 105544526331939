import saga from "@Saga";
import AppUserReducer from "@Store/AppUser/reducers";
import InvitesReducer from "@Store/Invites/reducers";
import MasterDataReducer from "@Store/MasterData/reducers";
import MatrixReducer from "@Store/Matrix/reducers";
import OrganizationsReducer from "@Store/Organizations/reducers";
import BpContactPersonReducer from "@Store/Reducers/BpContactPersonReducer";
import TeamsReducer from "@Store/Teams/reducers";
import UsersReducer from "@Store/Users/reducers";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import AppReducer from "./App/reducers";

const sagaMiddleware = createSagaMiddleware({});

const store = configureStore({
  reducer: {
    app: AppReducer,
    appUser: AppUserReducer,
    organizations: OrganizationsReducer,
    masterData: MasterDataReducer,
    matrix: MatrixReducer,
    users: UsersReducer,
    invites: InvitesReducer,
    teams: TeamsReducer,
    bpContactPerson: BpContactPersonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunkMiddleware, sagaMiddleware),
});

sagaMiddleware.run(saga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const storeState = store.getState();

export default store;
