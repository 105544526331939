import { AppstoreOutlined } from "@ant-design/icons";
import AvatarAsync from "@Pages/AvatarAsync";
import { useHistory, useLocation } from "react-router-dom";

function OverviewOrganizationsButton() {
  const history: ReturnType<typeof useHistory> = useHistory();
  const location: ReturnType<typeof useLocation> = useLocation();

  return (
    <div className="organization-button">
      <div
        className={
          location.pathname === "/organizations" ? "inner active" : "inner"
        }
      >
        <div onClick={() => history.push(`/organizations`)}>
          <AvatarAsync
            size={45}
            url={<AppstoreOutlined />}
            shape="square"
            placeholderText={"View Organizations"}
          />
        </div>
      </div>
    </div>
  );
}

export default OverviewOrganizationsButton;
