import {
  FacebookOutlined,
  LinkOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Button, Image } from 'antd';
import React from 'react';

interface IIcon {
  active?: boolean;
}

export const UserIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    width={20}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/kontakte.svg`
        : `${process.env.PUBLIC_URL}/Assets/kontakte_off.svg`
    }
  />
);

export const TeamIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    width={20}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/workspace.svg`
        : `${process.env.PUBLIC_URL}/Assets/workspace_off.svg`
    }
  />
);

interface ISMIcon {
  url: string;
}

export const SocialNetworkIcon: React.FC<ISMIcon> = ({ url }) => {
  if (url.includes('youtube'))
    return <Button icon={<YoutubeOutlined />} onClick={() => {}} />;
  if (url.includes('facebook'))
    return <Button icon={<FacebookOutlined />} onClick={() => {}} />;
  if (url.includes('twitter'))
    return <Button icon={<TwitterOutlined />} onClick={() => {}} />;

  return <Button icon={<LinkOutlined />} onClick={() => {}} />;
};

export const DataIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    style={{backgroundColor: 'transparent'}}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/data.svg`
        : `${process.env.PUBLIC_URL}/Assets/data.svg`
    }
  />
);

export const SetupIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    style={{backgroundColor: 'transparent'}}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/setup.svg`
        : `${process.env.PUBLIC_URL}/Assets/setup.svg`
    }
  />
);

export const LogoutIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    style={{backgroundColor: 'transparent'}}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/logout.svg`
        : `${process.env.PUBLIC_URL}/Assets/logout.svg`
    }
  />
);

export const ArrowLeftIcon: React.FC<IIcon> = ({ active }) => (
  <Image
    preview={false}
    style={{backgroundColor: 'transparent'}}
    src={
      active
        ? `${process.env.PUBLIC_URL}/Assets/arrow_left.svg`
        : `${process.env.PUBLIC_URL}/Assets/arrow_left.svg`
    }
  />
);
