import { NewOrganizationPayload } from "@API/Organizations/types";
import { RootState } from "@Store";
import { IOrganization, OrganizationId } from "@Store/Organizations/types";

export const getInitialValues: NewOrganizationPayload = {
  orgName: "",
  matrixServerIdentifier: "",
  adminBpUserName: "",
};

export const formatData = (orga: any) => {
  const {
    superAdministratorAvatar,
    superAdministratorFirstname,
    superAdministratorLastname,
    superAdministratorEmail,
    superAdministratorPhoneNumbers,
    superAdministratorAddresses,
    superAdministratorDepartment,
    superAdministratorDepartmentJob,

    contactPersonAvatar,
    contactPersonFirstname,
    contactPersonLastname,
    contactPersonEmail,
    contactPersonPhoneNumbers,
    contactPersonAddresses,
    contactPersonDepartment,
    contactPersonDepartmentJob,

    bpContactPersonAvatar,
    bpContactPersonFirstname,
    bpContactPersonLastname,
    bpContactPersonEmail,
    bpContactPersonPhoneNumbers,
    bpContactPersonAddresses,
    bpContactPersonDepartment,
    bpContactPersonDepartmentJob,

    ...data
  } = orga;

  return {
    ...data,
    contactPerson: {
      avatar: contactPersonAvatar,
      firstname: contactPersonFirstname,
      lastname: contactPersonLastname,
      email: contactPersonEmail,
      numbers: contactPersonPhoneNumbers,
      addresses: contactPersonAddresses,
      department: contactPersonDepartment,
      departmentJob: contactPersonDepartmentJob,
    },
    bpContactPerson: {
      avatar: bpContactPersonAvatar,
      firstname: bpContactPersonFirstname,
      lastname: bpContactPersonLastname,
      email: bpContactPersonEmail,
      numbers: bpContactPersonPhoneNumbers,
      addresses: bpContactPersonAddresses,
      department: bpContactPersonDepartment,
      departmentJob: bpContactPersonDepartmentJob,
    },
    superAdministrator: {
      avatar: superAdministratorAvatar,
      firstname: superAdministratorFirstname,
      lastname: superAdministratorLastname,
      email: superAdministratorEmail,
      numbers: superAdministratorPhoneNumbers,
      addresses: superAdministratorAddresses,
      department: superAdministratorDepartment,
      departmentJob: superAdministratorDepartmentJob,
    },
  };
};

export const selectSystemEditOrganization =
  (searchOrgId: OrganizationId | undefined) =>
  (state: RootState): IOrganization => {
    if (!searchOrgId) return {} as IOrganization;

    const organization = state.organizations.system.organizationsList.find(
      ({ orgId }) => orgId === searchOrgId
    );

    return {} as IOrganization;
    // if (!organization) return {};
    // return {
    //   id: organization.searchOrgId,
    //   name: organization.orgName,
    //   customization: organization.customization,
    //   active: organization.active,
    //
    //   superAdministratorAvatar: organization.contactPerson.avatar,
    //   superAdministratorFirstname: organization.contactPerson.firstname,
    //   superAdministratorLastname: organization.contactPerson.lastname,
    //   superAdministratorEmail: organization.contactPerson.email,
    //   superAdministratorPhoneNumbers: organization.contactPerson.phoneNumbers,
    //   superAdministratorAddresses: organization.contactPerson.addresses,
    //   superAdministratorDepartment: organization.contactPerson.department,
    //   superAdministratorDepartmentJob: organization.contactPerson.departmentJob,
    //
    //   bpContactPersonAvatar: organization.contactPerson.avatar,
    //   bpContactPersonFirstname: organization.contactPerson.firstname,
    //   bpContactPersonLastname: organization.contactPerson.lastname,
    //   bpContactPersonEmail: organization.contactPerson.email,
    //   bpContactPersonPhoneNumbers: organization.contactPerson.phoneNumbers,
    //   bpContactPersonAddresses: organization.contactPerson.addresses,
    //   bpContactPersonDepartment: organization.contactPerson.department,
    //   bpContactPersonDepartmentJob: organization.contactPerson.departmentJob,
    //
    //   contactPersonAvatar: organization.contactPerson.avatar,
    //   contactPersonFirstname: organization.contactPerson.firstname,
    //   contactPersonLastname: organization.contactPerson.lastname,
    //   contactPersonEmail: organization.contactPerson.email,
    //   contactPersonPhoneNumbers: organization.contactPerson.phoneNumbers,
    //   contactPersonAddresses: organization.contactPerson.addresses,
    //   contactPersonDepartment: organization.contactPerson.department,
    //   contactPersonDepartmentJob: organization.contactPerson.departmentJob,
    // };
  };
