import styled from 'styled-components';

export const TableContainer = styled.div`
  background-color: white;
  button svg :nth-child(1) {
    fill: white;
  }
  button[aria-disabled='true'] svg :nth-child(1) {
    fill: grey;
  }
  :nth-child(n) {
    border-radius: 5px;
    background-color: transparent;
  }
  :nth-child(1) {
    background-color: transparent;
  }
`;
