import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ActionContainer, EditorContainer, PageContainer} from '@Pages/CompanyLegalPage/styles';
import ReactQuill from 'react-quill';
import {languageOptions} from '@Pages/CompanyLegalPage/consts';
import {useAppDispatch} from '@hooks';
import {addOrganizationPolicy} from '@Store/Organizations/actions';
import {Button, Modal, Select} from 'antd';
const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block', 'link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }, { 'align': [] }],
        ['clean'],
    ],
};
function PrivacyTab({type, currentOrganization, policyData}: { type: string, currentOrganization: any, policyData: any}) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const [language, setLanguage] = useState('english');
    const [contentEN, setContentEN] = useState(policyData?.contentDE);
    const [contentDE, setContentDE] = useState(policyData?.contentEN);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newLanguage, setNewLanguage] = useState(language);

    const updatePolicy = () => {
        const formData = {
            org_id: currentOrganization?.orgId,
            language: language,
            type: type,
            contentEN: contentEN,
            contentDE: contentDE,
        };
        dispatch(addOrganizationPolicy(formData));
    };

    useEffect(() => {
        setContentEN(policyData?.contentEN);
        setContentDE(policyData?.contentDE);
    }, [policyData]);

    const handleLanguageChange = (value: string) => {
        const hasChanges = (language === 'english' && contentEN !== policyData?.contentEN) ||
            (language === 'german' && contentDE !== policyData?.contentDE);
        if (hasChanges) {
            setNewLanguage((prevState) => (prevState === 'english' ? 'german' : 'english'));
            setIsModalVisible(true);
        } else {
            setLanguage(value);
        }
    };

    const handleConfirmChangeLanguage = () => {
        setLanguage(newLanguage);
        if (newLanguage === 'english') {
            setContentDE(policyData?.contentDE);
        } else {
            setContentEN(policyData?.contentEN);
        }
        setIsModalVisible(false);
    };

    const handleCancelChangeLanguage = () => {
        setIsModalVisible(false);
    };
    return (
        <PageContainer>
            <h2>{type === 'PRIVACY' ? t('sidebar.organization.privacy') : t('sidebar.organization.terms')}</h2>

            <Select
                value={language}
                placeholder={t('organizationPolicies.selectLanguage')}
                onChange={handleLanguageChange}
                mode={undefined}
                showSearch={false}
                style={{width: '40%', marginBottom: '25px'}}
            >
                {languageOptions.map(({label, value}) => (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                ))}
            </Select>

            <EditorContainer key={language}>
                {language === 'english' ? (
                    <ReactQuill theme="snow" value={contentEN} onChange={setContentEN} modules={modules} />
                ) : (
                    <ReactQuill theme="snow" value={contentDE} onChange={setContentDE} modules={modules} />
                )}
            </EditorContainer>


            <ActionContainer>
                <Button type="primary" onClick={updatePolicy}>
                    {t('sidebar.organization.submit')}
                </Button>
            </ActionContainer>

            <Modal
                title={t('sidebar.organization.changeLanguage.title')}
                visible={isModalVisible}
                onOk={handleConfirmChangeLanguage}
                onCancel={handleCancelChangeLanguage}
                cancelText={t('cancel')}
                okText={t('confirm')}
            >
                {t('sidebar.organization.changeLanguage.content')}
            </Modal>
        </PageContainer>
    );
}

export default PrivacyTab;
