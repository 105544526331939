import styled from 'styled-components';

export const PageContainer = styled.div`
    width: 100%;
`;

export const SideBarManipulator = styled.div`
  background: black;
  padding: 12px 0 12px;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;
