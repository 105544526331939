import styled from 'styled-components';

export const TextContainer = styled.div`
  height: 60px;
  margin-top: 16px;
  span {
    cursor: pointer;
  }
`;

export const FormContainer = styled.div`
  ${({ theme }) => theme.media.desktop} {
    width: 40%;
    padding: 30px 15px 20px;
  }
  padding: 30px 90px 20px;
  width: 50%;
`;

export const PageContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  overflow-y: scroll;
`;
