import "./index.scss";

import OverviewNavigation from "@Components/OverviewNavigation";
import { RootState } from "@Store";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { inviteUsersListRequest } from "@Store/Invites/actions";
import {
  selectCurrentOrganization,
  selectIsAdminOfCurrentOrganization,
  selectOrganizationsGeneralData,
} from "@Store/Organizations/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import MainComponent from "@src/MainComponent";
import { useEffect } from "react";

function OrganizationOverviewPage(props: any) {
  const dispatch = useAppDispatch();

  const {
    currentOrganization,
    IsAdminOfCurrentOrganization,
    selectOrganizationsGeneralData: { isLoading },
    userInformation,
  } = useAppSelector((state: RootState) => ({
    currentOrganization: selectCurrentOrganization(state),
    IsAdminOfCurrentOrganization: selectIsAdminOfCurrentOrganization(state),
    selectOrganizationsGeneralData: selectOrganizationsGeneralData(state),
    userInformation: selectAppUserData(state),
  }));



  // TODO: add translation
  return (
    <MainComponent>
      {!isLoading && (
        <div className="app__organization">
          <OverviewNavigation />
        </div>
      )}
    </MainComponent>
  );
}

export default OrganizationOverviewPage;
