import {Redirect, Route, Switch} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "@hooks";
import {RootState} from "@Store";
import {selectAppUserData, selectIsSystemAdmin} from "@Store/AppUser/selectors";
import {selectIsAuthenticated} from "@Store/App/selectors";
import {
    selectIsAdminOfAtLeaseOneOrganization,
    selectIsAdminOfCurrentOrganization
} from "@Store/Organizations/selectors";
import {useEffect} from "react";
import {appUserDataRequest} from "@Store/AppUser/actions";
import LoginPage from "@Pages/Login";
import BpContactPersonListPage from "@Pages/BpContactPersonListPage";
import OrganizationsPage from "@Pages/OrganizationsPage";
import OrganizationOverviewPage from "@Pages/OrganizationOverviewPage";
import AdminsPage from "@Pages/AdminsPage";
import CorporatePage from "@Pages/CorporatePage";
import CompanyLegalPage from "@Pages/CompanyLegalPage";
import UsersPage from "@Pages/UsersPage";
import DashboardPage from "@Pages/Dashboard";

function MainRouter() {
    const dispatch = useAppDispatch();

    const {
        isAuthenticated,
        isSystemAdmin,
        isAdminOfAtLeaseOneOrganization,
    } = useAppSelector((state: RootState) => ({
        userData: selectAppUserData(state),
        isAuthenticated: selectIsAuthenticated(state),
        isSystemAdmin: selectIsSystemAdmin(state),
        isAdminOfAtLeaseOneOrganization: selectIsAdminOfAtLeaseOneOrganization(state),
    }));

    useEffect(() => {
        if (!isAuthenticated) return;
        dispatch(appUserDataRequest());
    }, [dispatch, isAuthenticated]);

    if (!isAuthenticated) {
        return (
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Redirect to="/login" />
            </Switch>
        );
    }

    // Assuming isAuthenticated is true from this point
    return (
        <Switch>
            <Route path="/dashboard" component={DashboardPage} />
            <Route path="/bpContactPersons" component={BpContactPersonListPage} />
            <Route path="/organizations" component={OrganizationsPage} />

            <Route path="/organization/:orgId" render={({ match }) => (
                <Switch>
                    <Route exact path={`${match.path}/overview`} component={OrganizationOverviewPage} />
                    <Route exact path={`${match.path}/admins`} component={AdminsPage} />
                    <Route exact path={`${match.path}/users`} component={UsersPage} />
                    <Route exact path={`${match.path}/corporate`} component={CorporatePage} />
                    <Route exact path={`${match.path}/policies`} component={CompanyLegalPage} />
                    <Redirect to="/dashboard" />
                </Switch>
            )} />

            <Redirect from="*" to="/dashboard" />
        </Switch>
    );
}

export default MainRouter;
