import "./index.scss";

import { TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, AvatarProps, Tooltip } from "antd";
import { AvatarSize } from "antd/lib/avatar/SizeContext";
import { ReactNode } from "react";

export type AvatarAsyncProps = {
  url: ReactNode;
  shape?: AvatarProps["shape"];
  size: AvatarSize | "big" | "medium";
  placeholderText?: string;
  individual?: boolean;
  children?: ReactNode;
};

const AvatarAsync = (props: AvatarAsyncProps) => {
  const { url, shape, size, placeholderText }: AvatarAsyncProps = props;

  const getAvatarSize = (): AvatarSize => {
    switch (size) {
      case "big":
        return 128;
      case "medium":
        return 90;
      default:
        return size;
    }
  };

  const setIcon = (): JSX.Element =>
    props.individual ? <UserOutlined /> : <TeamOutlined />;

  return (
    <Tooltip placement="right" title={placeholderText}>
      <Avatar
        shape={shape}
        size={getAvatarSize()}
        src={url || null}
        icon={url ? null : setIcon()}
      />
    </Tooltip>
  );
};
export default AvatarAsync;
