const createMediaQuery = (size: number) => `
  @media (max-width: ${size}px)
`;

export const theme = {
  palette: {
    black: '#000',
    white: '#fff',
    mainYellow: '#E7B14F',
    mainGreen: '#69A071',
    mainRed: '#C4482B',
    mainPurple: '#7B394E',
    gray: '#4C4C4C',
  },

  media: {
    hd: createMediaQuery(1439),
    desktop: createMediaQuery(1379),
    tablet: createMediaQuery(1023),
    phone: createMediaQuery(767),
  },
};
