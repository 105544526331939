import { PlusOutlined } from '@ant-design/icons';
import { IAddress } from '@Store/MasterData/types';
import { Button, List } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import AddressItem from './AddressItem';

interface IAddressesForm {
  addresses: IAddress[];
  setAddresses: (arr: IAddress[]) => void;
  loading?: boolean;
  isRequired?: boolean;
}

const AddressesForm: React.FC<IAddressesForm> = ({
  addresses,
  setAddresses,
  loading = false,
  isRequired = false,
}) => {
  const { t } = useTranslation();

  const [newAddressMode, setNewAddressMode] = useState<boolean>(false);

  const addNewAddress = () => {
    const newTmpAddresses: IAddress[] = _.cloneDeep(addresses);

    newTmpAddresses.unshift({
      id: 0,
      addressType: undefined,
      city: '',
      country: undefined,
      street: '',
      streetNumber: '',
      zip: '',
    });

    setAddresses(newTmpAddresses);
    setNewAddressMode(true);
  };

  const onCancelAddress = (id: number) => {
    if (id === 0) {
      const newTmpAddresses: IAddress[] = _.cloneDeep(addresses);
      newTmpAddresses.shift();
      setAddresses(newTmpAddresses);
      setNewAddressMode(false);
    }
  };

  const onDeleteAddress = (id: number) => {
    const newTmpAddresses: IAddress[] = _.cloneDeep(addresses);
    var idx = newTmpAddresses.findIndex((a) => a.id === id);
    newTmpAddresses.splice(idx, 1);
    setAddresses(newTmpAddresses);
  };

  const addAddress = (values: IAddress) => {
    const idx = addresses.findIndex((a) => a.id === values.id);

    const newArray: IAddress[] = _.cloneDeep(addresses);

    if (values.id === 0) {
      values.id = uuidv4();
    }

    if (idx === -1) {
      newArray.push(values);
    } else {
      newArray[idx] = values;
    }

    setAddresses(newArray);
    setNewAddressMode(false);
  };

  return (
    <List
      bordered
      size='small'
      dataSource={addresses}
      header={
        <div className='listHeader'>
          <div>
            <i>{isRequired && '* '}</i>
            {t('modals.address.title')}
          </div>
          <div>
            <Button
              disabled={loading || newAddressMode}
              ghost
              icon={<PlusOutlined />}
              onClick={() => addNewAddress()}
            />
          </div>
        </div>
      }
      renderItem={(item) =>
        item && [
          <List.Item>
            <List.Item.Meta
              title={
                item.id === 0
                  ? t('modals.address.newAddresses')
                  : t(`addressTypes.${item.addressType?.name}`)
              }
              description={
                <AddressItem
                  onCancle={(id: number) => onCancelAddress(id)}
                  onDelete={(id: number) => onDeleteAddress(id)}
                  onSave={(data: IAddress) => addAddress(data)}
                  address={item}
                  loading={loading}
                ></AddressItem>
              }
            />
          </List.Item>,
        ]
      }
    />
  );
};

export default AddressesForm;
