import { postAxiosTokenRefresh, postLoginRequest } from "@API/App/endpoints";
import {
  ApiAuthenticationResponse,
  IPostAxiosTokenRefresh,
  IPostLoginRequest,
} from "@API/App/types";
import i18n from "@i18n";
import { PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { AxiosError, HttpStatusCode, isAxiosError } from "axios";
import jwtDecode from "jwt-decode";
import {
  call,
  put,
  race,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  loginFail,
  loginRequest,
  loginSuccess,
  logoutFail,
  logoutRequest,
  logoutSuccess,
  tokenRefreshFail,
  tokenRefreshRequest,
  tokenRefreshSuccess,
} from "./actions";
import { ILoginData } from "./types";

const ignoreActionTypes = ["LOGIN", "LOGOUT", "TOKEN_REFRESH"];

function requestFailAction(action: any) {
  return (
    action.type.includes("REQUEST") &&
    ignoreActionTypes.every(
      (fragment: string) => !action.type.includes(fragment)
    )
  );
}

function identifyAction(action: any) {
  return action.type.replace(/\/.+$/, "");
}

export function getSuccessType(action: any) {
  return `${identifyAction(action)}/SUCCESS`;
}

export function getFailType(action: any) {
  return `${identifyAction(action)}/FAIL`;
}

function* monitor(monitoredAction: any) {
  const accessToken: string | null = localStorage.getItem("accessToken");

  if (!accessToken) return;

  const accessTokenData: any = jwtDecode(accessToken);

  if (accessTokenData?.exp * 1000 <= Date.now()) {
    notification.info({ message: i18n.t("general.sessionExpired") });
    yield put(logoutRequest());
    return;
  }

  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (fail?.payload.isAxiosError) return;

  if (
    fail &&
    fail.payload &&
    (fail.payload.code === 401 || fail.payload.response?.status === 401)
  ) {
    yield put(tokenRefreshRequest());

    const { success } = yield race({
      success: take(tokenRefreshSuccess),
      fail: take(tokenRefreshFail),
    });

    if (success) {
      yield put(monitoredAction);
    } else {
      yield put(logoutRequest());
    }
  }
}

function* onLoginRequest({ payload }: PayloadAction<ILoginData>) {
  try {
    const loginResponse: IPostLoginRequest = yield call(
      postLoginRequest,
      payload
    );

    if (loginResponse.error) throw loginResponse.error;

    const refreshedTokenData: ApiAuthenticationResponse = loginResponse.data;

    localStorage.setItem("tokenType", refreshedTokenData.token_type);
    localStorage.setItem("accessToken", refreshedTokenData.access_token);
    localStorage.setItem("refreshToken", refreshedTokenData.refresh_token);

    yield put(loginSuccess());

    notification.success({
      message: i18n.t("general.loginSuccess"),
    });
  } catch (error) {
    const errorMessage =
      isAxiosError(error) && error.response
        ? error.response.data.message
        : (error as AxiosError).message;

    yield put(loginFail(error as Error));

    notification.error({
      message: i18n.t("general.loginFail"),
      description: errorMessage,
    });
  }
}

function* onTokenRefreshRequest() {
  try {
    const refreshedTokenResponse: IPostAxiosTokenRefresh = yield call(
      postAxiosTokenRefresh
    );

    const error: HttpStatusCode | undefined = refreshedTokenResponse.error;
    const refreshedTokenData: ApiAuthenticationResponse =
      refreshedTokenResponse.data;

    if (error !== HttpStatusCode.Ok) {
      throw new Error(`HTTP status: ${error}`);
    }

    localStorage.setItem("tokenType", refreshedTokenData.token_type);
    localStorage.setItem("accessToken", refreshedTokenData.access_token);
    localStorage.setItem("refreshToken", refreshedTokenData.refresh_token);

    yield put(tokenRefreshSuccess());
  } catch (e) {
    console.error(e);
    yield put(tokenRefreshFail(e as Error));
  }
}

function* onLogoutRequest() {
  localStorage.removeItem("tokenType");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");

  try {
    yield put(logoutSuccess());
    window.location.reload();
  } catch (e) {
    yield put(logoutFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(loginRequest, onLoginRequest);
  yield takeLatest(tokenRefreshRequest, onTokenRefreshRequest);
  yield takeLatest(logoutRequest, onLogoutRequest);
  yield takeEvery(requestFailAction, monitor);
}
