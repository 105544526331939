import axios, { AxiosInstance } from 'axios';

export let http: AxiosInstance;
export let headers: any = undefined;
export const createHttpInstance = (tokenType: string, accessToken: string) => {
  try {
    headers = {
      Authorization: `${tokenType} ${accessToken}`,
    };

    http = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    });
  } catch (error: any) {
    console.error(error);
  }
};
