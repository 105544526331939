import "./index.scss";

import { RootState } from "@Store";
import { setLanguage } from "@Store/App/actions";
import { selectLanguage } from "@Store/App/selectors";
import { lang } from "@Store/App/types";
import { GlobalOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Modal, Select, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { De, Gb } from "react-flags-select";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function LanguageSelectAction() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const { selectedLanguage } = useAppSelector((state: RootState) => ({
    selectedLanguage: selectLanguage(state),
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    moment.locale(selectedLanguage);
    i18n
      .changeLanguage(selectedLanguage)
      .catch((reason) => console.error(reason));
  }, [i18n, selectedLanguage]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSelect = (value: lang) => {
    dispatch(setLanguage(value));
  };

  return (
    <Tooltip
      key="signout"
      title=''
      placement="bottom"
    >
      <Select onChange={onSelect} defaultValue={selectedLanguage}>
        <Option key="de" value="de">
          <De />
        </Option>
        <Option key="en" value="en">
          <Gb />
        </Option>
      </Select>
    </Tooltip>
  );
}

export default LanguageSelectAction;
