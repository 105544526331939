import React, { useEffect, useState } from 'react';
import { Image } from "antd";
import {
    FileUploadContainer, FileUploadInfo,
    HiddenInput, ImagePlaceholderContainer,
    InputLabel,
    RemoveButton,
    UploadBox, UploadButton,
    UploadedImage
} from "@Components/FileUpload/styles";

interface FileUploadProps {
    id: string;
    name: string;
    accept: string;
    formData: FormData;
    updateParentFormData: (formData: FormData) => void;
    labelText: string;
    initialFileSrc?: string;
    onImageStateChange?: (b: boolean) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
                                                   id,
                                                   name,
                                                   accept,
                                                   formData,
                                                   updateParentFormData,
                                                   labelText,
                                                   initialFileSrc,
                                                   onImageStateChange
                                               }) => {
    const [fileSrc, setFileSrc] = useState(initialFileSrc);

    useEffect(() => {
        setFileSrc(initialFileSrc);
    }, [initialFileSrc]);

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        setFileSrc(URL.createObjectURL(file));
        formData.delete(name);
        formData.append(name, file);
        updateParentFormData(formData);
        onImageStateChange?.(true);
    };

    const removeFile = () => {
        setFileSrc('');
        formData.delete(name);
        formData.append(name, '');
        updateParentFormData(formData);
        onImageStateChange?.(false);
    };

    return (
        <FileUploadContainer>
            <UploadBox>
                {fileSrc ? (<div>
                    <UploadedImage
                        src={fileSrc}
                        alt="Uploaded preview"
                    />
                    <RemoveButton onClick={removeFile}>
                        x
                    </RemoveButton>
                </div>) : <ImagePlaceholderContainer>
                    <Image
                        width={20}

                        src={`${process.env.PUBLIC_URL}/Assets/imagePlaceholder.svg`}
                    />
                </ImagePlaceholderContainer>}
            </UploadBox>

            <FileUploadInfo>
                Min 200x200px, max 5MB
            </FileUploadInfo>

            <InputLabel htmlFor={id}>
                <HiddenInput
                    id={id}
                    type="file"
                    name={name}
                    accept={accept}
                    onChange={handleFileChange}
                />
                <UploadButton>
                    {labelText}
                </UploadButton>
            </InputLabel>
        </FileUploadContainer>
    );
};

export default FileUpload;
