import { toggleSideBarCollapsed } from "@Store/App/actions";
import { selectSideBarCollapsed } from "@Store/App/selectors";
import {
  selectCurrentOrganization,
  selectOrganizationsGeneralData,
} from "@Store/Organizations/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import { SideBarManipulator } from "@src/commonStyles";
import { UnmountClosed } from "react-collapse";
import HamburgerMenu from "react-hamburger-menu";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, ProSidebar, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import { Container, SidebarContainer, SidebarItemContainer } from "./styles";
import {selectIsSystemAdmin} from "@Store/AppUser/selectors";

function SideBar() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const params: {orgId: string} = useParams();
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const { isLoading } = useAppSelector(selectOrganizationsGeneralData);
  const sideBarCollapsed = useAppSelector(selectSideBarCollapsed);
  const isUserSystemAdmin = useAppSelector((state) => ({
      isSystemAdmin: selectIsSystemAdmin(state)
  }));

  const orgId = params.orgId;

  const orgDataDefaultOpen = path.includes("data")
      || path.includes("admins")
      || path.includes("corporate")
      || path.includes("policies");
  const appSettingsDefaultOpen =
    path.includes("users") || path.includes("teams");

  return orgId ? (
    <Container>
      <SideBarManipulator onClick={() => dispatch(toggleSideBarCollapsed())}>
        <HamburgerMenu
          isOpen={sideBarCollapsed}
          menuClicked={() => {}}
          width={18}
          height={15}
          strokeWidth={1}
          rotate={0}
          color="white"
          borderRadius={0}
          animationDuration={0.5}
        />
      </SideBarManipulator>
      <UnmountClosed isOpened={sideBarCollapsed}>
        <SidebarContainer>
          <ProSidebar>
            <Menu subMenuBullets={true}>
              <SidebarItemContainer color={"white"}>
                <MenuItem
                  className={path.includes("overview") ? "subMenuActive" : ""}
                >
                  {t("sidebar.overview")}
                  <Link
                    to={`/organization/${orgId}/overview`}
                  />
                </MenuItem>
              </SidebarItemContainer>
              <SidebarItemContainer color={"mainYellow"}>
                <SubMenu
                  title={t("sidebar.organization.data")}
                  className={orgDataDefaultOpen ? "subMenuActive" : ""}
                  defaultOpen
                >
                  <MenuItem active={path.includes("admins")}>
                    {t("sidebar.organization.admins")}
                    <Link
                      to={`/organization/${orgId}/admins`}
                    />
                  </MenuItem>
                  {isUserSystemAdmin && (
                    <>
                      <MenuItem active={path.includes("corporate")}>
                        {t("sidebar.organization.corporate")}
                        <Link
                            to={`/organization/${orgId}/corporate`}
                        />
                      </MenuItem>
                      <MenuItem active={path.includes("policies")}>
                        {t("sidebar.organization.policies")}
                        <Link
                            to={`/organization/${orgId}/policies`}
                        />
                      </MenuItem></>
                      )}
                </SubMenu>
              </SidebarItemContainer>
              <SidebarItemContainer color={"mainRed"}>
                <SubMenu
                  title={t("sidebar.app.title")}
                  className={appSettingsDefaultOpen ? "subMenuActive" : ""}
                  defaultOpen
                >
                  <MenuItem active={path.includes("users")}>
                    {t("sidebar.app.users")}
                    <Link
                      to={`/organization/${orgId}/users`}
                    />
                  </MenuItem>
                  {/*<MenuItem active={path.includes("teams")}>*/}
                  {/*  {t("sidebar.app.teams")}*/}
                  {/*  <Link*/}
                  {/*    to={`/organization/${currentOrganization?.orgId}/teams`}*/}
                  {/*  />*/}
                  {/*</MenuItem>*/}
                </SubMenu>
              </SidebarItemContainer>
            </Menu>
          </ProSidebar>
        </SidebarContainer>
      </UnmountClosed>
    </Container>
  ) : null;
}

export default SideBar;
