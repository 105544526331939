import { UploadOutlined } from '@ant-design/icons';
import AvatarAsync from '@Pages/AvatarAsync';
import { headers } from '@src/app/Http';
import { Button, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { FunctionComponent, useState } from 'react';

export interface IImageUpload {
  disabled?: boolean;
  beforeUploadCb?: () => void;
  onDoneCb?: (info: any) => void;
  onErrorCb?: (info: any) => void;
  updateUrl?: string;
  getUrl?: string;
  shape?: 'square' | 'circle';
  placeholderText?: string;
}

const ImageUpload: FunctionComponent<IImageUpload> = ({
  disabled = false,
  beforeUploadCb = () => {},
  onDoneCb = () => {},
  onErrorCb = () => {},
  updateUrl = '',
  getUrl = '',
  shape = 'square',
  placeholderText,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div className='center_image' style={{ paddingBottom: 20 }}>
      <div style={{ paddingBottom: 20 }}>
        {placeholderText ? (
          <AvatarAsync shape={shape} size={'big'} url={getUrl}>
            {placeholderText}
          </AvatarAsync>
        ) : (
          <AvatarAsync shape={shape} size={'big'} url={getUrl} />
        )}
      </div>
      <ImgCrop rotate>
        <Upload
          disabled={loading || disabled}
          beforeUpload={() => {
            beforeUploadCb();
            setLoading(true);
            return true;
          }}
          listType='text'
          maxCount={1}
          showUploadList={false}
          headers={headers}
          onChange={(info: any) => {
            if (info.file.status === 'done') {
              onDoneCb(info);
            }
            if (info.file.status === 'error') onErrorCb(info);
            setLoading(false);
            return true;
          }}
          action={updateUrl}
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default ImageUpload;
