import "../../index.scss";

import Table from "@Components/Table";
import { INVITE_USERS_TABLE_COLUMNS } from "@Pages/UsersPage/inviteUsersColumns";
import {
  setModalInviteUser,
  setModalInviteUserType,
} from "@Store/Invites/actions";
import {
  selectInviteUserModal,
  selectInvitedUsers,
} from "@Store/Invites/selectors";
import {
  IInviteUser,
  IInviteUserModal,
  IUserInvite,
} from "@Store/Invites/types";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { IOrganization } from "@Store/Organizations/types";
import { MailOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";

type InvitationTabProps = {
  dispatch: ReturnType<typeof useAppDispatch>;
};

function InvitationTabContent(props: InvitationTabProps) {
  const { dispatch } = props;

  const { t } = useTranslation();

  const invitedUsersList: IUserInvite[] = useAppSelector(selectInvitedUsers);
  const currentOrganization: IOrganization = useAppSelector(
    selectCurrentOrganization
  );
  const { usersInvites: selectedInviteUsers }: IInviteUserModal =
    useAppSelector(selectInviteUserModal);

  const [inviteUsersTableColumns, setInviteUsersTableColumns] = useState<
    TableColumn<any>[]
  >(INVITE_USERS_TABLE_COLUMNS(() => {}, invitedUsersList));

  useEffect(() => {
    setInviteUsersTableColumns(
      INVITE_USERS_TABLE_COLUMNS(() => {}, invitedUsersList)
    );

    const intervalId: NodeJS.Timeout = setInterval(() => {
      const updatedValue: TableColumn<any>[] = INVITE_USERS_TABLE_COLUMNS(
        () => {},
        invitedUsersList
      );
      setInviteUsersTableColumns(updatedValue);
    }, 1_000);

    return () => clearInterval(intervalId);
  }, [invitedUsersList]);

  const inviteUserConditionalRowStyles = (array: IInviteUser[]) => [
    {
      when: (row: IInviteUser) =>
        array.some(
          (selected: IInviteUser) => row.id && row.id === selected?.id
        ),
      style: {
        backgroundColor: "#282117",
      },
    },
  ];

  return (
    <>
      <Row justify={"space-between"} style={{ padding: 20 }}>
        <div
          style={{
            paddingBottom: 15,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            justifyItems: "flex-end",
          }}
        >
          <div className="inviteButtonContainer">
            <div style={{ paddingRight: 10 }}>
              <Button
                className="buttonWithImage"
                type="primary"
                style={{ height: 40, textTransform: "uppercase" }}
                onClick={() => {
                  dispatch(setModalInviteUserType("invite"));
                  dispatch(setModalInviteUser());
                }}
                icon={<MailOutlined />}
              >
                {t("inviteUser.buttons.inviteUser")}
              </Button>
            </div>
            {/*<div style={{ paddingRight: 10 }}>*/}
            {/*  <Button*/}
            {/*    disabled={selectedInviteUsers.length <= 0}*/}
            {/*    className="buttonWithImage"*/}
            {/*    type="default"*/}
            {/*    style={{ height: 40, textTransform: "uppercase" }}*/}
            {/*    onClick={() => {*/}
            {/*      if (currentOrganization?.orgId && invitedUsersList) {*/}
            {/*        dispatch(setModalInviteUserType("notify"));*/}
            {/*      }*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Einladung verschicken{" "}*/}
            {/*    <span style={{ paddingLeft: 10 }}>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          height: 15,*/}
            {/*          width: 15,*/}
            {/*          backgroundSize: "contain",*/}
            {/*          backgroundRepeat: "no-repeat",*/}
            {/*          backgroundPosition: "center",*/}
            {/*          backgroundImage: `url('${process.env.PUBLIC_URL}/Assets/add.svg')`,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*  </Button>*/}
            {/*</div>*/}
            {/*<div style={{ paddingRight: 10 }}>*/}
            {/*  <Button*/}
            {/*    disabled={selectedInviteUsers.length <= 0}*/}
            {/*    className="buttonWithImage"*/}
            {/*    type="primary"*/}
            {/*    danger*/}
            {/*    style={{ height: 40, textTransform: "uppercase" }}*/}
            {/*    onClick={() => {*/}
            {/*      if (currentOrganization?.orgId && invitedUsersList) {*/}
            {/*        dispatch(setModalInviteUserType("delete"));*/}
            {/*      }*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Einladung löschen{" "}*/}
            {/*    <span style={{ paddingLeft: 10 }}>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          height: 15,*/}
            {/*          width: 15,*/}
            {/*          backgroundSize: "contain",*/}
            {/*          backgroundRepeat: "no-repeat",*/}
            {/*          backgroundPosition: "center",*/}
            {/*          backgroundImage: `url('${process.env.PUBLIC_URL}/Assets/delete.svg')`,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*  </Button>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <Button*/}
            {/*    className="buttonWithImage"*/}
            {/*    type="primary"*/}
            {/*    style={{ height: 40, textTransform: "uppercase" }}*/}
            {/*    onClick={() => dispatch(setModalInviteUserType("invite"))}*/}
            {/*  >*/}
            {/*    Benutzer hinzufügen{" "}*/}
            {/*    <span style={{ paddingLeft: 10 }}>*/}
            {/*      <div*/}
            {/*        style={{*/}
            {/*          height: 15,*/}
            {/*          width: 15,*/}
            {/*          backgroundSize: "contain",*/}
            {/*          backgroundRepeat: "no-repeat",*/}
            {/*          backgroundPosition: "center",*/}
            {/*          backgroundImage: `url('${process.env.PUBLIC_URL}/Assets/add_black.svg')`,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </span>*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </div>
      </Row>
      <Row style={{ height: "100%", width: "100%", overflow: "hidden" }}>
        <Col
          span={24}
          style={{ paddingLeft: 20, paddingRight: 20, height: "100%" }}
        >
          <div style={{ height: "100%" }}>
            <Table
              columns={inviteUsersTableColumns}
              data={invitedUsersList}
              highlightOnHover
              conditionalRowStyles={inviteUserConditionalRowStyles(
                selectedInviteUsers
              )}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default InvitationTabContent;
