import { Button, Image } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type OrganizationBannerProps = {
  bannerImageUrl: string;
};

function OrganizationBanner(props: OrganizationBannerProps) {
  const { bannerImageUrl }: OrganizationBannerProps = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [isBannerAvailable, setIsBannerAvailable] = useState(false);

  if (!isBannerAvailable) {
    return (
      <Image
        style={{ display: "none" }}
        src={`${bannerImageUrl}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
        onError={() => setIsBannerAvailable(false)}
        onLoad={() => setIsBannerAvailable(true)}
      />
    );
  }

  return (
    <>
      <Button type="dashed" onClick={() => setVisible(true)}>
        {t("organization.buttons.previewBanner")}
      </Button>
      <Image
        style={{ display: "none" }}
        src={`${bannerImageUrl}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
        onError={() => setIsBannerAvailable(false)}
        preview={{
          visible,
          src: bannerImageUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </>
  );
}

export default OrganizationBanner;
