import SearchInput from "@Components/SearchInput";
import { debounce } from "@src/app/helpers/general";
import { useMemo, useState } from "react";
import DataTable, {
  createTheme,
  IDataTableProps,
  TableColumn,
} from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { TableContainer } from "./styles";

export const sortFunction = (format: any) => (a: any, b: any) => {
  if (format(a) > format(b)) {
    return 1;
  }

  if (format(b) > format(a)) {
    return -1;
  }

  return 0;
};

export interface ITable extends IDataTableProps<any> {
  withMoreMenuColumn?: boolean;
  isStriped?: boolean;
}

// @ts-ignore
export interface IColumn extends TableColumn<any> {
  selector?: string;
}

createTheme("solarized", {
  text: {
    primary: "white",
    secondary: "rgba(255, 255, 255, 0.85)",
  },
  context: {
    // background: '#cb4b16',
    text: "#FFFFFF",
  },
  background: {
    default: "#1d1d1d",
  },
  striped: {
    default: "#1d1d1d",
    text: "rgba(255, 255, 255, 0.85)",
  },
});

const customStyles = {
  rows: {
    style: {
      color: "rgba(255, 255, 255, 0.85)",
      fontVariant: "tabular-nums",
      fontSize: "14px",
      background: "#141414",
      "&:hover": {
        backgroundColor: "#202020!important",
        color: "white!important",
      },
    },
  },
  headCells: {
    style: {
      color: "rgba(255, 255, 255, 0.85)",
      fontWeight: 500,
      background: "#1d1d1d",
      borderBottom: "1px solid #303030",
      fontSize: "14px",
      fontVariant: "tabular-nums",
      width: "fit-content",
      padding: "8px",
    },
  },
  cells: {
    style: {
      padding: "4px 8px",
      width: "fit-content",
    },
  },
};

const Table = ({ isStriped = true, data, ...props }: ITable) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>("");

  const filteredData = useMemo(
    () =>
      data.filter((item) =>
        Object.values(item)
          .map((value) => {
            if (value && (Array.isArray(value) || typeof value === "object")) {
              return Object.values(value).join(" ");
            }
            return value;
          })
          .join(" ")
          .replace(/\s+/, " ")
          .toLowerCase()
          .includes(searchText)
      ),
    [data, searchText]
  );

  const paginationOptions = {
    rowsPerPageText: t("table.rowsPerPageText"),
    rangeSeparatorText: t("table.rangeSeparatorText"),
  };

  return (
    <TableContainer>
      <SearchInput
        onChangeHandler={debounce(
          (text) => setSearchText(text.replace(/\s+/, " ").toLowerCase()),
          300
        )}
      />
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="60vh"
        striped={isStriped}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        noHeader
        noDataComponent={t("table.noDataInTable")}
        paginationComponentOptions={paginationOptions}
        customStyles={customStyles}
        theme="solarized"
        data={filteredData}
        {...props}
      />
    </TableContainer>
  );
};

export default Table;
