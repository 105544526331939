import i18n from "@i18n";
import { PayloadAction } from "@reduxjs/toolkit";
import { notification } from "antd";
import { takeEvery } from "redux-saga/effects";

function* onError({ payload }: PayloadAction<{ error: any; response: any }>) {
  const isError = payload instanceof Error;

  if (!payload?.error && !isError) return;

  if (isError) {
    console.error(payload);
    return;
  }

  if (payload?.response?.status !== 401) return;

  const message = i18n.t(`errors.${payload?.response?.status || -1}`);

  notification.error({ message });
}

export default function* () {
  yield takeEvery("*", onError);
}
