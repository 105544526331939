import AppSaga from "@Store/App/saga";
import AppUserSaga from "@Store/AppUser/saga";
import InvitesSaga from "@Store/Invites/saga";
import MasterDataSaga from "@Store/MasterData/saga";
import MatrixSaga from "@Store/Matrix/saga";
import NotificationSaga from "@Store/Notification/saga";
import OrganizationsSaga from "@Store/Organizations/saga";
import TeamsSaga from "@Store/Teams/saga";
import UsersSaga from "@Store/Users/saga";
import { spawn } from "redux-saga/effects";

export default function* () {
  yield spawn(NotificationSaga);
  yield spawn(AppSaga);
  yield spawn(AppUserSaga);
  yield spawn(OrganizationsSaga);
  yield spawn(MasterDataSaga);
  yield spawn(MatrixSaga);
  yield spawn(UsersSaga);
  yield spawn(InvitesSaga);
  yield spawn(TeamsSaga);
}
