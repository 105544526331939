import "./index.scss";

import OverviewOrganizationsButton from "@Components/OrganizationSelection/OverviewOrganizationsButton";
import ReleaseNotes from "@Components/ReleaseNotes";
import AvatarAsync from "@Pages/AvatarAsync";
import {RootState} from "@Store";
import {selectFilteredOrganizations, selectIsAppUserLoading, selectIsSystemAdmin} from "@Store/AppUser/selectors";
import {updateSelectedOrganizationId} from "@Store/Organizations/actions";
import {selectCurrentOrganization, selectIsSimpleUserOfCurrentOrganization,} from "@Store/Organizations/selectors";
import {IOrganization, OrganizationId} from "@Store/Organizations/types";
import {useAppDispatch, useAppSelector} from "@hooks";
import {Divider} from "antd";
import {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";

function OrganizationSelection() {
  const dispatch = useAppDispatch();
  const history: ReturnType<typeof useHistory> = useHistory();
  const location: ReturnType<typeof useLocation> = useLocation();
  const params: {orgId: string | undefined} = useParams();
  const [imageUpdateKey, setImageUpdateKey] = useState(0); // Add this line

  const {
    selectOrganizationsGeneralData,
    isCurrentUserSimpleUser,
    isSystemAdmin,
    organizationList,
    currentOrganization
  } = useAppSelector((state: RootState) => ({
    selectOrganizationsGeneralData: selectIsAppUserLoading(state),
    isCurrentUserSimpleUser: selectIsSimpleUserOfCurrentOrganization(state),
    isSystemAdmin: selectIsSystemAdmin(state),
    organizationList: selectFilteredOrganizations(state),
    currentOrganization: selectCurrentOrganization(state),
  }));

  useEffect(() => {
    if (organizationList.length > 0 ) {
      dispatch(updateSelectedOrganizationId(organizationList[0].orgId));
    }
  }, [dispatch, isCurrentUserSimpleUser, organizationList]);

  const handleOrganizationClick = (organizationId: OrganizationId): void => {
    history.push(`/organization/${organizationId}/overview`);
  };

  const isOrganizationSelected = (orgId: OrganizationId): boolean => {
    return params.orgId === orgId;
  };

  useEffect(() => {
    setImageUpdateKey(prevKey => prevKey + 1);
  }, [organizationList]);

  //
  // const isOrganizationsPageSelected = (): boolean =>
  //   location.pathname !== "/organizations";

  return (
    <>
      <div className="organization-navigation">
        {isSystemAdmin && <OverviewOrganizationsButton />}
        <div className="organization-list">
          {organizationList.map(
            ({ orgId, orgLogoUrl, orgName }: IOrganization) => (
              <div key={orgId} className="organization-button">
                <div
                  className={
                    isOrganizationSelected(orgId)
                      ? "inner active"
                      : "inner"
                  }
                >
                  <div
                    className={
                      isOrganizationSelected(orgId)
                        ? ""
                        : "notActive"
                    }
                    onClick={() => handleOrganizationClick(orgId)}
                  >
                    <AvatarAsync
                        size={45}
                        url={orgLogoUrl}
                        shape="square"
                        placeholderText={orgName}
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <ReleaseNotes />
      </div>
      <Divider type="vertical" className="organization-navigation-divider" />
    </>
  );
}

export default OrganizationSelection;
