import {
  IDeleteAxiosOrganizationTeam,
  IGetAxiosOrganizationTeam,
  IGetAxiosOrganizationTeams,
  IPostAxiosOrganizationTeam,
  IPostAxiosOrganizationTeamMember,
  IPutAxiosOrganizationTeam,
  deleteAxiosOrganizationTeam,
  getAxiosOrganizationTeam,
  getAxiosOrganizationTeams,
  postAxiosOrganizationTeam,
  postAxiosOrganizationTeamMember,
  putAxiosOrganizationTeam,
} from "@API/Teams";
import { selectCurrentOrgId } from "@Store/Organizations/selectors";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  addTeamMembersFail,
  addTeamMembersRequest,
  addTeamMembersSuccess,
  createTeamFail,
  createTeamRequest,
  createTeamSuccess,
  deleteTeamFail,
  deleteTeamRequest,
  deleteTeamSuccess,
  resetTeamModal,
  setTeamModalType,
  teamFail,
  teamRequest,
  teamSuccess,
  teamsListFail,
  teamsListRequest,
  teamsListSuccess,
  updateTeamFail,
  updateTeamRequest,
  updateTeamSuccess,
} from "./actions";
import { selectSelectedTeamId, selectTeams } from "./selectors";
import { IOrganizationTeamListItem, IOrganizationTeamMember } from "./types";

function* onTeamsListRequest() {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IGetAxiosOrganizationTeams = yield call(
      getAxiosOrganizationTeams,
      orgId
    );
    if (response.error) throw response.error;

    yield put(teamsListSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(teamsListFail(e as Error));
  }
}

function* onTeamRequest({ payload: teamId }: PayloadAction<number>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IGetAxiosOrganizationTeam = yield call(
      getAxiosOrganizationTeam,
      orgId,
      teamId
    );
    if (response.error) throw response.error;

    // TODO: delete next 2 lines when api will work
    const teams: any[] = yield select(selectTeams);
    const team = teams.find((team: any) => team.id === teamId);
    yield put(teamSuccess({ ...response.data, teamMembers: [], ...team }));
  } catch (e) {
    console.error(e);
    yield put(teamFail(e as Error));
  }
}

function* onCreateTeamRequest({
  payload,
}: PayloadAction<IOrganizationTeamListItem>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IPostAxiosOrganizationTeam = yield call(
      postAxiosOrganizationTeam,
      orgId,
      payload
    );
    if (response.error) throw response.error;

    yield put(resetTeamModal());
    yield put(createTeamSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(createTeamFail(e as Error));
  }
}

function* onUpdateTeamRequest({
  payload,
}: PayloadAction<IOrganizationTeamListItem>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IPutAxiosOrganizationTeam = yield call(
      putAxiosOrganizationTeam,
      orgId,
      payload
    );
    if (response.error) throw response.error;

    yield put(resetTeamModal());
    yield put(updateTeamSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(updateTeamFail(e as Error));
  }
}

function* onDeleteTeamRequest() {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const teamId: number = yield select(selectSelectedTeamId);
    const response: IDeleteAxiosOrganizationTeam = yield call(
      deleteAxiosOrganizationTeam,
      orgId,
      teamId
    );
    if (response.error) throw response.error;

    yield put(resetTeamModal());
    yield put(deleteTeamSuccess(teamId));
  } catch (e) {
    console.error(e);
    yield put(deleteTeamFail(e as Error));
  }
}

function* onAddTeamMemberRequest({
  payload,
}: PayloadAction<IOrganizationTeamMember[]>) {
  try {
    const orgId: number = yield select(selectCurrentOrgId);
    const response: IPostAxiosOrganizationTeamMember = yield call(
      postAxiosOrganizationTeamMember,
      orgId,
      payload
    );
    if (response.error) throw response.error;

    yield put(addTeamMembersSuccess(response.data));
  } catch (e) {
    console.error(e);
    yield put(addTeamMembersFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(teamsListRequest, onTeamsListRequest);
  yield takeLatest(teamRequest, onTeamRequest);
  yield takeLatest(createTeamRequest, onCreateTeamRequest);
  yield takeLatest(updateTeamRequest, onUpdateTeamRequest);
  yield takeLatest(deleteTeamRequest, onDeleteTeamRequest);
  yield takeLatest(addTeamMembersRequest, onAddTeamMemberRequest);
  yield takeLatest(setTeamModalType, () => {});
  yield takeLatest(resetTeamModal, () => {});
}
