import { RootState } from "@Store";
import { IOrganizationUser } from "@Store/Organizations/types";
import { IUserModal } from "@Store/Users/types";

export const selectOrganizationUsers = (
  state: RootState
): IOrganizationUser[] => state.users.general.list;

export const selectUsersIsLoading = (state: RootState): boolean =>
  state.users.general.isLoading;

export const selectShowDeleteUserModal = (state: RootState): boolean =>
  !!state.users.general.userModal.user &&
  state.users.general.userModal.type === "delete";

export const selectShowEditUserModal = (state: RootState): boolean =>
  !!state.users.general.userModal.user &&
  (state.users.general.userModal.type === "update" ||
    state.users.general.userModal.type === "create");

export const selectUserModal = (state: RootState): IUserModal =>
  state.users.general.userModal;
