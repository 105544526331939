import "./index.scss";

import InvitationTabContent from "@Pages/UsersPage/Tabs/InvitationTabContent";
import UsersTabContent from "@Pages/UsersPage/Tabs/UserTabContent";
import { inviteUsersListRequest } from "@Store/Invites/actions";
import {
  selectCurrentOrgId,
  selectCurrentOrganization,
} from "@Store/Organizations/selectors";
import { IOrganization, OrganizationId } from "@Store/Organizations/types";
import { useAppDispatch, useAppSelector } from "@hooks";
import MainComponent from "@src/MainComponent";
import { Tabs } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ModalInviteUser from "./ModalInviteUser";
import ModalOrganizationUser from "./ModalOrganizationUser";
import {getAxiosOrganizationUsers} from "@API/Users/endpoints";
import {usersListRequest} from "@Store/Users/actions";
import {useParams} from "react-router-dom";

const { TabPane } = Tabs;

const UsersPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params: {orgId: string} = useParams();
  const orgId= params.orgId;
  const currentOrganization: IOrganization = useAppSelector(
    selectCurrentOrganization
  );

  useEffect(() => {

    if(orgId) {
      dispatch(usersListRequest(orgId));
      dispatch(inviteUsersListRequest(orgId));
    }
  }, [orgId]);

  return (
    <MainComponent>
      <div className="app__organizationUserPage">
        <Tabs type="card">
          <TabPane key="users" tab={t("inviteUser.users")}>
            <UsersTabContent dispatch={dispatch} />
          </TabPane>
          <TabPane key="invitations" tab={t("inviteUser.invitations")}>
            <InvitationTabContent dispatch={dispatch} />
          </TabPane>
        </Tabs>
      </div>
      <ModalInviteUser />
      {/*<ModalDeleteUser />*/}
      {/*<ModalDeleteInviteUser />*/}
      {/*<ModalSendNotificationInviteUser />*/}
      <ModalOrganizationUser />
    </MainComponent>
  );
};

export default UsersPage;
