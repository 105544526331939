import { combineReducers, createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  teamsListRequest, teamsListSuccess, teamsListFail,
  teamRequest, teamSuccess, teamFail,
  createTeamRequest, createTeamSuccess, createTeamFail,
  updateTeamRequest, updateTeamSuccess, updateTeamFail,
  deleteTeamRequest, deleteTeamSuccess, deleteTeamFail,
  addTeamMembersRequest, addTeamMembersSuccess, addTeamMembersFail,
  setTeamModalType, resetTeamModal, setEmptyTeam,
} from './actions';
import { IOrganizationTeam, IOrganizationTeamListItem, IOrganizationTeamMember, ITeamModal, ITeamModalType } from './types';



export interface ITeamsInitialState {
  list: IOrganizationTeamListItem[];
  teamModal: ITeamModal,
  isLoading: boolean;
  success: boolean;
  fail: boolean;
}

const TEAMS_INITIAL_STATE: ITeamsInitialState = {
  list: [],
  teamModal: {
    team: undefined,
    type: undefined,
    isLoading: false,
    success: false,
    fail: false,
  },
  isLoading: false,
  success: false,
  fail: false,
};

const teamsReducer = createReducer(TEAMS_INITIAL_STATE, {
  [teamsListRequest.type]: state => ({
    ...state,
    isLoading: true,
    success: false,
    fail: false,
  }),
  [teamsListSuccess.type]: (state, { payload }: PayloadAction<IOrganizationTeamListItem[]>) => ({
    ...state,
    list: payload,
    isLoading: false,
    success: true,
    fail: false,
  }),
  [teamsListFail.type]: state => ({
    ...state,
    isLoading: false,
    success: false,
    fail: true,
  }),


  [teamRequest.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [teamSuccess.type]: (state, { payload }: PayloadAction<IOrganizationTeam>) => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      team: payload,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [teamFail.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),
  [setEmptyTeam.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      team: {
        active: false,
        isPrivate: false,
        administrators: [],
        description: '',
        avatar: undefined,
        id: -1,
        name: '',
        teamMemberCount: 0,
        tags: undefined,
        teamMembers: [],
      },
      isLoading: false,
      success: true,
      fail: false,
    },
  }),


  [createTeamRequest.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [createTeamSuccess.type]: (state, { payload }: PayloadAction<IOrganizationTeamListItem>) => ({
    ...state,
    list: [payload, ...state.list],
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [createTeamFail.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [updateTeamRequest.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [updateTeamSuccess.type]: (state, { payload }: PayloadAction<IOrganizationTeamListItem>) => {
    const index = state.list.findIndex(item => item.id === payload.id);
    const newList = [...state.list];
    if(index !==-1) newList.splice(index, 1, payload);

    return {
      ...state,
      list: newList,
      teamModal: {
        ...state.teamModal,
        isLoading: false,
        success: true,
        fail: false,
    },
  }},
  [updateTeamFail.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [deleteTeamRequest.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [deleteTeamSuccess.type]: (state, { payload: teamId }: PayloadAction<number>) => ({
    ...state,
    list: state.list.filter(item => item.id !== teamId),
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [deleteTeamFail.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),


  [addTeamMembersRequest.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: true,
      success: false,
      fail: false,
    },
  }),
  [addTeamMembersSuccess.type]: (state, { payload }: PayloadAction<IOrganizationTeamMember[]>) => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      team: state.teamModal.team
        ? {
          ...state.teamModal.team,
          teamMembers: payload,
        }
        : undefined,
      isLoading: false,
      success: true,
      fail: false,
    },
  }),
  [addTeamMembersFail.type]: state => ({
    ...state,
    teamModal: {
      ...state.teamModal,
      isLoading: false,
      success: false,
      fail: true,
    },
  }),

  [setTeamModalType.type]: (state, { payload }: PayloadAction<ITeamModalType>) => {
    return {
      ...state,
      teamModal: {
        ...state.teamModal,
        type: payload,
      },
    }
  },
  [resetTeamModal.type]: state => ({
    ...state,
    teamModal: {
      team: undefined,
      type: undefined,
      isLoading: false,
      success: false,
      fail: false,
    },
  }),
});

export interface IGeneralState {
  readonly general: ITeamsInitialState;
}

export default combineReducers<IGeneralState>({
  general: teamsReducer,
})
