import { BpContactPerson } from "@Types/bpContactPersons";
import { http } from "@src/app/Http";

const serviceUrl = "bpcontactpersons";

export const GetImageUrl_BpContactPersons = (id: number | undefined) => {
  return `${process.env.REACT_APP_API_BASE_URL}/${serviceUrl}/${id}/avatar`;
};

export const GetAvatar = async (url: string) => {
  try {
    const request = await http.get<string>(url);
    return request.data;
  } catch (error: any) {
    return undefined;
  }
};

export const $getAllBpContactPersons = async () => {
  try {
    const request = await http.get<BpContactPerson[]>(`${serviceUrl}`);

    return {
      error: undefined,
      value: request.data,
    };
  } catch (error: any) {
    if (error.response && error.response.data) {
      return {
        error: error.response.data,
        value: undefined,
      };
    } else {
      return {
        error: {
          message:
            "Unhandled exception occured while loading bp contact persons!",
          id: 999,
        },
        value: undefined,
      };
    }
  }
};

export const $editBpContactPerson = async (data: BpContactPerson) => {
  try {
    const request = await http.put<BpContactPerson>(
      `${serviceUrl}/${data.id}`,
      data
    );

    return {
      error: null,
      value: request.data,
    };
  } catch (error: any) {
    if (error.response && error.response.data) {
      return {
        error: error.response.data,
        value: undefined,
      };
    } else {
      return {
        error: {
          message: "Unhandled exception occured while editing contact person!",
          id: 999,
        },
        value: undefined,
      };
    }
  }
};

export const $addBpContactPerson = async (data: BpContactPerson) => {
  try {
    const request = await http.post<BpContactPerson>(`${serviceUrl}`, data);

    return {
      error: null,
      value: request.data,
    };
  } catch (error: any) {
    if (error.response && error.response.data) {
      return {
        error: error.response.data,
        value: undefined,
      };
    } else {
      return {
        error: {
          message: "Unhandled exception occured while editing contact person!",
          id: 999,
        },
        value: undefined,
      };
    }
  }
};

export const $deleteBpContactPerson = async (data: BpContactPerson) => {
  try {
    const request = await http.delete<BpContactPerson>(
      `${serviceUrl}/${data.id}`
    );

    return {
      error: null,
      id: data.id,
      value: request.data,
    };
  } catch (error: any) {
    if (error.response && error.response.data) {
      return {
        error: error.response.data,
        id: data.id,
        value: undefined,
      };
    } else {
      return {
        error: {
          message: "Unhandled exception occured while deleting contact person!",
          id: 999,
        },
        id: data.id,
        value: undefined,
      };
    }
  }
};
