import "moment/locale/de";

import ProfileCard from "@Components/ProfileCard";
import HelpMenu from "@Components/HelpMenu";
import { RootState } from "@Store";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { UserOutlined } from "@ant-design/icons";
import { useAppSelector } from "@hooks";
import { Avatar, Image, PageHeader, Popover } from "antd";
import React from "react";
import { AppName, OrgName, StyledHeader } from "./styles";

interface IHeader {
  showCurrentOrganizationName?: boolean;
  onLogoClick?: () => void;
}

const Header: React.FC<IHeader> = ({
  showCurrentOrganizationName = true,
  onLogoClick = () => {},
}) => {
  const { appUser, currentOrganization } = useAppSelector(
    (state: RootState) => ({
      appUser: selectAppUserData(state),
      currentOrganization: selectCurrentOrganization(state),
    })
  );

  return (
    <StyledHeader>
      <PageHeader
        title={
          <Image
            preview={false}
            width={150}
            src={`${process.env.PUBLIC_URL}/Assets/blackpin_logo_small.svg`}
            onClick={onLogoClick}
          />
        }
        subTitle=""
        className="app__header"
        extra={
          <div className="container">
            {showCurrentOrganizationName && appUser.userId ? (
              <OrgName>{currentOrganization?.orgName}</OrgName>
            ) : (
              <AppName>ADMIN CENTER</AppName>
            )}
            <div className="menuFlex">
              <div className="helpButton">
                <HelpMenu />
              </div>
              {appUser?.userId && (
                <Popover
                  content={<ProfileCard />}
                  trigger="click"
                  placement="bottomRight"
                >
                  <Avatar
                    size="small"
                    icon={<UserOutlined />}
                    style={{ cursor: "pointer" }}
                  />
                </Popover>
              )}
            </div>
          </div>
        }
      />
    </StyledHeader>
  );
};

export default Header;
