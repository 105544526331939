import styled, { css } from 'styled-components';

const checkboxStyles = css`
  display: flex;
  flex-direction: row-reverse;
  width: auto;
  align-items: center;
`;

const socialMediasStyles = css`
  border: 1px solid #434343;
  border-radius: 5px;
  label {
    margin-bottom: 20px;
    border-bottom: 1px solid #434343;
    padding: 15px 20px;
  }
  label button {
    position: absolute;
    right: 15px;
    top: 10px;
  }
`;

interface IStyledFormField {
  type: string;
}

export const StyledFormField = styled.div<IStyledFormField>`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  ${({ type }) => (type === 'checkbox' ? checkboxStyles : '')}
  ${({ type }) => (type === 'social-medias' ? socialMediasStyles : '')}
`;

export const FormError = styled.span`
  position: absolute;
  color: #a61d24;
  font-size: 12px;
  display: block;
`;

export const StyledInputLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 4px;
  display: block;
  text-align: left;
  i {
    color: #a61d24;
  }
`;
