import { DatePicker } from "antd";
import { FieldInputProps, FormikHelpers } from "formik/dist/types";
import moment, { Moment } from "moment";
import { useCallback } from "react";

type DatePickerFormProps = {
  setFieldValue: FormikHelpers<{}>["setFieldValue"];
  field: FieldInputProps<string>;
} & { [x: string]: any };

function range(start: number, end: number): number[] {
  return Array.from(
    { length: end - start },
    (_, index: number) => index + start
  );
}

function disabledDate(current: Moment): boolean {
  const today: Moment = moment().startOf("day");

  return current && current < today;
}

function disabledDateTime(current: Moment | null) {
  if (!current) return {};

  const now: Moment = moment();

  if (!current.isSame(now, "day")) return {};

  return {
    disabledHours: () => range(0, now.hour()),
    disabledMinutes: () => range(0, now.minute()),
    disabledSeconds: () => range(0, now.second() + 1),
  };
}

function DatePickerForm(props: DatePickerFormProps) {
  const { setFieldValue, field, ...rest }: DatePickerFormProps = props;

  const handleOnChange = useCallback(
    (_value: Moment | null, dateString: string) => {
      setFieldValue(field.name, dateString);
    },
    [setFieldValue, field.name]
  );

  return (
    <DatePicker
      format="YYYY-MM-DD HH:mm:ss"
      disabledDate={disabledDate}
      disabledTime={disabledDateTime}
      showNow={false}
      showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
      onChange={handleOnChange}
      {...rest}
    />
  );
}

export default DatePickerForm;
