import { PlusOutlined } from '@ant-design/icons';
import PhoneNumberItem from '@Components/TelephoneNumbersForm/TelephoneNumberItem';
import { IPhoneNumber } from '@Store/MasterData/types';
import { Button, List } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

interface ITelephoneNumbersForm {
  phoneNumbers: IPhoneNumber[];
  setPhoneNumbers: (arr: IPhoneNumber[]) => void;
  loading?: boolean;
  isRequired?: boolean;
}

const TelephoneNumbersForm: React.FC<ITelephoneNumbersForm> = ({
  phoneNumbers,
  setPhoneNumbers,
  loading = false,
  isRequired = false,
}) => {
  const [newPhoneNumberMode, setNewPhoneNumberMode] = useState<boolean>(false);
  const { t } = useTranslation();

  const addNewPhoneNumber = () => {
    const newTmpPhoneNumbers: IPhoneNumber[] = _.cloneDeep(phoneNumbers);

    newTmpPhoneNumbers.unshift({
      id: 0,
      phoneNumberType: undefined,
      number: '',
      country: undefined,
    });

    setPhoneNumbers(newTmpPhoneNumbers);
    setNewPhoneNumberMode(true);
  };

  const onCancelPhoneNumber = (id: number) => {
    if (id === 0) {
      const newTmpPhoneNumbers: IPhoneNumber[] = _.cloneDeep(phoneNumbers);
      newTmpPhoneNumbers.shift();
      setPhoneNumbers(newTmpPhoneNumbers);
      setNewPhoneNumberMode(false);
    }
  };

  const onDeletePhoneNumber = (id: number) => {
    const newTmpPhoneNumbers: IPhoneNumber[] = _.cloneDeep(phoneNumbers);
    const idx = newTmpPhoneNumbers.findIndex((a) => a.id === id);
    newTmpPhoneNumbers.splice(idx, 1);
    setPhoneNumbers(newTmpPhoneNumbers);
  };

  const addPhoneNumber = (values: IPhoneNumber) => {
    const idx = phoneNumbers.findIndex((a) => a.id === values.id);

    const newArray: IPhoneNumber[] = _.cloneDeep(phoneNumbers);

    if (values.id === 0) {
      values.id = uuidv4();
    }

    if (idx === -1) {
      newArray.push(values);
    } else {
      newArray[idx] = values;
    }

    setPhoneNumbers(newArray);
    setNewPhoneNumberMode(false);
  };

  return (
    <List
      bordered={true}
      size='small'
      dataSource={phoneNumbers}
      header={
        <div className='listHeader'>
          <div>
            <i>{isRequired && '* '}</i>
            {t('bpContactPersons.modals.phoneNumbers')}
          </div>
          <div>
            <Button
              disabled={loading || newPhoneNumberMode}
              ghost
              icon={<PlusOutlined />}
              onClick={() => addNewPhoneNumber()}
            />
          </div>
        </div>
      }
      renderItem={(item) =>
        item && [
          <List.Item>
            <List.Item.Meta
              title={
                item.id === 0
                  ? t('modals.phoneNumber.newPhoneNumber')
                  : t(`phoneNumberTypes.${item.phoneNumberType?.name}`)
              }
              description={
                <PhoneNumberItem
                  onCancle={(id: number) => onCancelPhoneNumber(id)}
                  onDelete={(id: number) => onDeletePhoneNumber(id)}
                  onSave={(data: IPhoneNumber) => addPhoneNumber(data)}
                  phoneNumber={item}
                  loading={loading}
                />
              }
            />
          </List.Item>,
        ]
      }
    />
  );
};

export default TelephoneNumbersForm;
