import { Button, Modal, ModalProps } from "antd";
import { MouseEventHandler, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

function ReleaseNotes() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [changelog, setChangelog] = useState<string>("");

  useEffect(() => {
    let isMounted: boolean = true;

    const loadChangeLogFile = async (): Promise<void> => {
      const changeLogFile = await import("@src/CHANGELOG.md");
      const response: Response = await fetch(changeLogFile.default);

      if (!isMounted || !response.ok) return;

      try {
        const changeLogText: string = await response.text();

        setChangelog(changeLogText);
      } catch (error) {
        console.error(error);
      }
    };

    loadChangeLogFile().catch((reason) => console.error(reason));

    return () => {
      isMounted = false;
    };
  }, []);

  const showModal: MouseEventHandler<HTMLElement> = () => {
    setIsModalOpen(true);
  };

  const handleOk: ModalProps["onOk"] = () => {
    setIsModalOpen(false);
  };

  const handleCancel: ModalProps["onCancel"] = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="link" onClick={showModal}>
        v{process.env.REACT_APP_VERSION}
      </Button>
      <Modal
        title="Release Notes"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="35vw"
        bodyStyle={{ height: "60vh", overflow: "auto" }}
        footer={
          <Button type="primary" onClick={handleOk}>
            OK
          </Button>
        }
      >
        <ReactMarkdown>{changelog}</ReactMarkdown>
      </Modal>
    </>
  );
}

export default ReleaseNotes;
