import BlackPinTag from "@Components/BlackPinTag";
import { sortFunction } from "@Components/Table";
import { calculateCountdownTimer } from "@Pages/UsersPage/utilities";
import { IUserInvite } from "@Store/Invites/types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import i18n from "@i18n";
import { Popover } from "antd";
import { TableColumn } from "react-data-table-component";

export const INVITE_USERS_TABLE_COLUMNS = (
  setBooleanFieldValue: any,
  invitedUsers: IUserInvite[]
): TableColumn<any>[] => {
  return [
    // {
    //   width: "35px",
    //   cell: (row: IUserInvite) => <CheckboxButton row={row} />,
    // },
    {
      name: i18n.t("inviteUser.columns.invitedOrgUserGivenName"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.invitedOrgUserGivenName.toLowerCase()
      ),
      selector: (row: IUserInvite) => row.invitedOrgUserGivenName,
    },
    {
      name: i18n.t("inviteUser.columns.invitedOrgUserSurname"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.invitedOrgUserSurname.toLowerCase()
      ),
      selector: (row: IUserInvite) => row.invitedOrgUserSurname,
    },
    {
      name: i18n.t("inviteUser.columns.invitedOrgUserUsername"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.invitedOrgUserUsername.toLowerCase()
      ),
      selector: (row: IUserInvite) => row.invitedOrgUserUsername,
    },
    {
      name: i18n.t("inviteUser.columns.creationTs"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.creationTs.getTime()
      ),
      selector: (row: IUserInvite) => row.creationTs.toLocaleString(),
    },
    {
      name: i18n.t("inviteUser.columns.invitedByUsername"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.invitedByUsername.toLowerCase()
      ),
      selector: (row: IUserInvite) => row.invitedByUsername,
    },
    {
      name: i18n.t("inviteUser.columns.revoked"),
      sortable: true,
      selector: (row: IUserInvite) => row.revoked,
      sortFunction: sortFunction((row: IUserInvite) => +row.revoked),
      cell: (row: IUserInvite) => {
        if (!row.revoked) {
          return (
            <BlackPinTag color="Green" text={i18n.t("inviteUser.notRevoked")} />
          );
        }

        const content: JSX.Element = (
          <div>
            <p>{row.revocationTs?.toLocaleString() ?? "none"}</p>
          </div>
        );

        return (
          <Popover content={content} title={i18n.t("inviteUser.revocationTs")}>
            <BlackPinTag color="Red" text={i18n.t("inviteUser.revoked")} />
          </Popover>
        );
      },
    },
    {
      name: i18n.t("inviteUser.columns.status"),
      sortable: true,
      selector: (row: IUserInvite) =>
        row.acceptationTs?.toLocaleString() ?? "none",
      cell: (row: IUserInvite) => {
        // Case where user has accepted the invite

        if (row.acceptationTs !== null) {
          return (
            <BlackPinTag
              color="Green"
              text={
                <>
                  {i18n.t("inviteUser.accepted")}{" "}
                  <Popover
                    content={
                      <div>
                        <p>{row.acceptationTs.toLocaleString()}</p>
                      </div>
                    }
                    title={i18n.t("inviteUser.acceptationTs")}
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </>
              }
            />
          );
        }

        // Case where user has not accepted the invite

        const currentTime: number = new Date().getTime();
        const timeRemaining: number = +row.validUntil - currentTime;

        // Case where invite has expired

        if (timeRemaining <= 0) {
          return <BlackPinTag color="Red" text="Expired" />;
        }

        // Case where invite is still valid, but user has not accepted it yet

        return (
          <BlackPinTag
            color="Orange"
            text={
              <>
                {i18n.t("inviteUser.waitingForAcceptance")}{" "}
                <Popover
                  content={
                    <div>
                      <p>{calculateCountdownTimer(timeRemaining)}</p>
                    </div>
                  }
                  title={i18n.t("inviteUser.expiresIn")}
                >
                  <QuestionCircleOutlined />
                </Popover>
              </>
            }
          />
        );
      },
    },
    {
      name: i18n.t("inviteUser.columns.getOtpValidUntil"),
      sortable: true,
      sortFunction: sortFunction((row: IUserInvite) =>
        row.getOtpValidUntil?.getTime()
      ),
      selector: (row: IUserInvite) =>
        row.getOtpValidUntil?.toLocaleString() ?? "none",
    },
    // {
    //   name: i18n.t("inviteUser.expiresIn"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => row.validUntil.toLocaleString(),
    //   cell: (row: IUserInvite) => {
    //     const currentTime: number = new Date().getTime();
    //     const timeRemaining: number = +row.validUntil - currentTime;
    //
    //     if (timeRemaining <= 0) {
    //       return <BlackPinTag color="Red" text="Expired" />;
    //     }
    //
    //     return calculateCountdownTimer(timeRemaining);
    //   },
    // },
    // {
    //   name: i18n.t("inviteUser.columns.firstname"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "firstname",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.lastname"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "lastname",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.nickname"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "nickname",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.email"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "email",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.department"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "department",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.departmentJob"),
    //   sortable: true,
    //   selector: (row: IUserInvite) => "departmentJob",
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.notified"),
    //   selector: (row: IUserInvite) => "notifyUser",
    //   sortable: true,
    //   cell: (row) => (
    //     <BlackPinTag
    //       color={row.notifyUser ? "Green" : "Red"}
    //       text={
    //         row.notifyUser
    //           ? i18n.t("inviteUser.notifiedUser")
    //           : i18n.t("inviteUser.unnotifiedUser")
    //       }
    //     />
    //   ),
    // },
    //
    // {
    //   name: checkboxForAll({
    //     invitedUsers,
    //     field: "used",
    //     cb: setBooleanFieldValue,
    //     hideStyles: true,
    //   }),
    //   width: "65px",
    //   cell: (row) =>
    //     checkbox({
    //       row,
    //       field: "used",
    //       cb: setBooleanFieldValue,
    //       hideStyles: true,
    //     }),
    // },
    //
    // {
    //   name: i18n.t("inviteUser.columns.used"),
    //   selector: (row: IUserInvite) => "used",
    //   sortable: true,
    //   cell: (row) => (
    //     <BlackPinTag
    //       color={row.used ? "Green" : "Red"}
    //       text={
    //         row.used ? i18n.t("inviteUser.used") : i18n.t("inviteUser.unused")
    //       }
    //     />
    //   ),
    // },
  ];
};
