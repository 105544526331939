import "react-pro-sidebar/dist/css/styles.css";

import {DataIcon, SetupIcon} from "@Components/Icons";
import NavSection from "@Components/NavSection";
import {selectCurrentOrganization, selectOrganizationsGeneralData,} from "@Store/Organizations/selectors";
import {useAppDispatch, useAppSelector} from "@hooks";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {NavContainer, Section} from "./styles";
import {useEffect} from "react";
import {getOrganizationByIdRequest} from "@Store/Organizations/actions";
import {useSelector} from "react-redux";

function OverviewNavigation() {
  const { t } = useTranslation();
  const history = useHistory();
  const params: {orgId: string} = useParams();
  const dispatch = useAppDispatch();


  const { isLoading } = useAppSelector(selectOrganizationsGeneralData);
  const currentOrganization = useAppSelector(selectCurrentOrganization);


  if (!currentOrganization?.orgId && !isLoading) return null;


  return (
    <NavContainer>
      <Section>
        <NavSection
          color="mainYellow"
          header={
            <>
              <DataIcon />
              {t("sidebar.organization.data")}
            </>
          }
          contentItems={[
            // {
            //   text: t("sidebar.organization.info"),
            //   onClickHandler: () =>
            //     history.push(
            //       `/organization/${currentOrganization?.orgId}/data`
            //     ),
            // },
            {
              text: t("sidebar.organization.admins"),
              onClickHandler: () =>
                history.push(
                  `/organization/${currentOrganization?.orgId}/admins`
                ),
            },
          ]}
        />
        <NavSection
          color="mainRed"
          header={
            <>
              <SetupIcon />
              {t("sidebar.app.title")}
            </>
          }
          contentItems={[
            {
              text: t("sidebar.app.users"),
              onClickHandler: () =>
                history.push(
                  `/organization/${currentOrganization?.orgId}/users`
                ),
            },
            // {
            //   text: t("sidebar.app.teams"),
            //   onClickHandler: () =>
            //     history.push(
            //       `/organization/${currentOrganization?.orgId}/teams`
            //     ),
            // },
          ]}
        />
      </Section>
    </NavContainer>
  );
}

export default OverviewNavigation;
