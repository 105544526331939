import "./index.scss";

import React, { useEffect, useState } from "react";
import MainComponent from "@src/MainComponent";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { RootState } from "@Store";
import { selectIsSystemAdmin, selectFilteredOrganizations } from "@Store/AppUser/selectors";

function DashboardPage() {
    const { isSystemAdmin, organizationList } = useAppSelector((state: RootState) => ({
        isSystemAdmin: selectIsSystemAdmin(state),
        organizationList: selectFilteredOrganizations(state),
    }));

    const [redirectTo, setRedirectTo] = useState("");

    useEffect(() => {
        if (isSystemAdmin) {
            setRedirectTo("/organizations");
        } else if (organizationList && organizationList.length > 0) {
            setRedirectTo(`/organization/${organizationList[0].orgId}/overview`);
        }
    }, [isSystemAdmin, organizationList]);

    if (redirectTo) {
        return <Redirect to={redirectTo} />;
    }

    return (
        <MainComponent>
            <div className="app__dashboard">
                <h2>Dashboard</h2>
            </div>
        </MainComponent>
    );
}

export default DashboardPage;
