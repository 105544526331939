import "./index.scss";

import { selectMasterData } from "@Store/MasterData/selectors";
import {
  ICountry,
  IPhoneNumber,
  IPhoneNumberType,
} from "@Store/MasterData/types";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "@hooks";
import { Button, Form, Input, Select, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { De, Fr, Gb, It, Nl, Pl } from "react-flags-select";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { Text } = Typography;

export const getPhoneNumberText = (phoneNumber: IPhoneNumber) =>
  `${phoneNumber.country?.phoneAreaCode} ${phoneNumber.number}`;
export type PhoneNumberItemProps = {
  phoneNumber: IPhoneNumber;
  loading: boolean;
  onSave: any;
  onCancle: any;
  onDelete: any;
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

type PhoneNumberForm = IPhoneNumber & {};

const PhoneNumberItem: FunctionComponent<PhoneNumberItemProps> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<PhoneNumberForm>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { countries, phoneNumberTypes } = useAppSelector(selectMasterData);

  useEffect(() => {
    form.setFieldsValue(props.phoneNumber);
    if (props.phoneNumber.id === 0) {
      setEditMode(true);
    }
  }, [props.phoneNumber]);

  const onOk = (phoneNumber: IPhoneNumber) => {
    const typeIdx = phoneNumberTypes.findIndex(
      (c: any) => c.id === phoneNumber.phoneNumberType?.id
    );
    const countryIdx = countries.findIndex(
      (c: any) => c.id === phoneNumber.country?.id
    );
    phoneNumber.id = props.phoneNumber.id;
    phoneNumber.phoneNumberType = phoneNumberTypes[typeIdx];
    phoneNumber.country = countries[countryIdx];
    props.onSave(phoneNumber);
    setEditMode(false);
  };
  const onCancle = () => {
    setEditMode(false);
    props.onCancle(props.phoneNumber.id);
  };
  const onDelete = () => {
    setEditMode(false);
    props.onDelete(props.phoneNumber.id);
  };
  return (
    <div className={!editMode ? "horizontalFormItem" : "verticalFormItem"}>
      {!editMode ? (
        <div>
          <Text>{getPhoneNumberText(props.phoneNumber)}</Text>
        </div>
      ) : (
        <Form
          form={form}
          onFinish={onOk}
          scrollToFirstError
          layout="vertical"
          validateMessages={validateMessages}
        >
          <Form.Item
            label={t("modals.phoneNumber.phoneNumberType")}
            name={["phoneNumberType", "id"]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              id={`phoneNumberType_${props.phoneNumber.id}`}
              placeholder={t(
                "modals.phoneNumber.placeholders.selectPhoneNumberType"
              )}
              style={{ width: "100%" }}
            >
              {phoneNumberTypes.map((type: IPhoneNumberType) => (
                <Option key={type.id} value={type.id}>
                  {t(`phoneNumberTypes.${type.name}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="" style={{ marginBottom: 0 }}>
            <Form.Item
              label={t("modals.phoneNumber.phoneAreaCode")}
              style={{ display: "inline-block", width: "calc(22% - 8px)" }}
              name={["country", "id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                id={`country_${props.phoneNumber.id}`}
                placeholder={t(
                  "modals.phoneNumber.placeholders.selectPhoneAreaCode"
                )}
                style={{ width: "100%" }}
              >
                {countries.map((country: ICountry) => (
                  <Option key={country.id} value={country.id}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {country.isoCode === "DE" && (
                        <De key={`flag_${country.id}`} />
                      )}
                      {country.isoCode === "FR" && (
                        <Fr key={`flag_${country.id}`} />
                      )}
                      {country.isoCode === "NL" && (
                        <Nl key={`flag_${country.id}`} />
                      )}
                      {country.isoCode === "PL" && (
                        <Pl key={`flag_${country.id}`} />
                      )}
                      {country.isoCode === "IT" && (
                        <It key={`flag_${country.id}`} />
                      )}
                      {country.isoCode === "GB" && (
                        <Gb key={`flag_${country.id}`} />
                      )}
                      <Text>{country.phoneAreaCode}</Text>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("modals.phoneNumber.number")}
              style={{
                display: "inline-block",
                width: "calc(78% - 8px)",
                margin: "0 8px",
              }}
              name="number"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                id={`number_${props.phoneNumber.id}`}
                placeholder={t("modals.phoneNumber.number")}
                disabled={props.loading}
              />
            </Form.Item>
          </Form.Item>
        </Form>
      )}
      <div className="horizontalButtonItem">
        {editMode && (
          <div style={{ paddingRight: 15 }}>
            <Button icon={<CloseOutlined />} onClick={() => onCancle()} />
          </div>
        )}
        {editMode && props.phoneNumber.id !== 0 && (
          <div style={{ paddingRight: 15 }}>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDelete()}
            />
          </div>
        )}
        {editMode && (
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => form.submit()}
          />
        )}
        {!editMode && (
          <Button
            ghost
            icon={<EditOutlined />}
            onClick={() => setEditMode(true)}
          />
        )}
      </div>
    </div>
  );
};
export default PhoneNumberItem;
