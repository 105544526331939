import "./index.scss";

import { selectAppUserData } from "@Store/AppUser/selectors";
import { IAppUser } from "@Store/AppUser/types";
import {
  openAddEditBpContactPersons,
  performDeleteBpContactPerson,
} from "@Store/Reducers/BpContactPersonReducer";
import { BpContactPerson } from "@Types/bpContactPersons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import { getUserName } from "@src/Utilities";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export type BpContactPersonListActionsProps = {
  bpContactPerson: BpContactPerson;
};

const BpContactPersonListActions: FunctionComponent<
  BpContactPersonListActionsProps
> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { deleteState } = useAppSelector((state) => state.bpContactPerson);
  const userInformation: IAppUser | any = useAppSelector(selectAppUserData);

  const [popconfirmDeleteVisible, setPopconfirmDeleteVisible] =
    useState<boolean>(false);

  const [popconfirmDeleteLoading, setPopconfirmDeleteLoading] =
    useState<boolean>(false);

  const [contactPersonName, setContactPersonName] = useState<string>("");

  const deleteContact = () => {
    setPopconfirmDeleteLoading(true);
    dispatch(performDeleteBpContactPerson(props.bpContactPerson));
  };

  const edit = () => {
    dispatch(openAddEditBpContactPersons(props.bpContactPerson));
  };

  useEffect(() => {
    setContactPersonName(
      getUserName({
        givenName: props.bpContactPerson.firstname,
        surName: props.bpContactPerson.lastname,
        emailAddress: props.bpContactPerson.email,
      })
    );
  }, []);

  useEffect(() => {
    if (typeof deleteState.response === "undefined") {
      return;
    }

    //Throw error if my requested failed
    if (deleteState.response.contactId === props.bpContactPerson.id) {
      setPopconfirmDeleteLoading(false);
    }
  }, [deleteState]);

  return (
    <>
      <Space size="middle">
        <Popconfirm
          okText={t("buttons.delete")}
          cancelText={t("buttons.cancel")}
          title={t("messages.deleteGeneric", { text: contactPersonName })}
          visible={popconfirmDeleteVisible}
          onConfirm={deleteContact}
          okType="danger"
          okButtonProps={{ loading: popconfirmDeleteLoading }}
          onCancel={() => {
            setPopconfirmDeleteVisible(false);
            setPopconfirmDeleteLoading(false);
          }}
        >
          <Tooltip
            placement="top"
            title={t("bpContactPersons.tooltips.deleteContactPerson", {
              name: contactPersonName,
            })}
          >
            <Button
              hidden={!userInformation?.superAdmin}
              type="ghost"
              icon={<DeleteOutlined />}
              onClick={() => setPopconfirmDeleteVisible(true)}
              danger
            ></Button>
          </Tooltip>
        </Popconfirm>
        <Tooltip
          placement="top"
          title={t("bpContactPersons.tooltips.editContactPerson", {
            name: contactPersonName,
          })}
        >
          <Button
            type="ghost"
            hidden={!userInformation?.superAdmin}
            icon={<EditOutlined />}
            onClick={() => edit()}
          ></Button>
        </Tooltip>
      </Space>
    </>
  );
};

export default BpContactPersonListActions;
