import BlackPinTag from "@Components/BlackPinTag";
import { sortFunction } from "@Components/Table";
import AvatarAsync from "@Pages/AvatarAsync";
import { CellsContainer } from "@Pages/UsersPage/styles";
import { IOrganizationUser } from "@Store/Organizations/types";
import { isOrganizationUserStatusActive } from "@Store/Organizations/utilities";
import i18n from "@i18n";
import { Tag } from "antd";
import { TableColumn } from "react-data-table-component";

export const getAdminTableColumns = (): TableColumn<any>[] => [
  {
    name: i18n.t("admins.avatar"),
    selector: (_) => "avatar",
    cell: (row) => (
      <AvatarAsync shape="square" size="medium" url={row.avatar} individual />
    ),
  },
  {
    name: i18n.t("admins.givenName"),
    selector: (row: IOrganizationUser) => row.givenName,
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      row.givenName.toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.givenName}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.surname"),
    selector: (row: IOrganizationUser) => row.surName,
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      row.surName.toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.surName}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.displayName"),
    selector: (row: IOrganizationUser) => row.displayName,
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      row.displayName.toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.displayName}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.nickname"),
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      (row.nickName ?? "").toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.nickName}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.description"),
    selector: (row: IOrganizationUser) => row.description ?? "",
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      (row.description ?? "").toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.description}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.emailAddress"),
    selector: (row: IOrganizationUser) => row.emailAddress,
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      row.emailAddress.toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.emailAddress}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.department"),
    selector: (row: IOrganizationUser) => row.department ?? "",
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      (row.department ?? "").toLowerCase()
    ),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>{row.department}</CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.mobileNumber"),
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) => row.mobileNumber),
    cell: (row: IOrganizationUser) => (
      <CellsContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ paddingBottom: 15 }}>
            <Tag>{i18n.t(`phoneNumberTypes.mobile`)}</Tag>
            {row.mobileNumber}
          </div>
        </div>
      </CellsContainer>
    ),
  },
  {
    name: i18n.t("admins.status"),
    selector: (row: IOrganizationUser) => row.status,
    sortable: true,
    sortFunction: sortFunction((row: IOrganizationUser) =>
      Boolean(isOrganizationUserStatusActive(row.status))
    ),
    cell: (row: IOrganizationUser) => (
      <BlackPinTag
        color={isOrganizationUserStatusActive(row.status) ? "Green" : "Red"}
        text={
          isOrganizationUserStatusActive(row.status)
            ? i18n.t("inviteUser.columns.active")
            : i18n.t("inviteUser.columns.inactive")
        }
      />
    ),
  },
];
