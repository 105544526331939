import { NewOrganizationPayload } from "@API/Organizations/types";
import { createAction } from "@reduxjs/toolkit";
import {IOrganization, OrganizationId, OrganizationModalType, UploadImageOrganizationPayload} from "./types";
import {CompanyPrivacyPolicyFormValues} from "@Pages/CompanyLegalPage/consts";

export const organizationsListRequest = createAction<void>(
  "ORGANIZATIONS_LIST/REQUEST"
);
export const organizationsListSuccess = createAction<IOrganization[]>(
  "ORGANIZATIONS_LIST/SUCCESS"
);
export const organizationsListFail = createAction<Error>(
  "ORGANIZATIONS_LIST/FAIL"
);

export const updateOrganizationRequest = createAction<IOrganization>(
  "UPDATE_ORGANIZATION/REQUEST"
);
export const updateOrganizationSuccess = createAction<IOrganization>(
  "UPDATE_ORGANIZATION/SUCCESS"
);
export const updateOrganizationFail = createAction<Error>(
  "UPDATE_ORGANIZATION/FAIL"
);

export const getOrganizationByIdRequest = createAction<string>('GET_ORGANIZATION_BY_ID_REQUEST');

export const getOrganizationByIdSuccess = createAction<{
    data: IOrganization
}>(
    "GET_ORGANIZATION_BY_ID/SUCCESS"
);
export const getOrganizationByIdFail = createAction<Error>(
    "GET_ORGANIZATION_BY_ID/FAIL"
);

export const updateSelectedOrganizationId = createAction<OrganizationId>(
  "UPDATE_SELECTED_ORGANIZATION_ID"
);

// system user requests
export const createOrganizationRequest = createAction<NewOrganizationPayload>(
  "CREATE_ORGANIZATION/REQUEST"
);
export const createOrganizationSuccess = createAction<IOrganization>(
  "CREATE_ORGANIZATION/SUCCESS"
);
export const createOrganizationFail = createAction<Error>(
  "CREATE_ORGANIZATION/FAIL"
);

export const setModalCreateOrganization = createAction<void>(
  "SET_MODAL_CREATE_ORGANIZATION"
);
export const setModalCreateOrganizationType =
  createAction<OrganizationModalType>("SET_MODAL_CREATE_ORGANIZATION_TYPE");
export const resetCreateOrganizationModal = createAction<void>(
  "MODAL_CREATE_ORGANIZATION_RESET"
);

export const systemUpdateOrganizationRequest = createAction<IOrganization>(
  "SYSTEM_UPDATE_ORGANIZATION/REQUEST"
);
export const systemUpdateOrganizationSuccess = createAction<IOrganization>(
  "SYSTEM_UPDATE_ORGANIZATION/SUCCESS"
);
export const systemUpdateOrganizationFail = createAction<Error>(
  "SYSTEM_UPDATE_ORGANIZATION/FAIL"
);

export const deleteOrganizationRequest = createAction<OrganizationId>(
  "DELETE_ORGANIZATION/REQUEST"
);
export const deleteOrganizationSuccess = createAction<OrganizationId>(
  "DELETE_ORGANIZATION/SUCCESS"
);
export const deleteOrganizationFail = createAction<Error>(
  "DELETE_ORGANIZATION/FAIL"
);
export const getOrganizationPolicyRequest = createAction<{ orgId: string; type: string }>('GET_ORGANIZATION_POLICY_REQUEST');



export interface PolicyData {
    orgId: string;
    type: string;
    contentEN: string;
    contentDE: string;
    validUntil: string;
}

export const getOrganizationPolicySuccess = createAction<PolicyData>('GET_ORGANIZATION_POLICY_SUCCESS');
export const getOrganizationPolicyFail = createAction<{ orgId: string; type: string; error: Error | unknown }>('GET_ORGANIZATION_POLICY_FAIL');

export const uploadImageOrganizationRequest = createAction<UploadImageOrganizationPayload>('IMAGE_UPLOAD/REQUEST');
export const uploadImageOrganizationSuccess = createAction('IMAGE_UPLOAD/SUCCESS');
export const uploadImageOrganizationFail = createAction('IMAGE_UPLOAD/FAIL');

export const addOrganizationPolicy = createAction<CompanyPrivacyPolicyFormValues>('addOrganizationPolicy');
export const addOrganizationPolicySuccess = createAction('addOrganizationPolicySuccess');
export const addOrganizationPolicyFail = createAction('addOrganizationPolicyFail');

export const toggleUserActiveStatus = createAction<{ org_id: string; user_id: string; isActive: boolean }>(
    "TOGGLE_USER_ACTIVE_STATUS/REQUEST"
);
export const toggleUserActiveStatusSuccess = createAction<{ org_id: string; user_id: string; isActive: boolean }>(
    "TOGGLE_USER_ACTIVE_STATUS/SUCCESS"
);

export const toggleUserActiveStatusFail = createAction<Error>(
    "TOGGLE_USER_ACTIVE_STATUS/FAIL"
);