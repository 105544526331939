import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  $addBpContactPerson,
  $deleteBpContactPerson,
  $editBpContactPerson,
  $getAllBpContactPersons,
} from "@Services/PbContactPerson";
import { RootState } from "@Store";
import { IImageDTO } from "@Store/Teams/types";
import { BackendError } from "@Types";
import { BpContactPerson } from "@Types/bpContactPersons";

type BpContactPersonState = {
  list: {
    data: BpContactPerson[];
    loading: boolean;
    response:
      | {
          success: boolean;
          error: number;
          message: string | undefined;
        }
      | undefined;
  };
  selected: BpContactPerson | undefined;
  modal: {
    visible: boolean;
    loading: boolean;
    response:
      | {
          success: boolean;
          error: number;
          message: string | undefined;
        }
      | undefined;
  };
  deleteState: {
    loading: boolean;
    response:
      | {
          success: boolean;
          contactId: number;
          error: number;
          message: string | undefined;
        }
      | undefined;
  };
};

const initialState: BpContactPersonState = {
  list: { data: [], loading: false, response: undefined },
  modal: { response: undefined, loading: false, visible: false },
  deleteState: { response: undefined, loading: false },
  selected: undefined,
};

export const performGetBpContactPersons = createAsyncThunk<
  { error: BackendError | undefined; value: BpContactPerson[] | undefined },
  {},
  { state: RootState }
>("organization/perfromGetBpContactPersons", async (payload, thunkApi) => {
  thunkApi.dispatch(startLoadBpContactPersons());
  const data = await $getAllBpContactPersons();
  setTimeout(() => thunkApi.dispatch(endLoadBpContactPersons()), 1000);
  return data;
});

export const performEditBpContactPerson = createAsyncThunk<
  { error: any; value: BpContactPerson | undefined },
  BpContactPerson,
  { state: RootState }
>("organization/performEditBpContactPerson", async (payload, thunkApi) => {
  thunkApi.dispatch(startAddEditBpContactPersons());
  const data = await $editBpContactPerson(payload);
  setTimeout(() => thunkApi.dispatch(endAddEditBpContactPersons()), 1000);
  return data;
});

export const performDeleteBpContactPerson = createAsyncThunk<
  { error: any; id: number; value: BpContactPerson | undefined },
  BpContactPerson,
  { state: RootState }
>("organization/performDeleteBpContactPerson", async (payload, thunkApi) => {
  thunkApi.dispatch(startDeleteBpContactPersons());
  const data = await $deleteBpContactPerson(payload);
  setTimeout(() => thunkApi.dispatch(endDeleteBpContactPersons()), 1000);
  return data;
});

export const performAddBpContactPerson = createAsyncThunk<
  { error: any; value: BpContactPerson | undefined },
  BpContactPerson,
  { state: RootState }
>("organization/performAddBpContactPerson", async (payload, thunkApi) => {
  thunkApi.dispatch(startAddEditBpContactPersons());
  const data = await $addBpContactPerson(payload);
  setTimeout(() => thunkApi.dispatch(endAddEditBpContactPersons()), 1000);
  return data;
});

export const bpContactPersonSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    refreshBpContactPersonAvatar: (
      state,
      action: PayloadAction<{ contactPersonId: number; avatar: IImageDTO }>
    ) => {
      var userIdx = state.list.data.findIndex((o) => {
        return o.id === action.payload.contactPersonId;
      });
      state.list.data[userIdx].avatar = action.payload.avatar;
      if (state.selected?.id) {
        state.selected.avatar = action.payload.avatar;
      }
    },
    startLoadBpContactPersons: (state, action: PayloadAction) => {
      state.list.data = [];
      state.list.response = undefined;
      state.list.loading = true;
    },
    endLoadBpContactPersons: (state, action: PayloadAction) => {
      state.list.loading = false;
      state.list.response = undefined;
    },
    startAddEditBpContactPersons: (state, action: PayloadAction) => {
      state.modal.response = undefined;
      state.modal.loading = true;
    },
    endAddEditBpContactPersons: (state, action: PayloadAction) => {
      state.modal.response = undefined;
      state.modal.loading = false;
    },
    openAddEditBpContactPersons: (
      state,
      action: PayloadAction<BpContactPerson | undefined>
    ) => {
      state.modal.response = undefined;
      state.modal.visible = true;
      state.modal.loading = false;
      state.selected = action.payload;
    },
    closeAddEditBpContactPersons: (state, action: PayloadAction) => {
      state.modal.response = undefined;
      state.modal.loading = false;
      state.modal.visible = false;
    },
    startDeleteBpContactPersons: (state, action: PayloadAction) => {
      state.deleteState.response = undefined;
      state.deleteState.loading = true;
    },
    endDeleteBpContactPersons: (state, action: PayloadAction) => {
      state.deleteState.response = undefined;
      state.deleteState.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      performGetBpContactPersons.fulfilled,
      (state, { payload }) => {
        if (payload.error) {
          state.list.response = {
            success: false,
            error: payload.error.id,
            message: "",
          };
        } else {
          if (payload.value) {
            state.list.response = {
              success: true,
              error: -1,
              message: undefined,
            };
            state.list.data = payload.value;
          }
        }
      }
    );
    builder.addCase(performGetBpContactPersons.rejected, (state, error) => {
      state.list.response = {
        success: false,
        message: `Error get contact persons`,
        error: 999,
      };
      console.error(`Error get contact persons`, error);
    });
    builder.addCase(
      performEditBpContactPerson.fulfilled,
      (state, { payload }) => {
        if (payload.error) {
          state.modal.response = {
            success: false,
            error: payload.error.id,
            message: "",
          };
        } else {
          if (payload.value) {
            state.modal.response = {
              success: true,
              error: -1,
              message: "bpContactPersonEdited",
            };
          }
        }
      }
    );
    builder.addCase(performEditBpContactPerson.rejected, (state, error) => {
      state.modal.response = {
        success: false,
        message: `Error editing contact person`,
        error: 999,
      };
      console.error(`Error editing contact person`, error);
    });
    builder.addCase(
      performAddBpContactPerson.fulfilled,
      (state, { payload }) => {
        if (payload.error) {
          state.modal.response = {
            success: false,
            error: payload.error.id,
            message: "",
          };
        } else {
          if (payload.value) {
            state.modal.response = {
              success: true,
              error: -1,
              message: "bpContactPersonAdded",
            };
          }
        }
      }
    );
    builder.addCase(performAddBpContactPerson.rejected, (state, error) => {
      state.modal.response = {
        success: false,
        message: `Error adding contact person`,
        error: 999,
      };
      console.error(`Error adding contact person`, error);
    });
    builder.addCase(
      performDeleteBpContactPerson.fulfilled,
      (state, { payload }) => {
        if (payload.error) {
          state.deleteState.response = {
            success: false,
            contactId: payload.id ? payload.id : -1,
            error: payload.error.id,
            message: "",
          };
        } else {
          if (payload.value) {
            state.deleteState.response = {
              success: true,
              error: -1,
              contactId: payload.id ? payload.id : -1,
              message: "bpContactPersonDeleted",
            };
          }
        }
      }
    );
    builder.addCase(performDeleteBpContactPerson.rejected, (state, error) => {
      state.deleteState.response = {
        success: false,
        message: `Error deleting contact person`,
        contactId: -1,
        error: 999,
      };
      console.error(`Error deleting contact person`, error);
    });
  },
});

export const {
  refreshBpContactPersonAvatar,
  startLoadBpContactPersons,
  endLoadBpContactPersons,
  closeAddEditBpContactPersons,
  openAddEditBpContactPersons,
  startAddEditBpContactPersons,
  endAddEditBpContactPersons,
  startDeleteBpContactPersons,
  endDeleteBpContactPersons,
} = bpContactPersonSlice.actions;

export default bpContactPersonSlice.reducer;
