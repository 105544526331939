import { color } from "@Components/NavSection";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const SidebarContainer = styled.div`
  height: 100%;

  .pro-sidebar-inner {
    background-color: black !important;
  }

  .pro-icon-wrapper {
    background-color: transparent !important;
  }

  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: 0 !important;
  }
`;

const styledFirstYellowItem = css`
  margin-top: -10px;
  padding-top: 10px;
`;

interface ISidebarItemContainer {
  color: color;
}
export const SidebarItemContainer = styled.div<ISidebarItemContainer>`
  border-left: solid 3px ${({ theme, color }) => theme.palette[color]};

  font-weight: 500 !important;
  color: #9e9e9e !important;

  .active {
    color: white !important;
  }

  li.subMenuActive {
    .pro-arrow {
      border-color: black !important;
    }
    color: black !important;
    background-color: ${({ theme, color }) =>
      rgba(theme.palette[color], 0.5)} !important;
  }

  li.subMenuActive > div.pro-inner-item {
    background-color: ${({ theme, color }) => theme.palette[color]} !important;
  }
`;
