import styled from 'styled-components';

export const CorporateContainer = styled.div`
  width: 100%;
  height: max-content;
  margin-bottom: 25px;
    display: flex;
    justify-content: start;
    gap: 20px;
`;

export const CorporateContainerLeftSide = styled.div`
    width: 50%;
    background: #1D1D1D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;

export const CorporateContainerLeftSideUpload = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    margin-block: 40px;
`;

export const CorporateContainerRightSideUpload = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-block: 40px;
`

export const CorporateContainerLeftSideActions = styled.div`
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: end;
`

export const CorporateContainerRightSideActions = styled.div`
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: end;
`

export const CorporateContainerRightSide = styled.div`
    width: 50%;
    background: #1D1D1D;
    height: 550px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;



