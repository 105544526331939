export const toSentenceCase = (txt: string): string =>
  txt[0].toUpperCase() + txt.slice(1).toLowerCase();

export const debounce = <F extends (...params: any[]) => void>(
  fn: F,
  delay: number
) => {
  let timeoutID: number | null = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID!);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
};
