import styled from 'styled-components';

export const TableContainer = styled.div`
  padding: 0px 50px;
  width: 100%;
  height: max-content;
  margin-bottom: 25px;
`;

export const IconContainer = styled.div`
  width: 40%;
  text-align: center;
  color: #4D8569;
  font-size: 20px;
`;

export const ActionIconContainer = styled.div`
  width: 70px;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  span[aria-label="close"] svg {
    fill: #B43F4B;
  }
  span[aria-label="close"][disabled] svg {
    fill: gray!important;
  }
  span[aria-label="edit"] svg {
    fill: #FCD06D;
  }
`;
