import { IMatrixServer, MatrixServerIdentifier } from "@API/Matrix/types";
import { NewOrganizationPayload } from "@API/Organizations/types";
import FormField from "@Components/FormField";
import FormikForm from "@Components/FormikForm";
import { FormContainer } from "@Components/FormikForm/styles";
import { RootState } from "@Store";
import { matrixServersListRequest } from "@Store/Matrix/actions";
import { selectMatrixServers } from "@Store/Matrix/selectors";
import {
  createOrganizationRequest,
  resetCreateOrganizationModal,
} from "@Store/Organizations/actions";
import {
  selectCreateOrganizationModal,
  selectShowCreateOrganizationModal,
} from "@Store/Organizations/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import i18n from "@i18n";
import { Checkbox, Modal, Space } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { getInitialValues } from "./selectors";

const schema = object().shape({
  orgName: string().required(i18n.t("errors.validation.requiredField")),
  matrixServerIdentifier: string().required(
    i18n.t("errors.validation.requiredField")
  ),
  adminBpUserName: string()
    .email(i18n.t("errors.validation.emailFormat"))
    .nullable(),
});

function ModalAddOrganization() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { matrixServers, modal, modalIsVisible } = useAppSelector(
    (state: RootState) => ({
      matrixServers: selectMatrixServers(state),
      modal: selectCreateOrganizationModal(state),
      modalIsVisible: selectShowCreateOrganizationModal(state),
    })
  );

  const [filteredMatrixServers, setFilteredMatrixServers] =
    useState<IMatrixServer[]>(matrixServers);
  const [addNewAdminChecked, setAddNewAdminChecked] = useState<boolean>(false);

  useEffect(() => {
    if (matrixServers.length === 0) return;

    const identifiers: MatrixServerIdentifier[] = matrixServers.map(
      ({ identifier }: IMatrixServer) => identifier
    );
    const filtered: IMatrixServer[] = matrixServers.filter(
      ({ identifier }: IMatrixServer, index: number) =>
        !identifiers.includes(identifier, index + 1)
    );
    setFilteredMatrixServers(filtered);
  }, [matrixServers]);

  useEffect(() => {
    if (!modalIsVisible) return;

    dispatch(matrixServersListRequest());
  }, [dispatch, modalIsVisible]);

  const onCancel = useCallback(
    () => !modal.isLoading && dispatch(resetCreateOrganizationModal()),
    [dispatch, modal.isLoading]
  );

  const onSubmit = (newOrganization: NewOrganizationPayload) => {
    if (!addNewAdminChecked || newOrganization.adminBpUserName === "") {
      newOrganization.adminBpUserName = null;
    }

    dispatch(createOrganizationRequest(newOrganization));
  };

  return (
    <Modal
      title={t("organization.modals.addOrganization.title")}
      closeIcon={modal.isLoading}
      visible={modalIsVisible}
      onCancel={onCancel}
      okText={t("buttons.save")}
      footer={null}
      maskClosable={false}
    >
      <FormContainer>
        <FormikForm
          initialValues={getInitialValues}
          onSubmit={onSubmit}
          schema={schema}
          showCancelButton={false}
          saveButtonName={t("organization.modals.addOrganization.submit")}
        >
          <FormField
            name="orgName"
            isRequired
            label={t("organization.modals.addOrganization.name")}
            type="text"
          />
          <FormField
            name="matrixServerIdentifier"
            isRequired
            label={t(
              "organization.modals.addOrganization.matrixServerIdentifier"
            )}
            type="select"
            options={filteredMatrixServers.map(
              ({ identifier, externalUrl, type, description }: IMatrixServer) => ({
                value: identifier,
                label: type + ' - ' + externalUrl + (description ? (' - ' + description) : '')
              })
            )}
          />
          <Space direction="vertical" style={{ width: "100%" }}>
            <Checkbox
              checked={addNewAdminChecked}
              onChange={(event: CheckboxChangeEvent) =>
                setAddNewAdminChecked(event.target.checked)
              }
            >
              Add admin?
            </Checkbox>
            {addNewAdminChecked && (
              <FormField
                name="adminBpUserName"
                label={t("organization.modals.addOrganization.initialAdmin")}
                type="email"
              />
            )}
          </Space>
        </FormikForm>
      </FormContainer>
    </Modal>
  );
}

export default ModalAddOrganization;
