import styled from 'styled-components';

export const LoginContainer = styled.div`
  width: 400px;
  margin: auto;
  margin-top: 10%;
  padding: 40px 20px;
  border: solid 1px ${({ theme }) => theme.palette.mainYellow};
  border-radius: 24px;
`;
