import "./App.scss";
import "./Index.less";

import { selectLanguage } from "@Store/App/selectors";
import { useAppSelector } from "@hooks";
import MainRouter from "@src/Router";
import moment from "moment/moment";
import { useEffect } from "react";

function App() {
  const selectedLanguage = useAppSelector(selectLanguage);

  useEffect(() => {
    moment.locale(selectedLanguage);
  }, [selectedLanguage]);

  return <MainRouter />;
}

export default App;
