import styled from 'styled-components';

export const StyledHeader = styled.div`
  min-height: 60px;
`;

export const AppName = styled.div`
  padding-right: 5vw;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 17px;
`;

export const OrgName = styled.div`
  color: #9E9E9E;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 12px;
`;
