import MainComponent from "@src/MainComponent";
import {PageContainer} from "@commonStyles";
import {useTranslation} from "react-i18next";
import {Tabs} from "antd";
import TabPane = Tabs.TabPane;
import PrivacyTab from "@Pages/CompanyLegalPage/privacyTab";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "@hooks";
import {getOrganizationPolicyRequest} from "@Store/Organizations/actions";
import {RootState} from "@Store";
import {selectCurrentOrganization, selectOrganizationPolicyData} from "@Store/Organizations/selectors";

function CompanyLegalPage() {
    const { t } = useTranslation();
    const [activeTabKey, setActiveTabKey] = useState('privacy');
    const dispatch = useAppDispatch();

    const { currentOrganization, policyData} = useAppSelector((state: RootState) => ({
        currentOrganization: selectCurrentOrganization(state),
        policyData:  selectOrganizationPolicyData(state),
    }));

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    useEffect(() => {
        if (currentOrganization?.orgId) {
            dispatch(getOrganizationPolicyRequest({ orgId: currentOrganization.orgId, type: activeTabKey }));
        }
    }, [dispatch, currentOrganization, activeTabKey]);



    return (
        <MainComponent>
            <PageContainer>
                <Tabs type="card" activeKey={activeTabKey} onChange={onChange}>
                    <TabPane key="privacy" tab={t("sidebar.organization.privacy")}>
                        <PrivacyTab type={'PRIVACY'}  currentOrganization={currentOrganization} policyData={policyData}/>
                    </TabPane>
                    <TabPane key="gtac" tab={t("sidebar.organization.terms")}>
                        <PrivacyTab type={'GTAC'} currentOrganization={currentOrganization} policyData={policyData} />
                    </TabPane>
                </Tabs>
            </PageContainer>
        </MainComponent>
    );
}

export default CompanyLegalPage;
