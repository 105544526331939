import "./index.scss";

import { selectMasterData } from "@Store/MasterData/selectors";
import { IAddress, IAddressType, ICountry } from "@Store/MasterData/types";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "@hooks";
import { Button, Form, Input, Select, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { Text } = Typography;

export const getAddressText = (address: IAddress) => {
  return `${address.street} ${address.streetNumber}, ${address.zip}, ${address.city} (${address.country?.isoCode})`;
};

export type AddressItemProps = {
  address: IAddress;
  loading: boolean;
  onSave: any;
  onCancle: any;
  onDelete: any;
};

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

type AddressForm = IAddress & {};

const AddressItem: FunctionComponent<AddressItemProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm<AddressForm>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const { countries, addressTypes } = useAppSelector(selectMasterData);

  useEffect(() => {
    form.setFieldsValue(props.address);
    if (props.address.id === 0) {
      setEditMode(true);
    }
  }, [props.address]);

  const onOk = (address: IAddress) => {
    var countryIdx = countries.findIndex((c: any) => {
      return c.id === address.country?.id;
    });
    var typeIdx = addressTypes.findIndex((c: any) => {
      return c.id === address.addressType?.id;
    });

    address.id = props.address.id;
    address.country = countries[countryIdx];
    address.addressType = addressTypes[typeIdx];

    props.onSave(address);
    setEditMode(false);
  };

  const onCancle = () => {
    setEditMode(false);
    props.onCancle(props.address.id);
  };

  const onDelete = () => {
    setEditMode(false);
    props.onDelete(props.address.id);
  };
  return (
    <>
      <div className={!editMode ? "horizontalFormItem" : "verticalFormItem"}>
        {!editMode ? (
          <div>
            <Text>{getAddressText(props.address)}</Text>
          </div>
        ) : (
          <Form
            form={form}
            onFinish={onOk}
            scrollToFirstError
            layout="vertical"
            validateMessages={validateMessages}
          >
            <Form.Item
              label={t("modals.address.addressType")}
              name={["addressType", "id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                id={`addressType_${props.address.id}`}
                placeholder={t("modals.address.placeholders.selectAddressType")}
                style={{ width: "100%" }}
              >
                {addressTypes.map((type: IAddressType) => (
                  <Option key={type.id} value={type.id}>
                    {t(`addressTypes.${type.name}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("modals.address.country")}
              name={["country", "id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                id={`country_${props.address.id}`}
                placeholder={t("modals.address.placeholders.selectCountry")}
                style={{ width: "100%" }}
              >
                {countries.map((country: ICountry) => (
                  <Option key={country.id} value={country.id}>
                    {t(`countries.${country.name}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: 0 }}>
              <Form.Item
                label={t("modals.address.street")}
                name={"street"}
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  id={`street_${props.address.id}`}
                  placeholder={t("modals.address.street")}
                  disabled={props.loading}
                />
              </Form.Item>
              <Form.Item
                label={t("modals.address.streetNumber")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
                name={"streetNumber"}
              >
                <Input
                  id={`streetNumber_${props.address.id}`}
                  placeholder={t("modals.address.streetNumber")}
                  disabled={props.loading}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={t("modals.address.zip")}
                name={"zip"}
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  id={`zip_${props.address.id}`}
                  placeholder={t("modals.address.zip")}
                  disabled={props.loading}
                />
              </Form.Item>
              <Form.Item
                label={t("modals.address.city")}
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  margin: "0 8px",
                }}
                name={"city"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  id={`city_${props.address.id}`}
                  placeholder={t("modals.address.city")}
                  disabled={props.loading}
                />
              </Form.Item>
            </Form.Item>
          </Form>
        )}
        <div className="horizontalButtonItem">
          {editMode && (
            <div style={{ paddingRight: 15 }}>
              <Button icon={<CloseOutlined />} onClick={() => onCancle()} />
            </div>
          )}
          {editMode && props.address.id !== 0 && (
            <div style={{ paddingRight: 15 }}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDelete()}
              />
            </div>
          )}
          {editMode && (
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={() => form.submit()}
            />
          )}
          {!editMode && (
            <Button
              ghost
              icon={<EditOutlined />}
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AddressItem;
