import { RootState } from "@Store";
import { IOrganizationTeamListItem } from "./types";

export const selectTeams = (state: RootState) => state.teams.general.list;
export const selectTeam = (
  state: RootState
): IOrganizationTeamListItem | undefined =>
  state.teams.general.teamModal.team && {
    ...state.teams.general.teamModal.team,
    teamMemberCount: state.teams.general.teamModal.team.teamMembers.length || 0,
    administrators: [],
  };

export const selectTeamModal = (state: RootState) =>
  state.teams.general.teamModal;
export const selectSelectedTeamId = (state: RootState) =>
  state.teams.general.teamModal.team?.id;
