import FormField from "@Components/FormField";
import FormikForm from "@Components/FormikForm";
import {
  formatUserInvitationData,
  selectInviteUserModalValues,
} from "@Pages/UsersPage/selectors";
import { RootState } from "@Store";
import {
  createInviteUserRequest,
  resetInviteUserModal,
} from "@Store/Invites/actions";
import {
  selectInviteUserModal,
  selectShowInviteUserModal,
} from "@Store/Invites/selectors";
import { UserInvitationFormValues } from "@Store/Invites/types";
import { selectCurrentOrganization } from "@Store/Organizations/selectors";
import { useAppDispatch, useAppSelector } from "@hooks";
import i18n from "@i18n";
import { Modal } from "antd";
import { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { date, object, string } from "yup";

const schema = object().shape({
  bpUserName: string()
    .email(i18n.t("inviteUser.modal.validation.emailAddress.format"))
    .required(i18n.t("inviteUser.modal.validation.emailAddress.required")),
  validUntil: date()
    .nullable()
    .required(i18n.t("inviteUser.modal.validation.validUntil.required"))
    .min(new Date(), "inviteUser.modal.validation.validUntil.minDate"),
});

const ModalInviteUser: FunctionComponent = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { initialValues, modal, modalIsVisible, currentOrganization } =
    useAppSelector((state: RootState) => ({
      initialValues: selectInviteUserModalValues(state),
      modal: selectInviteUserModal(state),
      modalIsVisible: selectShowInviteUserModal(state),
      currentOrganization: selectCurrentOrganization(state),
    }));

  const onCancel = useCallback(
    () => !modal.isLoading && dispatch(resetInviteUserModal()),
    [dispatch, modal.isLoading]
  );

  const onSubmit = (data: UserInvitationFormValues) => {
    const formattedData: UserInvitationFormValues =
      formatUserInvitationData(data);

    dispatch(
      createInviteUserRequest({
        orgId: currentOrganization.orgId,
        bpUserName: formattedData.bpUserName,
        validUntil: formattedData.validUntil,
      })
    );
  };

  return (
    <Modal
      title={t("inviteUser.modal.title", {
        organizationName: currentOrganization.orgName,
      })}
      closeIcon={modal.isLoading}
      visible={modalIsVisible}
      onCancel={onCancel}
      okText="Save"
      footer={null}
      maskClosable={false}
    >
      <FormikForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        schema={schema}
        showCancelButton={false}
        disabled
        saveButtonName={t("inviteUser.modal.submit")}
      >
        <FormField
          name="bpUserName"
          isRequired
          label={t("inviteUser.modal.emailAddress")}
          type="email"
        />
        <FormField
          name="validUntil"
          label={t("inviteUser.modal.validUntil")}
          type="datePicker"
        />
      </FormikForm>
    </Modal>
  );
};
export default ModalInviteUser;
