import FormField from "@Components/FormField";
import FormikForm from "@Components/FormikForm";
import Header from "@Components/Header";
import { loginRequest } from "@Store/App/actions";
import { ILoginData } from "@Store/App/types";
import { PageContainer } from "@commonStyles";
import { useAppDispatch } from "@hooks";
import i18n from "@i18n";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { LoginContainer } from "./styles";

const schema = object().shape({
  username: string().required(i18n.t("errors.validation.requiredField")),
  password: string().required(i18n.t("errors.validation.requiredField")),
});

const initialValues: any = {
  username: "",
  password: "",
};

// TODO: add loader on loading

function LoginPage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onSubmit = (data: ILoginData) => {
    localStorage.setItem("emailAddress", data.username.toLowerCase());
    dispatch(loginRequest(data));
  };

  return (
    <>
      <Header />
      <PageContainer>
        <LoginContainer>
          <FormikForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableEnterSubmit
            schema={schema}
            showCancelButton={false}
            saveButtonName={t("general.logIn")}
          >
            <FormField
              name="username"
              isRequired
              label={t("general.username")}
              type="text"
            />
            <FormField
              name="password"
              isRequired
              label={t("general.password")}
              type={showPassword ? "text" : "password"}
            />
            <Checkbox
              onChange={() => setShowPassword(!showPassword)}
              checked={showPassword}
            >
              {t("general.showPassword")}
            </Checkbox>
          </FormikForm>
        </LoginContainer>
      </PageContainer>
    </>
  );
}

export default LoginPage;
