import AddressItem from "@Components/AdressesForm/AddressItem";
import PhoneNumberItem from "@Components/TelephoneNumbersForm/TelephoneNumberItem";
import { GetImageUrl_BpContactPersons } from "@Services/PbContactPerson";
import { IAddress, IPhoneNumber } from "@Store/MasterData/types";
import {
  closeAddEditBpContactPersons,
  performAddBpContactPerson,
  performEditBpContactPerson,
  performGetBpContactPersons,
  refreshBpContactPersonAvatar,
} from "@Store/Reducers/BpContactPersonReducer";
import { BpContactPerson } from "@Types/bpContactPersons";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import { getUserName } from "@src/Utilities";
import { headers } from "@src/app/Http";
import {
  Button,
  Form,
  Input,
  List,
  Modal,
  Space,
  Upload,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarAsync from "../AvatarAsync";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export type ModalBpContactPersonProps = {};

type BpContactPersonForm = BpContactPerson & {};

const ModalBpContactPerson: FunctionComponent<ModalBpContactPersonProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const { modal, selected } = useAppSelector((state) => state.bpContactPerson);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [newAddressMode, setNewAddressMode] = useState<boolean>(false);
  const [newCreatedAddresses, setNewCreatedAddresses] = useState<number>(-1);
  const [phoneNumbers, setPhoneNumbers] = useState<IPhoneNumber[]>([]);
  const [newPhoneNumberMode, setNewPhoneNumberMode] = useState<boolean>(false);
  const [newCreatedPhoneNumbers, setNewCreatedPhoneNumbers] =
    useState<number>(-1);
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm<BpContactPersonForm>();
  const initialFormValues: BpContactPersonForm = {
    id: -1,
    addresses: [],
    avatar: undefined,
    email: "",
    firstname: "",
    lastname: "",
    department: "",
    departmentJob: "",
    phoneNumbers: [],
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (typeof modal === "undefined") {
      return;
    }

    if (modal.response != undefined) {
      if (modal.response.success) {
        notification.success({
          message: t(`success.${modal.response.message}`, {
            name: getUserName({
              givenName: selected?.firstname,
              surName: selected?.lastname,
              emailAddress: selected?.email,
            }),
          }),
        });
        dispatch(performGetBpContactPersons({}));
        dispatch(closeAddEditBpContactPersons());
      }
    }

    setModalVisible(modal.visible);
    setLoading(modal.loading);
  }, [modal]);

  useEffect(() => {
    if (typeof selected === "undefined") {
      return;
    }
    initialFormValues.id = selected.id;
    initialFormValues.addresses = selected.addresses;
    initialFormValues.email = selected.email;
    initialFormValues.firstname = selected.firstname;
    initialFormValues.lastname = selected.lastname;

    setAddresses(selected.addresses);
    setNewAddressMode(false);
    setNewCreatedAddresses(-1);

    setPhoneNumbers(selected.phoneNumbers);
    setNewPhoneNumberMode(false);
    setNewCreatedPhoneNumbers(-1);

    form.setFieldsValue(selected);
  }, [selected]);

  const onCancel = () => {
    dispatch(closeAddEditBpContactPersons());
  };

  const onOk = (data: BpContactPerson) => {
    data.addresses = addresses;
    data.phoneNumbers = phoneNumbers;
    if (selected && selected.id !== 0) {
      data.id = selected.id;
      dispatch(performEditBpContactPerson(data));
    } else {
      dispatch(performAddBpContactPerson(data));
    }
  };

  const addAddress = (values: IAddress) => {
    var idx = addresses.findIndex((a) => {
      return a.id === values.id;
    });

    if (values.id === 0) {
      values.id = newCreatedAddresses;
      setNewCreatedAddresses(newCreatedAddresses - 1);
    }

    var newArray = JSON.parse(JSON.stringify(addresses));

    if (idx === -1) {
      newArray.push(values);
    } else {
      newArray[idx] = values;
    }

    setAddresses(newArray);
    setNewAddressMode(false);
  };

  const addNewAddress = () => {
    var newTmpAddresses: IAddress[] = [];
    newTmpAddresses = JSON.parse(JSON.stringify(addresses));

    newTmpAddresses.unshift({
      id: 0,
      addressType: undefined,
      city: "",
      country: undefined,
      street: "",
      streetNumber: "",
      zip: "",
    });

    setAddresses(newTmpAddresses);
    setNewAddressMode(true);
  };

  const onCancelAddress = (id: number) => {
    if (id === 0) {
      var newTmpAddresses: IAddress[] = [];
      newTmpAddresses = JSON.parse(JSON.stringify(addresses));
      newTmpAddresses.shift();
      setAddresses(newTmpAddresses);
      setNewAddressMode(false);
    }
  };

  const onDeleteAddress = (id: number) => {
    var newTmpAddresses: IAddress[] = [];
    newTmpAddresses = JSON.parse(JSON.stringify(addresses));
    var idx = newTmpAddresses.findIndex((a) => {
      return a.id === id;
    });
    newTmpAddresses.splice(idx, 1);
    setAddresses(newTmpAddresses);
  };

  const addPhoneNumber = (values: IPhoneNumber) => {
    var idx = phoneNumbers.findIndex((a) => {
      return a.id === values.id;
    });

    if (values.id === 0) {
      values.id = newCreatedPhoneNumbers;
      setNewCreatedPhoneNumbers(newCreatedPhoneNumbers - 1);
    }

    var newArray = JSON.parse(JSON.stringify(phoneNumbers));

    if (idx === -1) {
      newArray.push(values);
    } else {
      newArray[idx] = values;
    }

    setPhoneNumbers(newArray);
    setNewPhoneNumberMode(false);
  };

  const addNewPhoneNumber = () => {
    var newTmpPhoneNumbers: IPhoneNumber[] = [];
    newTmpPhoneNumbers = JSON.parse(JSON.stringify(phoneNumbers));

    newTmpPhoneNumbers.unshift({
      id: 0,
      phoneNumberType: undefined,
      number: "",
      country: undefined,
    });

    setPhoneNumbers(newTmpPhoneNumbers);
    setNewPhoneNumberMode(true);
  };

  const onCancelPhoneNumber = (id: number) => {
    if (id === 0) {
      var newTmpPhoneNumbers: IPhoneNumber[] = [];
      newTmpPhoneNumbers = JSON.parse(JSON.stringify(phoneNumbers));
      newTmpPhoneNumbers.shift();
      setPhoneNumbers(newTmpPhoneNumbers);
      setNewPhoneNumberMode(false);
    }
  };

  const onDeletePhoneNumber = (id: number) => {
    var newTmpPhoneNumbers: IPhoneNumber[] = [];
    newTmpPhoneNumbers = JSON.parse(JSON.stringify(phoneNumbers));
    var idx = newTmpPhoneNumbers.findIndex((a) => {
      return a.id === id;
    });
    newTmpPhoneNumbers.splice(idx, 1);
    setPhoneNumbers(newTmpPhoneNumbers);
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("buttons.save")}
      title={
        selected && selected.id !== 0
          ? t("bpContactPersons.modals.editTitle", {
              name: getUserName({
                givenName: selected?.firstname,
                surName: selected?.lastname,
                emailAddress: selected?.email,
              }),
            })
          : t("bpContactPersons.modals.newTitle")
      }
      visible={modalVisible}
      onCancel={!loading ? onCancel : undefined}
      closeIcon={loading}
      footer={null}
    >
      {selected && selected.id !== 0 && (
        <div className="center_image">
          <div style={{ paddingBottom: 25 }}>
            <AvatarAsync
              size={"big"}
              url={
                selected.avatar?.update
                  ? `${GetImageUrl_BpContactPersons(selected.id)}?${
                      selected.avatar?.update
                    }`
                  : ""
              }
            />
          </div>
          <ImgCrop rotate>
            <Upload
              listType="text"
              className="avatar-uploader"
              maxCount={1}
              showUploadList={false}
              headers={headers}
              action={`${GetImageUrl_BpContactPersons(selected.id)}/upload`}
              onChange={(info: any) => {
                if (
                  info.file.status === "done" ||
                  info.file.status === "error"
                ) {
                  if (info.file.status === "done" && selected.id) {
                    dispatch(
                      refreshBpContactPersonAvatar({
                        contactPersonId: selected.id,
                        avatar: info.file.response,
                      })
                    );
                  }
                  setLoading(false);
                }
                return true;
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Avatar</Button>
            </Upload>
          </ImgCrop>
        </div>
      )}
      <Form
        form={form}
        onFinish={onOk}
        scrollToFirstError
        layout="vertical"
        validateMessages={validateMessages}
        initialValues={initialFormValues}
      >
        {/* EMail */}
        <Form.Item
          name={"email"}
          label={t("bpContactPersons.modals.email")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        {/* FirstName */}
        <Form.Item
          name={"firstname"}
          label={t("bpContactPersons.modals.firstname")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        {/* LastName */}
        <Form.Item
          name={"lastname"}
          label={t("bpContactPersons.modals.lastname")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        {/* Department */}
        <Form.Item
          name={"department"}
          label={t("bpContactPersons.modals.department")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>

        {/* DepartmentJob */}
        <Form.Item
          name={"departmentJob"}
          label={t("bpContactPersons.modals.departmentJob")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      </Form>
      <List
        bordered
        size="small"
        dataSource={phoneNumbers}
        header={
          <div className="listHeader">
            <div>{t("bpContactPersons.modals.phoneNumbers")}</div>
            <div>
              <Button
                disabled={loading || newPhoneNumberMode}
                ghost
                icon={<PlusOutlined />}
                onClick={() => addNewPhoneNumber()}
              />
            </div>
          </div>
        }
        renderItem={(item) =>
          item && [
            <List.Item>
              <List.Item.Meta
                title={
                  item.id === 0
                    ? t("modals.phoneNumber.newPhoneNumber")
                    : t(`phoneNumberTypes.${item.phoneNumberType?.name}`)
                }
                description={
                  <PhoneNumberItem
                    onCancle={(id: number) => onCancelPhoneNumber(id)}
                    onDelete={(id: number) => onDeletePhoneNumber(id)}
                    onSave={(data: IPhoneNumber) => addPhoneNumber(data)}
                    phoneNumber={item}
                    loading={loading}
                  ></PhoneNumberItem>
                }
              />
            </List.Item>,
          ]
        }
      />
      <div style={{ paddingTop: 20 }}>
        <List
          bordered
          size="small"
          dataSource={addresses}
          header={
            <div className="listHeader">
              <div>{t("bpContactPersons.modals.addresses")}</div>
              <div>
                <Button
                  disabled={loading || newAddressMode}
                  ghost
                  icon={<PlusOutlined />}
                  onClick={() => addNewAddress()}
                />
              </div>
            </div>
          }
          renderItem={(item) =>
            item && [
              <List.Item>
                <List.Item.Meta
                  title={
                    item.id === 0
                      ? t("modals.address.newAddresses")
                      : t(`addressTypes.${item.addressType?.name}`)
                  }
                  description={
                    <AddressItem
                      onCancle={(id: number) => onCancelAddress(id)}
                      onDelete={(id: number) => onDeleteAddress(id)}
                      onSave={(data: IAddress) => addAddress(data)}
                      address={item}
                      loading={loading}
                    ></AddressItem>
                  }
                />
              </List.Item>,
            ]
          }
        />
      </div>
      <Space size="middle" style={{ paddingTop: 25 }}>
        <Button disabled={loading} onClick={onCancel}>
          {t("buttons.cancel")}
        </Button>
        <Button type="primary" onClick={form.submit} loading={loading}>
          {t("buttons.save")}
        </Button>
      </Space>
    </Modal>
  );
};

export default ModalBpContactPerson;
