import "./index.scss";

import { MessageOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import i18n from "@i18n";
import { Dropdown, Menu, notification, Space } from "antd";
import { useTranslation } from "react-i18next";

function HelpMenu() {
  const { t } = useTranslation();

  const handleContactSupportClick = () => {
    navigator.clipboard
      .writeText("support@blackpin.de")
      .catch((reason) => console.error(reason));

    notification.success({
      message: i18n.t("helpMenu.copyToClipboardNotification"),
      placement: "bottomRight",
      closeIcon: <></>,
    });
  };

  const menu: JSX.Element = (
    <Menu>
      <Menu.Item onClick={handleContactSupportClick}>
        <Space>
          <MessageOutlined />
          {t("helpMenu.contactSupport")}
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <QuestionCircleOutlined />
    </Dropdown>
  );
}

export default HelpMenu;
