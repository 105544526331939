import "./index.scss";

import Table from "@Components/Table";
import {
  organizationsListRequest,
  setModalCreateOrganization,
  setModalCreateOrganizationType,
} from "@Store/Organizations/actions";
import {
  selectOrganizationsGeneralData,
  selectOrganizationsList,
} from "@Store/Organizations/selectors";
import { IOrganization } from "@Store/Organizations/types";
import { PlusOutlined } from "@ant-design/icons";
import { useAppSelector } from "@hooks";
import MainComponent from "@src/MainComponent";
import { Button, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ModalAddOrganization from "./ModalAddOrganization";
import { ORGANIZATIONS_TABLE_COLUMNS } from "./columns";

const { Title } = Typography;

function OrganizationsPage(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const userInfo = useAppSelector(selectAppUserData);
  const { isLoading } = useAppSelector(selectOrganizationsGeneralData);
  const organizationsList: IOrganization[] = useAppSelector(
    selectOrganizationsList
  );

  // const [modalEditOrganizationId, setModalEditOrganizationVisible] = useState<
  //   OrganizationId | undefined
  // >(undefined);

  useEffect(() => {
    dispatch(organizationsListRequest());
  }, [dispatch]);

  // const handleModalEditOrganizationCancel = () => {
  //   setModalEditOrganizationVisible(undefined);
  // };

  // const breadcrumpList = () => {
  //   return [{ id: 0, url: `/organization/`, text: t("organization.title") }];
  // };

  return (
    <MainComponent>
      {!isLoading && (
        <div className="app__organization">
          <Row justify={"space-between"} style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Title level={3}>{t("organization.title")}</Title>
            </div>
            <div>
              <div className="inviteButtonContainer">
                <div style={{ paddingRight: 10 }}>
                  <Button
                    style={{ height: 40, textTransform: "uppercase" }}
                    type="primary"
                    onClick={() => {
                      dispatch(setModalCreateOrganizationType("create"));
                      dispatch(setModalCreateOrganization());
                    }}
                    icon={<PlusOutlined />}
                  >
                    {t("organization.buttons.addOrganization")}
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <Table
                columns={
                  ORGANIZATIONS_TABLE_COLUMNS()
                  // setModalEditOrganizationVisible
                }
                data={organizationsList}
              />
            </Col>
          </Row>
          <ModalAddOrganization />
          {/*<ModalEditOrganization*/}
          {/*  onCancel={handleModalEditOrganizationCancel}*/}
          {/*  id={modalEditOrganizationId}*/}
          {/*/>*/}
        </div>
      )}
    </MainComponent>
  );
}

export default OrganizationsPage;
