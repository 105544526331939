import { SearchOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputContainer, SearchBlock } from './styles';

interface ISearchInput {
  onChangeHandler: (value: string) => void,
}

const SearchInput: FC<ISearchInput> = ({ onChangeHandler }) => {
  const { t } = useTranslation();
  return (
    <InputContainer>
      <Input
        placeholder={t('forms.search')}
        onChange={e => onChangeHandler(e.target.value)}
      />
      <SearchBlock>
      <SearchOutlined />
      </SearchBlock>
    </InputContainer>
  );
};

export default SearchInput;