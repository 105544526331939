import { createSelector } from "@reduxjs/toolkit";
import { IOrganizationUser } from "@Store/Organizations/types";
import { selectOrganizationUsers } from "@Store/Users/selectors";

export const selectOrganizationAdmins = createSelector(
  selectOrganizationUsers,
  (users: IOrganizationUser[]) =>
    users.filter(
      (user: IOrganizationUser): boolean => user.role !== "SIMPLE_USER"
    )
);
// if (!currentOrganization) return [];
// return currentOrganization?.administrators
//   .map((searchId: number) => {
//     const admin = users.find(
//       ({ userId }: OrganizationUserInfo) => userId === `${searchId}`
//     );
//     if (!admin) return {};
//     return {
//       user: admin.userId,
//       // superAdmin: index === 0 ? true : admin.user.superAdmin,
//       nickname: admin.nickName,
//       department: admin.department,
//       departmentJob: admin.department,
//       active: admin.status === "ACTIVE",
//       phoneNumbers: admin.mobileNumber,
//       // TODO: image url
//       avatar: "",
//     };
//   })
//   .sort(({ superAdmin: a }: any, { superAdmin: b }: any) => b - a);
