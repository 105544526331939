import { getAxiosAppUser } from "@API/AppUser/endpoints";
import { IGetAxiosAppUser } from "@API/AppUser/types";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  appUserDataFail,
  appUserDataRequest,
  appUserDataSuccess,
} from "./actions";

function* onAppUserDataRequest() {
  try {
    const appUser: IGetAxiosAppUser = yield call(getAxiosAppUser);

    if (appUser.error) throw appUser.error;

    yield put(appUserDataSuccess(appUser.data));
  } catch (e) {
    console.error(e);
    yield put(appUserDataFail(e as Error));
  }
}

export default function* () {
  yield takeLatest(appUserDataRequest, onAppUserDataRequest);
}
