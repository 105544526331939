/**
 * Calculates the time remaining until the given date.
 * @param timeRemaining The time remaining in milliseconds.
 * @returns {string} The time remaining in a human-readable format.
 */
export const calculateCountdownTimer = (timeRemaining: number): string => {
  const days: number = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours: number = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes: number = Math.floor(
    (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds: number = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  let countdownText = "";

  if (days > 0) {
    countdownText += `${days}d `;
  }
  if (hours > 0) {
    countdownText += `${hours}h `;
  }
  if (minutes > 0) {
    countdownText += `${minutes}m `;
  }
  if (seconds > 0) {
    countdownText += `${seconds}s `;
  }

  return countdownText.trim();
};
