import FormField from "@Components/FormField";
import FormikForm from "@Components/FormikForm";
import { IImageUpload } from "@Components/ImageUpload";
import { selectAppUserData } from "@Store/AppUser/selectors";
import { selectCurrentOrgId } from "@Store/Organizations/selectors";
import { IOrganizationUser } from "@Store/Organizations/types";
import { resetUserModal, updateUserRequest } from "@Store/Users/actions";
import {
  selectShowEditUserModal,
  selectUserModal,
} from "@Store/Users/selectors";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@hooks";
import i18n from "@i18n";
import { getUserName } from "@src/Utilities";
import { Modal } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { object, string } from "yup";
import { formatUserData, selectEditAddUserModalValues } from "./selectors";

const schema = object().shape({
    role: string().required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.role") })),
    givenName: string().required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.givenName") })),
    surName: string().required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.surName") })),
    nickName: string().nullable(),
    description: string().nullable(),
    department: string().required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.department") })),
    emailAddress: string().email(i18n.t("errors.validation.emailFormat")).required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.emailAddress") })),
    mobileNumber: string().required(i18n.t("errors.validation.requiredField", { field: i18n.t("organizationUsers.modals.mobileNumber") })),
});


function ModalOrganizationUser(props: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { initialValues, modal, modalIsVisible, orgId } = useAppSelector(
    (state) => ({
      initialValues: selectEditAddUserModalValues(state),
      userInfo: selectAppUserData(state),
      modal: selectUserModal(state),
      modalIsVisible: selectShowEditUserModal(state),
      orgId: selectCurrentOrgId(state),
    })
  );

  const onCancel = useCallback(
    () => !modal.isLoading && dispatch(resetUserModal()),
    [dispatch, modal.isLoading]
  );

  const onSubmit = (
    data: IOrganizationUser
  ) => {
    if (!initialValues) return;

    const formattedData = formatUserData(data);

    if (!formattedData.orgId) return;

    dispatch(updateUserRequest(formattedData));
  };

  // TODO: add loading to upload button
  const uploadButton = (
    <div>
      {modal.isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const imageUploadProps: IImageUpload = {
    shape: "circle",
    // TODO: image url
    updateUrl: "",
    // TODO: image url
    getUrl: "",
    onDoneCb: (info: any) => {},
  };

  return (
    <Modal
      title={
        initialValues?.userId
          ? t("organizationUsers.modals.editTitle", {
              name: initialValues
                ? getUserName({
                    givenName: initialValues.givenName,
                    surName: initialValues.surName,
                    nickName: initialValues.nickName,
                    displayName: initialValues.displayName,
                    emailAddress: initialValues.emailAddress,
                  })
                : "",
            })
          : t("organizationUsers.modals.newTitle")
      }
      closeIcon={modal.isLoading}
      visible={modalIsVisible}
      onCancel={onCancel}
      okText={t("buttons.save")}
      footer={null}
      maskClosable={false}
    >
      <FormikForm
        initialValues={{
          ...initialValues,
        }}
        onSubmit={onSubmit}
        schema={schema}
        cancelCb={onCancel}
      >
        <FormField
          name="role"
          label={t("organizationUsers.modals.role")}
          type="select"
          options={[
            { value: "SIMPLE_USER", label: t("user") },
            { value: "ORG_ADMIN", label: t("orgAdmin") },
          ]}
        />
        <FormField
          name="givenName"
          label={t("organizationUsers.modals.givenName")}
          type="text"
        />
        <FormField
          name="surName"
          label={t("organizationUsers.modals.surName")}
          type="text"
        />
        <FormField
          name="nickName"
          label={t("organizationUsers.modals.nickName")}
          type="text"
        />
        <FormField
          name="description"
          label={t("organizationUsers.modals.description")}
          type="text"
        />
        <FormField
          name="department"
          label={t("organizationUsers.modals.department")}
          type="text"
        />
        <FormField
          name="emailAddress"
          label={t("organizationUsers.modals.emailAddress")}
          type="email"
        />
        <FormField
          name="mobileNumber"
          label={t("organizationUsers.modals.mobileNumber")}
          type="text"
        />
      </FormikForm>
    </Modal>
  );
}

// for save btn
// disabled={newAddressMode}
// loading={loading}>

// for cancel btn
// disabled={loading || newAddressMode}
export default ModalOrganizationUser;
