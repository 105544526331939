import { storeState } from "@Store";
import { logoutRequest } from "@Store/App/actions";
import { LogoutOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@hooks";
import { Tooltip, Button } from "antd";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

function LogoutAction() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleLogout: MouseEventHandler<HTMLButtonElement> = (): void => {
    dispatch(logoutRequest());

    if (storeState.app.logout.success) {
      window.location.reload();
    }
  };

  return (
      <Tooltip key="signout" title={t("sidebar.logout")} placement="bottom">
        <Button type="text" onClick={handleLogout} style={{ padding: 0, border: 'none', background: 'transparent', width: '100%', height: '100%' }}>
          <LogoutOutlined />
        </Button>
      </Tooltip>
  );
}

export default LogoutAction;
