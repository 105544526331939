import {
  IOrganizationTeam,
  IOrganizationTeamListItem,
  IOrganizationTeamMember,
} from "@Store/Teams/types";
import { teamResData, teamsListResData } from "./_mockData";

// TODO: update links
export interface IGetAxiosOrganizationTeams {
  data: IOrganizationTeamListItem[];
  error?: any;
}
export const getAxiosOrganizationTeams = async (
  orgId: number
): Promise<IGetAxiosOrganizationTeams> => {
  // TODO: api call
  // const response = await getApiRequest().get(`/organizations/${orgId}/organization-teams/`);

  return { data: teamsListResData };
};

export interface IGetAxiosOrganizationTeam {
  data: IOrganizationTeam;
  error?: any;
}
export const getAxiosOrganizationTeam = async (
  orgId: number,
  teamId: number
): Promise<IGetAxiosOrganizationTeam> => {
  // TODO: api call
  // const response = await getApiRequest().get(`/organizations/${orgId}/organization-teams/`);

  return { data: teamResData };
};

export interface IPostAxiosOrganizationTeam {
  data: IOrganizationTeamListItem;
  error?: any;
}
export const postAxiosOrganizationTeam = async (
  orgId: number,
  body: IOrganizationTeamListItem
): Promise<IPostAxiosOrganizationTeam> => {
  // TODO: api call
  // const response = await getApiRequest().post(`/organizations/${orgId}/organization-teams/`);

  return { data: { ...body, id: Math.floor(Math.random() * 100000000) } };
};

export interface IPutAxiosOrganizationTeam {
  data: IOrganizationTeamListItem;
  error?: any;
}
export const putAxiosOrganizationTeam = async (
  orgId: number,
  body: IOrganizationTeamListItem
): Promise<IPutAxiosOrganizationTeam> => {
  // TODO: api call
  // const response = await getApiRequest().put(`/organizations/${orgId}/organization-teams/`);

  return { data: body };
};

export interface IDeleteAxiosOrganizationTeam {
  error?: any;
}
export const deleteAxiosOrganizationTeam = async (
  orgId: number,
  teamId: number
): Promise<IDeleteAxiosOrganizationTeam> => {
  // TODO: api call
  // const response = await getApiRequest().delete(`/organizations/${orgId}/organization-teams/`);

  return {};
};

export interface IPostAxiosOrganizationTeamMember {
  data: IOrganizationTeamMember[];
  error?: any;
}
export const postAxiosOrganizationTeamMember = async (
  orgId: number,
  body: IOrganizationTeamMember[]
): Promise<IPostAxiosOrganizationTeamMember> => {
  // TODO: api call
  // const response = await getApiRequest().post(`/organizations/${orgId}/organization-teams/`);

  return { data: body };
};
