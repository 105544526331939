import { Button } from "antd";
import { Form, FormikProvider, useFormik } from "formik";
import { KeyboardEvent, KeyboardEventHandler, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FormContainer, FormFooterContainer } from "./styles";

interface IFormikForm {
  children: ReactNode;
  schema: any;
  cancelCb?: () => void;
  initialValues: any;
  onSubmit?: (values: any) => void;
  enableEnterSubmit?: boolean;
  disabled?: boolean;
  showCancelButton?: boolean;
  saveButtonName?: string;
}

const FormikForm = ({
  children,
  schema,
  cancelCb,
  initialValues,
  disabled = false,
  onSubmit = () => {},
  enableEnterSubmit = false,
  showCancelButton = true,
  saveButtonName,
}: IFormikForm) => {
  const { t } = useTranslation();
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    enableReinitialize: true,
  });

  const { handleSubmit, isValid, dirty, isSubmitting, setSubmitting } = formik;

  const submitForm = async (): Promise<boolean> => {
    handleSubmit();

    setSubmitting(false);
    return isValid;
  };

  const handleOnKeyDown: KeyboardEventHandler = (
    event: KeyboardEvent<HTMLFormElement>
  ): void => {
    if (event.key !== "Enter") return;

    submitForm();
  };

  return (
    <FormContainer>
      <FormikProvider value={formik}>
        <Form
          {...(enableEnterSubmit && {
            onKeyDown: (event: KeyboardEvent<HTMLFormElement>) =>
              handleOnKeyDown(event),
          })}
        >
          {children}
          <FormFooterContainer>
            {showCancelButton && (
              <Button
                style={{ marginRight: "24px" }}
                onClick={() => (cancelCb ? cancelCb() : history.push("/"))}
              >
                {t("buttons.cancel")}
              </Button>
            )}
            <Button
              type="primary"
              disabled={disabled && (!(isValid && dirty) || isSubmitting)}
              onClick={submitForm}
            >
              {saveButtonName || t("buttons.save")}
            </Button>
          </FormFooterContainer>
        </Form>
      </FormikProvider>
    </FormContainer>
  );
};

export default FormikForm;
