import { FunctionComponent } from 'react';
import './index.scss';

export type tagProps = {
  size: number;
  color: string;
  borderColor?: string | undefined;
  hide?: boolean;
};

const BlackPinDot: FunctionComponent<tagProps> = (props) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        hidden={props.hide}
        className='app__blackPinDotTag'
        style={{
          borderColor: props.borderColor ? props.borderColor : 'white',
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundColor: props.color ? props.color : 'transparent',
          position: 'absolute',
          top: -props.size / 4,
          right: -(props.size / 4),
          width: props.size,
          height: props.size,
          borderRadius: 100,
          opacity: 1,
          zIndex: 1,
        }}
      ></div>
      {props.children}
    </div>
  );
};

export default BlackPinDot;
