import AddressesForm from "@Components/AdressesForm";
import DatePickerForm from "@Components/DatePickerForm";
import { SocialNetworkIcon } from "@Components/Icons";
import ImageUpload, { IImageUpload } from "@Components/ImageUpload";
import TelephoneNumbersForm from "@Components/TelephoneNumbersForm";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Select, Switch } from "antd";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import { FormError, StyledFormField, StyledInputLabel } from "./styles";

type InputType =
  | "text"
  | "number"
  | "search"
  | "email"
  | "password"
  | "phoneNumbers"
  | "addresses"
  | "datePicker"
  | "select"
  | "checkbox"
  | "address"
  | "image"
  | "social-media"
  | "social-medias"
  | "switch";

type SelectOption = {
  label: string;
  value: string;
};

type FormField = FieldHookConfig<string> & {
  label?: string;
  type?: InputType;
  isRequired?: boolean;
  placeholder?: string;
  multi?: boolean;
  selectAllAvailable?: boolean;
  searchable?: boolean;
  searchValue?: string;
  imageUploadProps?: IImageUpload;
  options?: SelectOption[];
};

export type FormFieldProps =
  | FormField
  | (React.InputHTMLAttributes<HTMLInputElement> & FormField)
  | (React.InputHTMLAttributes<HTMLSelectElement> & FormField);

const FormField = ({
  label,
  type = "text",
  isRequired = false,
  searchable = false,
  imageUploadProps = {},
  multi = false,
  selectAllAvailable = false,
  searchValue = "",
  options = [],
  ...props
}: FormFieldProps) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  const renderField = (type: InputType) => {
    switch (type) {
      case "image":
        return (
          <ImageUpload
            {...imageUploadProps}
            onDoneCb={(info) => {
              if (imageUploadProps.onDoneCb) imageUploadProps.onDoneCb(info);
              setFieldValue(field.name, info.file);
            }}
          />
        );
      case "social-media":
        return (
          <div style={{ display: "flex" }}>
            <SocialNetworkIcon url={field.value} />
            <Input {...field} type={type} />
          </div>
        );
      case "social-medias":
        return (
          Array.isArray(field.value) &&
          field.value.map((website: string, index: number) => (
            <div style={{ display: "flex" }} key={`website: ${index}`}>
              <SocialNetworkIcon url={website} />
              <Input
                value={website}
                type={type}
                onChange={({ target }) => {
                  const { value } = target;
                  const newArr = Array.isArray(field.value)
                    ? [...field.value]
                    : [];
                  newArr[index] = value;
                  setFieldValue(field.name, newArr);
                }}
              />
            </div>
          ))
        );
      case "phoneNumbers":
        return (
          <TelephoneNumbersForm
            isRequired
            /* @ts-ignore */
            phoneNumbers={field.value}
            setPhoneNumbers={(newPhoneNumbers) =>
              setFieldValue(field.name, newPhoneNumbers)
            }
          />
        );
      case "addresses":
        return (
          <AddressesForm
            isRequired
            /* @ts-ignore */
            addresses={field.value}
            setAddresses={(newAddresses) =>
              setFieldValue(field.name, newAddresses)
            }
          />
        );
      case "datePicker":
        return (
          <DatePickerForm
            value={field.value ? moment(field.value) : null}
            field={field}
            setFieldValue={setFieldValue}
            style={{ width: "100%" }}
          />
        );
      case "switch":
        return (
          <Switch
            // @ts-ignore
            defaultChecked={field.value}
            onChange={() => setFieldValue(field.name, !field.value)}
          />
        );
      case "select":
        return (
          <Select
            value={field.value}
            placeholder={label}
            onChange={(value: string) => setFieldValue(field.name, value)}
            mode={multi ? "multiple" : undefined}
            showSearch={searchable}
            style={{ width: "100%" }}
          >
            {options.map(({ label, value }: SelectOption) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return (
          <Input
            {...field}
            type={type}
            required={isRequired}
            placeholder={type === "search" ? label : undefined}
          />
        );
    }
  };

  return (
    <StyledFormField type={type}>
      {!!label && (
        <StyledInputLabel>
          <i>{isRequired && "* "}</i>
          {label}

          {type === "social-medias" && (
            <Button
              ghost
              icon={<PlusOutlined />}
              onClick={() =>
                field.value[0] !== "" &&
                setFieldValue(
                  field.name,
                  Array.isArray(field.value) ? ["", ...field.value] : [""]
                )
              }
            />
          )}
        </StyledInputLabel>
      )}
      {renderField(type)}
      {meta.touched && meta.error ? <FormError>{meta.error}</FormError> : null}
    </StyledFormField>
  );
};

export default FormField;
