import { IOrganizationUser } from "@Store/Organizations/types";

const addresses = [
  {
    id: 1,
    streetNumber: "20",
    street: "Anton-Huber-Straße",
    zip: "73430",
    city: "Aalen",
    country: {
      id: 1,
      isoCode: "DE",
      name: "germany",
      phoneAreaCode: "+49",
    },
    addressType: {
      id: 2,
      name: "business",
    },
  },
];
const phoneNumbers = [
  {
    id: 1,
    number: "176 223 971 88",
    country: {
      id: 1,
      isoCode: "DE",
      name: "germany",
      phoneAreaCode: "+49",
    },
    phoneNumberType: {
      id: 5,
      name: "main",
    },
  },
];

export const mockedUsers: IOrganizationUser[] = [
  {
    orgId: "b1acbead",
    orgUserId: "01G9DKYSEDTD660E6RZWST0SJY",
    userId: "01G9DKYSEDTD660E6RZWST0SJY",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Onur",
    surName: "Yardimci",
    displayName: "Yardimci, Onur",
    nickName: null,
    description: "onur",
    department: "Backend",
    emailAddress: "onur@blackpin.de",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+90 534 596 13 16",
    matrixLocalPart: "01g9dkysed5byy0d92140107qh",
    matrixAddress: "@01g9dkysed5byy0d92140107qh:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01GCRQ2Z81Y74Z0APVDTF508FS",
    userId: "01GCRQ2Z81Y74Z0APVDTF508FS",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Pedro",
    surName: "Gomes",
    displayName: "Gomes, Pedro",
    nickName: null,
    description: null,
    department: "",
    emailAddress: "pedrogomesprog+1@gmail.com",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+351 913 952 750",
    matrixLocalPart: "01gcrq2z81vyz700dkx0wx0ta2",
    matrixAddress: "@01gcrq2z81vyz700dkx0wx0ta2:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G8XDEBJF2MWA05K6JX8S1PS1",
    userId: "01G8XDEBJF2MWA05K6JX8S1PS1",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Moses",
    surName: "Nwosu",
    displayName: "tesla",
    nickName: "tesla",
    description: null,
    department: "Flutter Team",
    emailAddress: "manitego+1@gmail.com",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+49 163 0354813",
    matrixLocalPart: "01g8xdebjfq0vs13t772gz0jgm",
    matrixAddress: "@01g8xdebjfq0vs13t772gz0jgm:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01GAK721EK48GM0YTB76550V3M",
    userId: "01GAK721EK48GM0YTB76550V3M",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Omar",
    surName: "Hussein-31",
    displayName: "Hussein-31, Omar",
    nickName: null,
    description: null,
    department: "Mobile Team",
    emailAddress: "omar@blackpin.de",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+90 538 029 59 75",
    matrixLocalPart: "01gak721ekyfcp1hd086vc1y0x",
    matrixAddress: "@01gak721ekyfcp1hd086vc1y0x:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G8XC6Q108KED1N387T7H0538",
    userId: "01G8XC6Q108KED1N387T7H0538",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Pedro",
    surName: "Gomes",
    displayName: "Gomes, Pedro",
    nickName: null,
    description: null,
    department: "Flutter Team - ADMIN",
    emailAddress: "pedro@blackpin.de",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+351 913 952 750",
    matrixLocalPart: "01g8xc6q10aek81r6wf7c01t5n",
    matrixAddress: "@01g8xc6q10aek81r6wf7c01t5n:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G8XCN684K5B102NS4WGX0VHE",
    userId: "01G8XCN684K5B102NS4WGX0VHE",
    role: "SIMPLE_USER",
    status: "ACTIVE",
    givenName: "Moses",
    surName: "Nwosu",
    displayName: "Nwosu, Moses",
    nickName: null,
    description: null,
    department: "Flutter Team",
    emailAddress: "manitego@gmail.com",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+49 163 0354813",
    matrixLocalPart: "01g8xcn684cf590szjadsb0xnb",
    matrixAddress: "@01g8xcn684cf590szjadsb0xnb:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G8ZV03TA53T51W9MZR471A91",
    userId: "01G8ZV03TA53T51W9MZR471A91",
    role: "SIMPLE_USER",
    status: "ACTIVE",
    givenName: "Moses 2",
    surName: "Nwosu",
    displayName: "Nwosu, Moses 2",
    nickName: null,
    description: null,
    department: "Flutter Team",
    emailAddress: "manitego+2@gmail.com",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+49 163 0354813",
    matrixLocalPart: "01g8zv03ta1pjn1zrxvy200402",
    matrixAddress: "@01g8zv03ta1pjn1zrxvy200402:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G4A2TGVSQK1Y0343PJXD1ZPY",
    userId: "01G4A2TGVSQK1Y0343PJXD1ZPY",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Hannes",
    surName: "Lerchl",
    displayName: "Lerchl, Hannes",
    nickName: null,
    description: null,
    department: null,
    emailAddress: "hannes@blackpin.de",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+49 89 32168",
    matrixLocalPart: "01g4a2tgvd107d0d30zs321kpk",
    matrixAddress: "@01g4a2tgvd107d0d30zs321kpk:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G8XCMFFWNN2M00VP3ZEZ1E2N",
    userId: "01G8XCMFFWNN2M00VP3ZEZ1E2N",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Omar",
    surName: "Hussein",
    displayName: "Omar Hussein",
    nickName: "Omar Hussein",
    description: null,
    department: "Flutter Team - ADMIN",
    emailAddress: "omar@blackpin.de",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+90 538 029 59 75",
    matrixLocalPart: "01g8xcmffw78v71swqgq7s1apy",
    matrixAddress: "@01g8xcmffw78v71swqgq7s1apy:matrixorg.blackpin.tech",
  },
  {
    orgId: "b1acbead",
    orgUserId: "01G4A3XW3JRS031T3H1K3R1D04",
    userId: "01G4A3XW3JRS031T3H1K3R1D04",
    role: "ORG_ADMIN",
    status: "ACTIVE",
    givenName: "Corina",
    surName: "Rudel",
    displayName: "Rudel, Corina",
    nickName: null,
    description: null,
    department: "Flutter",
    emailAddress: "corina.rudel@jambit.com",
    presence: "AVAILABLE",
    presenceDescriptionMap: {
      AVAILABLE: "",
      AWAY: "",
      BUSY: "",
      OFFLINE: "",
    },
    mobileNumber: "+49 89 32168",
    matrixLocalPart: "01g4a3xw3jm3bd04a78gca0j3x",
    matrixAddress: "@01g4a3xw3jm3bd04a78gca0j3x:matrixorg.blackpin.tech",
  },
];
